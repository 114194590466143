// 获取url的参数部分
import store from '../store'

const _f = '-'
export const getUrlParams = (url) => {
  // 判断是否有参数
  if (url.indexOf('?') != -1) {
    const params = {}
    // 获取参数体，例如?id=1&age=18&name=zzw
    let paramObj = url.split('?')[1]
    let paramArry = paramObj.split('&')
    for (let i = 0; i < paramArry.length; i++) {
      const itemArr = paramArry[i].split('=')
      // 这里返回去作为路由的query部分，所以用对象返回
      params[itemArr[0]] = itemArr[1]
    }
    return params
  } else {
    // 不带参返回null
    return null
  }
}
export const replaceTitle = (title) => {
  if (title && title.includes('[')) {
    return title.split('[')?.[0] ?? ''
  } else {
    return title
  }
}

/**
 * 临时判断是不是京东安联财险``````临时补救措施
 * @param pId
 * @returns {boolean}
 */
export const isJDALAPIProducts = (pId) => {
  console.debug(pId)
  // const pIdList = [
  //   '3d20498625eb0ef4d6150747f910450e',
  //   'f72aca9167f2dca95a1e709ac324d7bc',
  //   '8ffdcbedc69b8b26c7eb60c31f1d5133',
  //   '7b51aaa5ec71150572c38323175e5a65']
  // for (let i = 0; i < pIdList.length; i++) {
  //   if (pId === pIdList[i]) {
  //     return true
  //   }
  // }
  return true
}

/**
 * 获取年月日
 * @returns {string}
 */
export const getYYMMDD = () => {
  let date = new Date()
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  month = (month > 9) ? month : `0${month}`
  day = (day < 10) ? `0${day}` : day
  let today = `${year}-${month}-${day}`
  return today
}


/**
 * 根据年龄限制条件判断最大最小日期
 * @param minAge  int类型 最小年龄 可具体到天
 * @param minType  string类型 'Y'-年，'M'-月，'D'-天
 * @param maxAge int类型 最大年龄
 * @param maxType string类型 'Y'-年，'M'-月，'D'-天
 * @param t     int类型 t = 0  等于 T+1，t=-1 等于 T-1
 * @returns {{T: string, maxData: string, born: string, age: {}, minData: string}}
 * 例如：T: T+1，maxData：能够选择的最早日期，minData ：能够选择的最晚日期，born：出生日期, age:年龄信息
 */
export const showDataMin = (m1Age, minType, m2Age, maxType, t) => {
  console.debug(`minAge=${m1Age},minType=${minType},maxAge=${m2Age},maxType=${maxType},t=${t}`)
  let minAge = m1Age + ''
  let maxAge = m2Age + ''
  minAge = minAge.indexOf(_f) > 0 ? Number(minAge.split(_f)[0]) : Number(minAge)
  maxAge = maxAge.indexOf(_f) > 0 ? Number(maxAge.split(_f)[1]) : Number(maxAge)
  // todo 假设A计划投保年龄限制 18岁 - 30岁
  // 今天是：2023年3月10日  t+5 2023-3-15 生效 ，生效日这一天被保人要在18-30内
  // 2005年3月15日 2005年3月5日 最晚日期是 +5
  // 1993年3月16日 30岁 那最早可选时间为1992年3月16日 要保证 +6 后这个日期 ，被保人还是30岁
  // [ , )
  let date = new Date()
  // 获取今天的年月日
  let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
  // 根据t去重新处理 比较日期
  if (t) {
    today = AddDays(date, t, t >= 0)
  }
  console.debug('today', today, maxType, maxAge, minType, minAge)
  // 计算最早可选时间 （
  let earliestDate = getEarliestDate(today, maxType, maxAge)
  // 计算最晚可选时间 【
  let latestDate = getLatestDate(today, minType, minAge)
  return {
    born: '',
    T: t === 0 ? '当天生效' : `T${t >= 0 ? '+' : '-'}${t}生效`,
    age: {
      minAge,
      minType,
      maxAge,
      maxType
    },
    earliestDate,
    latestDate
  }
}

/**
 * 计算几天前，几天后
 * @param newdate 当前的new date() 对象
 * @param num t
 * @param type t为正数则为 true ，负数 为 false
 * @returns {(number|number)[]}
 * @constructor
 */
export const AddDays = (newdate, num, type) => {
  if (type) {
    newdate.setDate(newdate.getDate() + num)
  } else {
    newdate = new Date(newdate - 1000 * 60 * 60 * 24 * num)
  }
  let time = [newdate.getFullYear(), (newdate.getMonth() + 1), newdate.getDate()]
  return time
}

// console.log(AddDays(5, true))

/**
 * 计算可选的最早日期
 * @param today array
 * @param type Y，D，M
 * @param age 年龄值
 */
const getEarliestDate = (today, type, age) => {
  let newdate = getDateByYMD(today, type, age)
  // 生效日期下一天为下一周岁 例如 65周岁就到65岁364天
  newdate.setFullYear(newdate.getFullYear() - 1)
  newdate.setDate(newdate.getDate() + 1)
  let timeStr = newdate.getFullYear() + '-' + (newdate.getMonth() + 1) + '-' + newdate.getDate()
  console.log('getEarliestDate', timeStr)
  // return [newdate.getFullYear(),(newdate.getMonth()+1),newdate.getDate()]
  return timeStr
}
// console.log(getEarliestDate(['2018','3','15'], 'Y', 100))
/**
 * 计算可选的最晚日期
 * @param today array
 * @param type Y，D，M
 * @param age 年龄值
 */
const getLatestDate = (today, type, age) => {
  let newdate = getDateByYMD(today, type, age)
  let timeStr = newdate.getFullYear() + '-' + (newdate.getMonth() + 1) + '-' + newdate.getDate()
  console.log('getLatestDate', timeStr)
  // return [newdate.getFullYear(),(newdate.getMonth()+1),newdate.getDate()]
  return timeStr
}
/**
 * 根据类型计算差值
 * @param today
 * @param type
 * @param age
 * @returns {Date}
 * @constructor
 */
const getDateByYMD = (today, type, age) => {
  let newdate = new Date(today[0], today[1] - 1, today[2])
  // 年处理
  if (type === 'Y') {
    newdate.setFullYear(newdate.getFullYear() - age)
  }
  if (type === 'M') {
    newdate.setMonth(newdate.getMonth() - age)
  }
  if (type === 'D') {
    newdate.setDate(newdate.getDate() - age)
  }
  return newdate
}
/**
 * 根据出生日期，计算几年几月几天
 */
export const getAge = (birthday, t) => {
  // 新建日期对象
  let date = new Date()
  console.debug(t)
  // 这个是算上了t+n
  if (t) {
    date.setDate(date.getDate() + t)
  }
  // 今天日期，数组，同 birthday
  let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
  // 分别计算年月日差值
  let age = today.map((value, index) => {
    return value - birthday[index]
  })
  // 当天数为负数时，月减 1，天数加上月总天数
  if (age[2] < 0) {
    // 简单获取上个月总天数的方法，不会错
    let lastMonth = new Date(today[0], today[1], 0)
    age[1]--
    age[2] += lastMonth.getDate()
  }
  // 当月数为负数时，年减 1，月数加上 12
  if (age[1] < 0) {
    age[0]--
    age[1] += 12
  }
  return {age, t}
}
// usage
// console.log(getAge('1959-3-14'.split('-'))) // [8,0,23] 岁 月 天 以及 t

export const getNewTimeForIOS = (time) => {
  // if (time) {
  //   return time.replace(/-/g, '/')
  // }

  if (isPG()) {
    // 依旧没得用~
    return time.replace(/-/g, '/')
  } else {
    return time
  }
}


const isWeiXin = () => {
  let ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger' && (ua.match(/wxwork/i) != 'wxwork')) {
    return true
  } else {
    return false
  }
}
const isQyWeiXin = function () {
  // return true
  let ua = window.navigator.userAgent.toLowerCase()
  if ((ua.match(/MicroMessenger/i) == 'micromessenger') && (ua.match(/wxwork/i) == 'wxwork')) {
    return true
  } else {
    return false
  }
}
const isQQ = () => {
  let ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/QQ\//i) == 'qq/') {
    return true
  } else {
    return false
  }
}
const isAndroid = () => {
  let ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/android/)) {
    return true
  } else {
    return false
  }
}
export const isBDT = navigator.userAgent.indexOf('hrhb_bdt') > -1 || !!window.locJs || !!window.WebViewJavascriptBridge // 保代帮
const isApp = () => {
  let ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/agentweb|rszl/)) {
    return true
  } else {
    return false
  }
}
const isIOS = () => {
  let ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/iphone|ipad|ipod/)) {
    return true
  } else {
    return false
  }
}
//判斷
const isApple = () => {
  let ua = navigator.userAgent
  if (ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    return true
  } else {
    return false
  }
}
const isMobile = () => {
  return isAndroid() || isIOS()
}
const isTencent = () => {
  return isQQ() || isWeiXin() || isQyWeiXin()
}
const isPG = () => {
  return isIOS() || isApple()
}
export {
  isApp,
  isIOS,
  isAndroid,
  isWeiXin,
  isQyWeiXin,
  isMobile,
  isPG,
  isTencent
}

/**
 * 针对
 * @param time
 * @returns {*}
 */
const getTimeStrArry = (time) => {
  if (time) {
    time = time + ''
    if (time.indexOf('-') > -1) {
      return time.split('-')
    }
    if (time.indexOf('/') > -1) {
      return time.split('/')
    }
  }
  return time
}
/**
 * 针对 苹果 系统拆一下
 * @param time
 * @returns {*}
 */
export const getNewDateParam = (time) => {
  let arr = getTimeStrArry(time)
  if (arr.length === 3) {
    return new Date(arr[0], arr[1] - 1, arr[2])
  }
  return new Date(time)
}

/**
 * 判断一个日期是否在两个日期之间
 * @param date
 * @param startDate
 * @param endDate
 * @returns {boolean}
 */
export const isDateBetween = (date, startDate, endDate) => {

  const d = getNewDateParam(date)
  const start = getNewDateParam(startDate)
  const end = getNewDateParam(endDate)
  return d >= start && d <= end
}

export const calculateCacheSize = () => {
  // 计算缓存大小
  let totalSize = 0
  // 计算 localStorage 大小
  for (const key in window.localStorage) {
    if (window.localStorage.hasOwnProperty.call(key)) {
      totalSize += localStorage[key].length
    }
  }
  // 计算 sessionStorage 大小
  for (const key in window.sessionStorage) {
    if (window.sessionStorage.hasOwnProperty.call(key)) {
      totalSize += sessionStorage[key].length
    }
  }
  // 计算 Vuex 存储的数据大小
  const vuexData = JSON.stringify(store.state)
  totalSize += vuexData.length
  // 将字节数转换为 MB 并保留两位小数
  const cacheSize = (totalSize / (1024 * 1024)).toFixed(2)
  return `${cacheSize}MB`
}

/**
 *
 * @param arr
 */
export const getNewDicList = (arr) => {
  console.error(arr)
  // const obj = arr.reduce((acc, curr) => {
  //   acc[curr.value] = curr.label
  //   return acc
  // }, {})
  const obj = {}
  for (let i = 0; i < arr.length; i++) {
    obj[arr[i].value] = arr[i].label
  }
  console.error('22222---2--', obj)
  console.error('22222---3--', obj[1])
  console.error('22222---3--', obj['1'])
}

export const formatNumberToFix2 = (num) => {
  // 判断传入值是否是数字或数字类型字符串
  if (typeof num === 'number' || !isNaN(Number(num))) {
    // 转为浮点数
    num = parseFloat(num)
    // 判断是否是整数
    if (Number.isInteger(num)) {
      // 补0.00
      return num.toFixed(2)
    } else {
      // 四舍五入保留两位小数
      return num.toFixed(2)
    }
  } else {
    // 非数字或数字类型字符串就是0.00
    num = 0
    return num.toFixed(2)
  }
}

/**
 * 计算天数差，主要用于计算0周岁的实际是几天
 * @param today
 * @param born
 * @returns {number}
 */
export const getDaysBetweenDates = (today, born, elementKey, t) => {
  // 将日期对象转换为毫秒数
  const time1 = today.getTime()
  const time2 = born.getTime()
  // 计算毫秒数之差
  const diff = elementKey === 'Insurancedate' ? Math.abs(time2 - time1) : Math.abs(time1 - time2)
  // 计算天数并返回
  return elementKey === 'Insurancedate' ? (Math.ceil(diff / (1000 * 3600 * 24)) + t) : Math.ceil(diff / (1000 * 3600 * 24))
}


/**
 * 不纠结了，直接放这里再次处理年龄
 * 处理年龄值，获取保费试算入参对象
 * @param selectDate 保费试算的对象
 * @param pInfo 产品详情的对象
 */
export const getPremCalculatorParam = (selectDate, pInfo) => {
  // pInfo = JSON.parse(store.getters['insured/getProductDetailData']).productInfo
  // productDetailData
  // console.log('selectDate--0-', pInfo)
  // console.log('selectDate--1-', selectDate)
  // 根据对应险种id下的textAge 获取产品详情中textAge的属性值列表
  // selectDate.riskList和pInfo下的riskList层级差不多，找到相同险种下的相同要素factorId的情况下去匹配factorValueList下的值
  // selectDate.riskList,
  //   pInfo.riskList,
  for (let i = 0; i < selectDate.riskList.length; i++) {
    let item = selectDate.riskList[i]
    // 获取选中的要素的对象的factorId
    for (let j = 0; j < item.calculateFactor.length; j++) {
      let factorbean_temp = item.calculateFactor[j]
      if (factorbean_temp.factorType === 'TextAge') {
        let obj = findPropValue(pInfo, 'factorId', factorbean_temp.factorId)
        let factorvalue_temp = factorbean_temp.factorValue + ''
        if (obj && obj.factorValueList) {
          getxxxx(item.calculateFactor[j], factorvalue_temp, obj.factorValueList)
          // console.log('selectDate--3-', a)
        }
      }
      if (factorbean_temp.factorType === 'Prem' && factorbean_temp.factorValue === '') {
        factorbean_temp.factorValue = '0'
      }
    }
  }
  return selectDate
}
const getxxxx = (factorbean_temp, factorvalue_temp, factorValueList) => {

  for (let i = 0; i < factorValueList.length; i++) {
    let item = factorValueList[i]
    if (item.value === factorvalue_temp) {
      factorbean_temp.factorValue = item.value
      factorbean_temp.factorValueName = item.name
      return factorbean_temp
    }
    if ((item.value + '').indexOf('-') !== -1) {
      // todo 不要看了~😱
      // 如果包含年龄段，需要判断是否在年龄段内
      let arr = item.value.split('-')
      if (arr.length > 1) {
        let start = arr[0]
        let end = arr[1]
        // 无穷大，只需要大于start即可
        if (end == '') {
          if (start.indexOf('D') !== -1) {
            let t = Number(factorvalue_temp.replace('D', ''))
            let s = Number(start.replace('D', ''))
            if (t == 0 || t > s) {
              factorbean_temp.factorValue = item.value
              factorbean_temp.factorValueName = item.name
              return factorbean_temp
            }
          } else {
            let t = Number(factorvalue_temp.replace('Y', ''))
            let s = Number(start.replace('Y', ''))
            if (t > s) {
              factorbean_temp.factorValue = item.value
              factorbean_temp.factorValueName = item.name
              return factorbean_temp
            }
          }
        } else {
          // 区间 28D-60D
          if (end.indexOf('D') !== -1 && (factorvalue_temp.indexOf('D') !== -1 || factorvalue_temp == 0)) {
            let t = Number(factorvalue_temp.replace('D', ''))
            let s = Number(start.replace('D', ''))
            let e = Number(end.replace('D', ''))
            if (t == 0 || e > t > s) {
              factorbean_temp.factorValue = item.value
              factorbean_temp.factorValueName = item.name
              return factorbean_temp
            }
          } else if (end.indexOf('D') === -1 && start.indexOf('D') === -1) {
            let t = Number(factorvalue_temp.replace('Y', ''))
            let s = Number(start.replace('Y', ''))
            let e = Number(end.replace('Y', ''))
            if (e > t > s) {
              factorbean_temp.factorValue = item.value
              factorbean_temp.factorValueName = item.name
              return factorbean_temp
            }
          } else {
            if (factorvalue_temp == 0) {
              factorbean_temp.factorValue = item.value
              factorbean_temp.factorValueName = item.name
              return factorbean_temp
            } else {
              let t = Number(factorvalue_temp.replace('Y', ''))
              let e = Number(end.replace('Y', ''))
              if (t < e) {
                factorbean_temp.factorValue = item.value
                factorbean_temp.factorValueName = item.name
                return factorbean_temp
              }
            }
          }
        }
      }
    } else if (factorvalue_temp.indexOf('D') !== -1 || factorvalue_temp.indexOf('Y') !== -1 && factorvalue_temp === item.value) {
      factorbean_temp.factorValue = item.value
      factorbean_temp.factorValueName = item.name
      return factorbean_temp
    } else {
      if (factorvalue_temp == 0 && (item.value + '').indexOf('D') !== -1) {
        // 判断下一个是不是D
        if (i < factorValueList.leading - 1 && (factorValueList[+1].value + '').indexOf('D') !== -1) {
          //  不处理了~不管了
        } else {
          factorbean_temp.factorValue = item.value
          factorbean_temp.factorValueName = item.name
          return factorbean_temp
        }
      }
      if (factorvalue_temp == item.value || factorvalue_temp == (item.value + '').replace('Y', '')) {
        factorbean_temp.factorValue = item.value
        factorbean_temp.factorValueName = item.name
        return factorbean_temp
      }
    }
  }
  return factorbean_temp
}
/**
 * 找到对应对象的值所在的对象
 * @param obj 对象
 * @param targetProp 目标key
 * @param value 目标key的值
 * @returns {*}
 */
export const findPropValue = (obj, targetProp, value) => {
  for (const prop in obj) {
    if (prop === targetProp && obj[prop] === value) {
      return obj
    } else if (typeof obj[prop] === 'object' || Array.isArray(obj[prop])) {
      const result = findPropValue(obj[prop], targetProp, value)
      if (result !== undefined) {
        return result
      }
    }
  }
}

/**
 * 检查有没有对应的对象，有的话就改对象
 * @param obj 目标对象
 * @param targetKey 匹配的对象的key
 * @param targetValue 需要给目标对象的目标key设置的value
 * @returns {boolean}
 */
export const checkAndSetValue = (obj, targetKey, targetValue) => {
  if (obj.hasOwnProperty.call(targetKey)) {
    obj[targetKey] = targetValue
    return true
  } else {
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        if (checkAndSetValue(obj[key], targetKey, targetValue)) {
          return true
        }
      }
    }
  }
  return false
}
// checkAndSetValue(obj, 'abc', {a:1,b:2});

export const getDefaultAge = (list, dF, t) => {
  let d = JSON.parse(JSON.stringify(list.filter(item => item.defaultShow === 'Y')[0]))
  if (d == undefined) {
    return dF
  }
  let now = new Date()
  if (t) {
    now.setDate(now.getDate() + t)
  }
  d.value = d.value + ''
  // 默认的天
  if (d.value.indexOf('-') !== -1) {
    d.value = d.value.split('-')[0]
  }
  // 判断是天还是年
  if (d.value.indexOf('D') !== -1) {
    let cDay = now.getDate()
    if (cDay > Number(d.value.replace('D', ''))) {
      return dF
    } else {
      let year = now.getFullYear()
      return `${year}-01-01`
    }
  } else {
    d.value = Number(d.value.replace('Y', ''))
    // const birthday = new Date(now.getFullYear() - d.value, now.getMonth(), now.getDate())
    const startYear = now.getFullYear() - d.value
    return `${startYear}-01-01`
  }
}

export const sorttnew = (arr) => {
  // 按mustReadOrder和fileOrder重新排序
  const filteredData = arr.filter(item => item.mustReadOrder === 2 || item.mustReadOrder === 3)
  const sortedData = filteredData.sort((a, b) => a.fileOrder - b.fileOrder)
  return sortedData
}

/**
 * 根据年龄获取日期
 * @param age 年龄，0岁可能是 多少天，约定如果是天则必须单位带D 例如100D字符串
 * @param t t+n生效的n值
 * @returns {date} 返回日期对象
 */
export const getBirthdateByDays = (age, t) => {
  age = age + ''
  let birthDate = ''
  if (age.indexOf('-') !== -1) {
    age = age.split('-')[0]
  }
  if (age.indexOf('D') !== -1) {
    let days = Number(age.replace('D', '')) - t
    if (!days) {
      return new Date()
    } else {
      birthDate = new Date(Date.now() - days * 24 * 60 * 60 * 1000)
    }
  } else {
    age = Number(age)
    let today = new Date(Date.now() - t)
    let birthYear = today.getFullYear() - age
    let birthDate = new Date(birthYear, today.getMonth(), today.getDate())

    // 如果出生日期已经过了今年，则加一年
    if (birthDate > today) {
      birthYear--
      birthDate = new Date(birthYear, today.getMonth(), today.getDate())
    }
  }
  // return birthDate.toLocaleDateString().replaceAll('/','-')
  return birthDate
}

/**
 * 根据年龄获取默认日期
 * @param age 年龄，0岁可能是 多少天，约定如果是天则必须单位带D 例如100D字符串
 * @param t t+n生效的n值
 * @returns {date} 返回日期对象
 */
export const getDefaultBirthdateByDays = (age, t) => {
  age = age + ''
  let birthDate = ''
  let birthYear = ''
  if (age.indexOf('-') !== -1) {
    age = age.split('-')[0]
  }
  if (age.indexOf('D') !== -1) {
    let days = Number(age.replace('D', '')) - t
    if (!days) {
      return new Date()
    } else {
      birthDate = new Date(Date.now() - days * 24 * 60 * 60 * 1000)
      birthYear = birthDate.getFullYear()
    }
  } else {
    age = Number(age)
    let today = new Date(Date.now() - t)
    birthYear = today.getFullYear() - age
    let birthDate = new Date(birthYear, today.getMonth(), today.getDate())

    // 如果出生日期已经过了今年，则加一年
    if (birthDate > today) {
      birthYear--
      // birthDate = new Date(birthYear, today.getMonth(), today.getDate())
    }
  }
  return new Date(birthYear, 0, 1)
}
// console.log(getDefaultBirthdateByDays('60D-29',1))

export const getPropDateList = (factorId, type) => {
  // 先只处理这个
  if (type === 'TextAge') {
    let obj = findPropValue(JSON.parse(store.getters['insured/getProductDetailData']).productInfo, 'factorId', factorId)
    return (obj && obj.factorValueList) ? obj.factorValueList : null
  }
  return null
}
/**
 * 起保日期 日期加着走
 * @param m1Age
 * @param minType
 * @param m2Age
 * @param maxType
 * @param t
 * @returns {{latestDate: string, earliestDate: string, T: (string|string), born: string, age: {maxAge: number, minType, minAge: number, maxType}}}
 */
export const showInsurancedateDataMin = (m1Age, minType, m2Age, maxType, t) => {
  console.debug(`minAge=${m1Age},minType=${minType},maxAge=${m2Age},maxType=${maxType},t=${t}`)
  let minAge = m1Age + ''
  let maxAge = m2Age + ''
  minAge = minAge.indexOf(_f) > 0 ? Number(minAge.split(_f)[0]) : Number(minAge)
  maxAge = maxAge.indexOf(_f) > 0 ? Number(maxAge.split(_f)[1]) : Number(maxAge)
  let date = new Date()
  // 获取今天的年月日
  let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
  // 根据t去重新处理 比较日期
  if (t) {
    today = AddDays(date, t, t >= 0)
  }
  // 计算最早可选时间 （
  let earliestDate = getLastDate(today, minType, minAge, t)
  // 计算最晚可选时间 【
  let latestDate = getLastDate(today, maxType, maxAge, t)
  return {
    born: '',
    T: t === 0 ? '当天生效' : `T${t >= 0 ? '+' : '-'}${t}生效`,
    age: {
      minAge,
      minType,
      maxAge,
      maxType
    },
    earliestDate,
    latestDate
  }
}
const getLastDate = (today, type, age, t) => {
  let newdate = getDateAddByYMD(today, type, age, t)
  let timeStr = newdate.getFullYear() + '-' + (newdate.getMonth() + 1) + '-' + newdate.getDate()
  return timeStr
}
const getDateAddByYMD = (today, type, age, t) => {
  let newdate = new Date(today[0], today[1] - 1, today[2])
  // 年处理
  if (type === 'Y') {
    newdate.setFullYear(newdate.getFullYear() + age)
  }
  if (type === 'M') {
    newdate.setMonth(newdate.getMonth() + age)
  }
  if (type === 'D') {
    newdate.setDate(newdate.getDate() + age - t)
  }
  return newdate
}


export const getQueryJson = (url, key) => {
  if (!url) {
    url = location.href
  }
  let params = {}
  url.replace(/([^#?&]+)=([^#?&/]+)/g, ($0, $1, $2) => {
    params[$1] = decodeURIComponent($2)
  })
  return key ? params[key] : params
}

export const formatAmountName = (amount) => {
  const number = parseInt(amount)
  if (number < 10000) {
    return number + '元'
  } else if (number < 100000000) {
    const wan = number / 10000
    return wan.toFixed(2) + '万元'
  } else {
    const yi = number / 100000000
    return yi.toFixed(2) + '亿元'
  }
}
console.log('a:', formatAmountName(2000.17))
console.log('b:', formatAmountName(200000.17))
console.log('c:', formatAmountName(8278372681.4764))
export const RELATION_KEY = Symbol('my-relation')
// 变量类型判断
export const getObjType = obj => {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = data => {
  const type = getObjType(data)
  let obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (let i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (let key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}
const special_str = 'sit.web.key.obs'
export const getSpecialImgUrl = (url) => {
  if (url.indexOf(special_str) !== -1) {
    return '//images.weserv.nl/?url=' + +url
  }
  return url
}


/**
 * 验证年龄规则的输入格式---险种信息编辑用
 * @param input
 * @returns {boolean}
 */
export const validateAgeRuleFormat = (input) => {
  const regex = /^-?\d+$|^-?\d+Y$|^-?\d+Y:-?\d+$/
  return regex.test(input)
}
//
// console.log(validateAgeRuleFormat('-1Y:-1')) true
// console.log(validateAgeRuleFormat('-1Y:0')) true
// console.log(validateAgeRuleFormat('1Y:-1')) true
// console.log(validateAgeRuleFormat('1Y:1')) true
// console.log(validateAgeRuleFormat('1Y:0')) true
// console.log(validateAgeRuleFormat('-1')) true
// console.log(validateAgeRuleFormat('0')) true
// console.log(validateAgeRuleFormat('Y0')) false
// console.log(validateAgeRuleFormat('-1-1')) false
// console.log(validateAgeRuleFormat(':')) false
// console.log(validateAgeRuleFormat('1Y:')) false


export const changeDataRangeByAgeDiff = (ageDiff = '0', last, early, flag) => {
  // last 和 early 都是Y-M-D
  console.log('最晚----->', last)
  console.log('最早----->', early)
  // 这里是根据ageDiff重新计算出最晚和最早的日期，如果ageDiff为负数则时间前推，ageDiff为正数则时间后推
  if (ageDiff !== '0') {
    console.log('ageDiff2----->', ageDiff)
    if (ageDiff.indexOf(':') === -1) {
      // 加年
      if (ageDiff.indexOf('Y') !== -1) {
        ageDiff = ageDiff.replace('Y', '')
        return diffAgeTime(last, early, ageDiff, undefined, flag)
      } else {
        // 加天
        return diffAgeTime(last, early, undefined, ageDiff, flag)
      }
    } else {
      const arr = ageDiff.split(':')
      console.log('arr----->', arr)
      const yearDiff = arr[0].replace('Y', '')
      const dayDiff = arr[1]
      return diffAgeTime(last, early, yearDiff, dayDiff, flag)
    }

  }
  return {
    last: last,
    early: early
  }
}

// 计算年龄差
// const diffAgeTime = (d1,d2, year=0, day=0, month) => {
const diffAgeTime = (last, early, year = 0, day = 0, flag = false) => {
  // console.debug('diffAgeTime--data_last--->d1', last)
  // console.debug('diffAgeTime--data_early--->d2', early)
  // let data_last = new Date(last)
  let data_last = getNewDateParam(last)
  // let data_early = new Date(early)
  let data_early = getNewDateParam(early)
  if (year) {
    year = parseInt(year)
    if (year < 0) {
      data_early.setFullYear(data_early.getFullYear() + year)
      data_last.setFullYear(data_last.getFullYear() + year)
    } else {
      data_early.setFullYear(data_early.getFullYear() - year)
      data_last.setFullYear(data_last.getFullYear() - year)
    }
  }
  if (day) {
    day = parseInt(day)
    console.debug('day--->', day)
    console.debug('flag--->', flag)
    if (day < 0) {
      data_early.setDate(data_early.getDate() + day - (flag ? 1 : 0))
      data_last.setDate(data_last.getDate() + day - (flag ? 1 : 0))
    } else {
      data_early.setDate(data_early.getDate() - day + (flag ? 1 : 0))
      data_last.setDate(data_last.getDate() - day + (flag ? 1 : 0))
    }
  }
  let d1 = data_last.getFullYear() + '-' + (data_last.getMonth() + 1) + '-' + data_last.getDate()
  let d2 = data_early.getFullYear() + '-' + (data_early.getMonth() + 1) + '-' + data_early.getDate()
  console.debug('diffAgeTime--d1--->d1', d1)
  console.debug('diffAgeTime--d2--->d2', d2)
  return {
    last: d1,
    early: d2
  }
}
// }


export const newBornInCalAgeFun = (born = new Date(), ageDiff, flag = false) => {
  console.debug('选择的出生日期：------>', born)
  console.debug('获取的ageDiff：------>', ageDiff)
  if (ageDiff !== '0') {
    if (ageDiff.indexOf(':') === -1) {
      // 加年
      if (ageDiff.indexOf('Y') !== -1) {
        ageDiff = ageDiff.replace('Y', '')
        if (flag) {
          return diffAgeTime3(born, ageDiff)
        } else {
          return diffAgeTime2(born, ageDiff)
        }
        // return diffAgeTime2(born, ageDiff,undefined)
      } else {
        // 加天
        if (flag) {
          return diffAgeTime3(born, undefined, ageDiff)
        } else {
          return diffAgeTime2(born, undefined, ageDiff)
        }
        // return diffAgeTime2(born, undefined, ageDiff)
      }
    } else {
      const arr = ageDiff.split(':')
      const yearDiff = arr[0].replace('Y', '')
      const dayDiff = arr[1]
      if (flag) {
        return diffAgeTime3(born, yearDiff, dayDiff)
      } else {
        return diffAgeTime2(born, yearDiff, dayDiff)
      }
      // return diffAgeTime2(born, yearDiff, dayDiff)
    }
  }
  return born
}


const diffAgeTime2 = (born, year = 0, day = 0) => {
  if (year) {
    year = parseInt(year)
    if (year < 0) {
      born.setFullYear(born.getFullYear() + year)
    } else {
      born.setFullYear(born.getFullYear() - year)
    }
  }
  if (day) {
    day = parseInt(day)
    if (day < 0) {
      born.setDate(born.getDate() + day)
    } else {
      born.setDate(born.getDate() - day)
    }
  }
  return born
}
const diffAgeTime3 = (born, year = 0, day = 0) => {
  if (year) {
    year = parseInt(year)
    born.setFullYear(born.getFullYear() - year)
  }
  if (day) {
    day = parseInt(day)
    born.setDate(born.getDate() - day)
  }
  return born
}

/**
 * 根据出生日期，计算几年几月几天
 */
export const getBBRAge = (birthday, t, ageDiff) => {
  let age = getDiff(birthday, ageDiff, t)
  // 啥睡不好
  return {age, t}
}
export const getTBRAge = (birthday, t = 0) => {
  // 新建日期对象
  let date = new Date()
  console.debug(t)
  // 这个是算上了t+n
  if (t) {
    if (typeof t === 'string') {
      t = parseInt(t)
    }
    date.setDate(date.getDate() + t)
  }
  // 今天日期，数组，同 birthday
  let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
  // 分别计算年月日差值
  let age = today.map((value, index) => {
    return value - birthday[index]
  })
  // 当天数为负数时，月减 1，天数加上月总天数
  if (age[2] < 0) {
    // 简单获取上个月总天数的方法，不会错
    let lastMonth = new Date(today[0], today[1], 0)
    age[1]--
    age[2] += lastMonth.getDate()
  }
  // 当月数为负数时，年减 1，月数加上 12
  if (age[1] < 0) {
    age[0]--
    age[1] += 12
  }
  return {age, t}
}

const getDiff = (birth, ageDiff = '0', t) => {
  ageDiff = ageDiff ? ageDiff : '0'
  let extraYears = 0
  let daysOffset = 0
  console.log(birth, ageDiff, t, 'birth, ageDiff, t')
  if (ageDiff !== '0') {
    if (ageDiff.indexOf(':') === -1) {
      // 加年
      if (ageDiff.indexOf('Y') !== -1) {
        extraYears = parseInt(ageDiff.replace('Y', ''))
      } else {
        // 加天
        daysOffset = parseInt(ageDiff)
      }
    } else {
      const arr = ageDiff.split(':')
      const yearDiff = arr[0].replace('Y', '')
      const dayDiff = arr[1]
      extraYears = parseInt(yearDiff)
      daysOffset = parseInt(dayDiff)
    }

  }
  return calculateAge(birth, extraYears, daysOffset, t)
}

/**
 * 计算年龄
 * @param birth 出生日期 字符串格式，如 '1958-04-08'
 * @param yearsOffset 年偏移量
 * @param daysOffset 天偏移量
 * @returns {number} 年龄
 */
const calculateAge = (birth, yearsOffset = 0, daysOffset = 0, t = 0) => {
  // 调试信息输出
  console.debug('fun:calculateAge()------->param:birth', birth)
  console.debug('fun:calculateAge()------->param:yearsOffset', yearsOffset)
  console.debug('fun:calculateAge()------->param:daysOffset', daysOffset)
  let bornDate = birth[0] + '-' + birth[1] + '-' + birth[2]
  // 获取当前日期和生日日期
  const today = new Date()
  today.setDate(today.getDate() + parseInt(t))
  // const birthDate = new Date(birth)
  // const birthDate = new Date(birth[0], birth[1] - 1, birth[2])
  const birthDate = getNewDateParam(bornDate)

  // 根据偏移量调整生日
  birthDate.setFullYear(birthDate.getFullYear() + yearsOffset)
  birthDate.setDate(birthDate.getDate() + daysOffset)

  // 处理月份和日期的边界情况
  birthDate.setMonth(birthDate.getMonth(), birthDate.getDate())

  // 计算年龄
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  // 调试信息输出
  console.debug('fun:calculateAge()------->return:age', age)

  // 返回年龄
  return age
}
// 测试函数
// console.debug(calculateAge('1958-04-09', 0, 1,1))
// export const getQueryJson = (key, url) => {
//   let params = {}
//   url.replace(/([^#?&]+)=([^#?&/]+)/g, ($0, $1, $2) => {
//     params[$1] = decodeURIComponent($2)
//   })
//   return key ? params[key] : params
// }
export const check_option = [
  {label: '产品详情页', value: 'detail'},
  {label: '订单必读页', value: 'sure'},
  {label: '建议书预览页', value: 'plan'},
]

/**
 * 这是一段固定的逻辑，顺序组合根据字典表对应上
 * @param arr
 * @returns {number}
 */
export const parseCheck = (arr) => {
  // 字典数据
  // 1 mustReadOrderType	文件显示位置	1	产品信息页面
  // 2	mustReadOrderType	文件显示位置	2	必读文件列表页面
  // 3	mustReadOrderType	文件显示位置	3	产品信息和必读文件列表页面
  // 4	mustReadOrderType	文件显示位置	4	建议书
  // 5	mustReadOrderType	文件显示位置	5	产品信息页面和建议书
  // 6	mustReadOrderType	文件显示位置	6	必读列表页和建议书
  // 7	mustReadOrderType	文件显示位置	7	产品详情页，必读列表页，建议书
  // 初始化各个组合的布尔值
  const hasA = arr.includes(check_option[0].value)
  const hasB = arr.includes(check_option[1].value)
  const hasC = arr.includes(check_option[2].value)
  // 根据组合返回对应的值
  if (hasA && hasB && hasC) return 7 // abc
  if (hasA && hasB) return 3 // ab
  if (hasA && hasC) return 5 // ac
  if (hasB && hasC) return 6 // bc
  if (hasA) return 1 // a
  if (hasB) return 2 // b
  if (hasC) return 4 // c
  return 0
}

export const getCheckOptionValueByNum = (num) => {
  if (typeof num === 'string') {
    num = Number(num)
  }
  switch (num) {
    case 1:
      return [check_option[0].value] // 产品信息页面
    case 2:
      return [check_option[1].value] // 必读文件列表页面
    case 3:
      return [check_option[0].value, check_option[1].value] // 产品信息和必读文件列表页面
    case 4:
      return [check_option[2].value] // 建议书
    case 5:
      return [check_option[0].value, check_option[2].value] // 产品信息页面和建议书
    case 6:
      return [check_option[1].value, check_option[2].value] // 必读列表页和建议书
    case 7:
      return [check_option[0].value, check_option[1].value, check_option[2].value] // 产品详情页，必读列表页，建议书
    default:
      return [] // 如果没有匹配的数字，返回空数组
  }
}
export const getCheckOptionLabelByNum = (num) => {
  if (typeof num === 'string') {
    num = Number(num)
  }
  switch (num) {
    case 1:
      return check_option[0].label // 产品信息页面
    case 2:
      return check_option[1].label // 必读文件列表页面
    case 3:
      return check_option[0].label + ',' + check_option[1].label // 产品信息和必读文件列表页面
    case 4:
      return check_option[2].value // 建议书
    case 5:
      return check_option[0].label + ',' + check_option[2].label // 产品信息页面和建议书
    case 6:
      return check_option[1].label + ',' + check_option[2].label // 必读列表页和建议书
    case 7:
      return check_option[0].label + ',' + check_option[1].label + ',' + check_option[2].label // 产品详情页，必读列表页，建议书
    default:
      return '' // 如果没有匹配的数字，返回空数组
  }
}

/**
 * 拼接字符串
 * @param valuename
 */
export const spellValuenameToCondition = (valuename) => {
  console.log(valuename)
}


// {
//   private String id;
//
//   /**
//    * 参与计算的类型（投保要素、责任、其他）
//    */
//   private String calculateType;
//
//   /**
//    * 四则运算
//    */
//   private String fourOperations;
//
//   /**
//    * 参与计算的值
//    */
//   private String calculateValue;
//
//   /**
//    * 被计算的值
//    */
//   private BigDecimal participateInCalculations;
//
//   /**
//    * 关系为 或 的子集条件
//    */
//   private List<ProspectusBenefitConditionVO> relationORConditions;
// }

/**
 * 公式场景的结构重组
 * @param item
 * @param typeList
 * @returns {{formulas_str: string, formula: {fourOperationsLabel, participateInCalculations: (number|*), calculateType: (string|*), calculateTypeValue: *, fourOperations: (string|*), gs_id, calculateValue: (string|*)}, id, relationORConditions: *[]}}
 */
export const newFormulaList = (item, typeList) => {
  if (!typeList) {
    typeList = [
      {
        label: '投保要素',
        value: '1'
      },
      {
        label: '可选责任',
        value: '2'
      },
      {
        label: '其他',
        value: '3'
      }
    ]
  }
  // 公式场景的数据结构重组
  let formulas_str = `${item.calculateTypeValue || item.calculateType}${item.fourOperationsLabel}${item.calculateValue}`
  let newD = {
    // 公式对象id
    id: item.id,
    formulas_str,
    formula: {
      // 公式id - 同对象id
      gs_id: item.id,
      calculateType: item.calculateType,
      calculateTypeValue: item.calculateTypeValue,
      calculateValue: item.calculateValue,
      fourOperations: item.fourOperations,
      fourOperationsLabel: item.fourOperationsLabel,
      participateInCalculations: item.participateInCalculations,
    },
    relationORConditions: []
  }

  // 遍历公式对应的多个场景
  item.relationORConditions.map(childItem => {
    let condition = {
      // 这里把公式也加到场景对象中去，用于后续的传递数据使用！公式对场景是1对多关系
      formulaInfo: newD.formula,
      id: childItem.id,
      formulaId: childItem.formulaId,
      // 场景-所以本身就是父级，默认为N
      haveParent: 'N',
      relationANDConditions: []
    }
    let conditionStr
    // 遍历场景对应的多个条件
    if (childItem.relationANDConditions.length === 0) {
      conditionStr = ''
      let conditions_str = getConditionStr(childItem, typeList)
      conditionStr = conditions_str
      let conditions2 = getconditionsObj(conditions_str, childItem)
      childItem.relationANDConditions.push(conditions2)
      condition['conditionStr'] = conditionStr

    } else {
      for (let i = 0; i < childItem.relationANDConditions.length; i++) {
        let childChildItem = childItem.relationANDConditions[i]
        let conditions_str = getConditionStr(childChildItem, typeList)
        if (i === 0) {
          conditionStr = conditions_str
        } else {
          conditionStr += ' 且 ' + conditions_str
        }
        let conditions = getconditionsObj(conditions_str, childChildItem)
        condition.relationANDConditions.push(conditions)
        condition['conditionStr'] = conditionStr
      }
    }
    newD.relationORConditions.push(condition)
  })

  return newD
}

// 获取条件的对象
const getconditionsObj = (conditions_str, childChildItem) => {
  return {
    conditions_str: conditions_str,
    id: childChildItem.id,
    parentId: childChildItem.parentId,
    conditionType: childChildItem.conditionType,
    calculateType: childChildItem.calculateType,
    calculateTypeValue: childChildItem.calculateTypeValue,
    showValue: childChildItem.showValue,
    formulaId: childChildItem.formulaId,
    calculateId: childChildItem.calculateId,
    // 条件-所以是有父级，默认为Y
    haveParent: 'Y',
    calculateMaxValue: childChildItem.calculateMaxValue,
    calculateMinValue: childChildItem.calculateMinValue,
    dutyCode: childChildItem.dutyCode,
    dutyName: childChildItem.dutyName,
    needDuty: childChildItem.needDuty,
    relationORConditions: []
  }
}

// 获取条件的字符串
const getConditionStr = (item, typeList) => {
  let str = ''
  let obj = typeList.find(a => a.value === item.conditionType)
  let type = obj.label === '投保要素' ? 1 : 2
  if (type === 1) {
    let calculateValue = ''
    if (item.calculateMaxValue === item.calculateMinValue) {
      calculateValue = item.showValue || item.calculateMaxValue
    } else {
      calculateValue = `[${item.calculateMinValue},${item.calculateMaxValue})`
    }
    if (item.showValue) {
      str = item.showValue
    } else {
      str = `${item.calculateTypeValue || item.calculateType}:${calculateValue}`
    }
  } else {
    str = `可选责任：${item.dutyName}`
  }
  return str
}
