const ios_event = () => {
  window.onload = () => {
    // 阻止双击放大
    let lastTouchEnd = 0
    document.addEventListener('touchstart',  (event) => {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    })
    document.addEventListener('touchend',  (event) => {
      const now = (new Date()).getTime()
      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }
      lastTouchEnd = now
    }, false)
    // 阻止双指放大
    document.addEventListener('gesturestart',  (event) => {
      event.preventDefault()
    })
  }
}
export default ios_event
