export const ruitai_relational = [
  {
    label: '本人',
    value: 'SF',
    pvale: '01'
  }, {
    label: '配偶',
    value: 'SP',
    pvale: '02'
  }, {
    label: '子女',
    value: 'CH',
    pvale: '03'
  }, {
    label: '父母',
    value: 'PA',
    pvale: '04'
  }, {
    label: '其他',
    value: 'AF'
  }, {
    label: '雇佣',
    value: 'EM'
  }, {
    label: '（外）祖父母',
    value: 'GF'
  }, {
    label: '（外）孙子女',
    value: 'GC'
  }, {
    label: '兄弟姐妹',
    value: 'SI'
  }, {
    label: '其他',
    value: 'CR'
  }
]

// export const getNewOption = (option) => {
//   console.log(ruitai_relational)
//   for (let k = 0; k < ruitai_relational.length; k++) {
//     for (let i = 0; i < option.length; i++) {
//       if (option[i].value ===  ruitai_relational[k].value) {
//         option[i].old_value = ruitai_relational[k].value
//         option[i].value = ruitai_relational[k].pvale
//       }
//     }
//   }
//   option.sort((a, b) => (a.value > b.value) ? 1 : -1)
//   console.log(option)
//   return option
// }
export const getNewOption = (option) => {
  return option
}

export const getNewSubmitData = (submitData) => {
  return submitData
}
// export const getNewSubmitData = (submitData) => {
//   if (submitData&&submitData.contInfo&&submitData.contInfo.insureds) {
//     for (let i = 0; i < submitData.contInfo.insureds.length; i++) {
//       for (let j = 0; j < ruitai_relational.length; j++) {
//         if (ruitai_relational[j].pvale&&submitData.contInfo.insureds[i].relationToAppnt === ruitai_relational[j].pvale) {
//           submitData.contInfo.insureds[i].relationToAppnt = ruitai_relational[j].value
//         }
//       }
//     }
//   }
//   return submitData
// }


