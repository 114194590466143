import * as rrweb from 'rrweb'
import {getExtendUri} from './request_extend'
import {request} from './request'
import {reactive} from 'vue'
import {Dialog} from 'vant'
import store from '@/store'
import router from '@/router'
const operation=[],  saveTimer= window.saveTimer || { value:-1 }
const rrwebObj = {instance: null}

const tradeNo= reactive({
  value: ''
})
let routerName = ''

export const stopRecord = () =>{
  if (rrwebObj.instance != null) {
    rrwebObj.instance.stop
  }
}
export const startRecord = (num, needRecord=false, ) =>{
  console.log('=========', rrwebObj.instance, routerName)
  if(!num || !needRecord || rrwebObj.instance){
    return
  }
  tradeNo.value = num
  rrwebObj.instance = rrweb.record({
    emit(event) {
      if (operation.length === 0) {
        let str = sessionStorage.getItem('operation')|| '[]'
        operation.push(...JSON.parse(str))
      }
      operation.push(event)
      sessionStorage.setItem('operation', JSON.stringify(operation))
      // traceTime()
      if (operation.length > 100) {
        saveTraceEvent(tradeNo.value)
      }
    },
    sampling: {
      // 不录制鼠标移动事件
      mousemove: false,
      // 不录制鼠标交互事件
      // mouseInteraction: false,
      // 设置滚动事件的触发频率
      scroll: 1000, // 每 150ms 最多触发一次
      // 设置输入事件的录制时机
      input: 'last', // 连续输入时，只录制最终值
      mouseInteraction: {
        MouseUp: false,
        MouseDown: false,
        // Click: true,
        ContextMenu: false,
        DblClick: false,
        // Focus: false,
        // Blur: true,
        TouchStart: false,
        TouchEnd: false
      }
    }
  })
  return rrwebObj.instance
}
const saveTraceEvent = function () {
  if(operation.length == 0){
    return
  }
  const datas = JSON.parse(sessionStorage.getItem('operation')|| '[]')
  sessionStorage.setItem('operation', '[]')
  operation.length = 0
  let a = window.location.pathname.replace(/.*\//, '')
  if(a === 'detail'){
    tradeNo.value = store.getters['insured/getTradeNo']
  }else{
    tradeNo.value = router.currentRoute.value.query.tradeNo
  }

  request(getExtendUri('rwebData'), {
    'tradeNo': tradeNo.value,
    data: JSON.stringify(datas)
  }).then(result => {
    if (result.code === 1) {
      console.debug('rrweb saveTraceEvent', datas.length)
      datas.length = 0
    }else{
      Dialog.alert({
        message: '网络异常,请稍后操作',
        theme: 'round-button',
        confirmButtonColor: '#0078fc'
      }).then(()=>{
        console.log('视频录制异常,请稍后操作', result)
      })
    }
  })
    .catch(err => {
      Dialog.alert({
        message: '网络异常,请稍后操作',
        theme: 'round-button',
        confirmButtonColor: '#0078fc'
      }).then(()=>{
        console.log('视频录制异常,请稍后操作', err)
      })
    })
}
const traceTime = function (){
  // 每 2 秒调用一次 save 方法，避免请求过多
  if(saveTimer.value !== -1){
    return
  }
  saveTraceEvent(tradeNo.value)
  saveTimer.value = setInterval(saveTraceEvent, 2000)
  window.saveTimer = saveTimer
}
traceTime()

