/**
 * 登录认证脚本
 * version 2.0.0
 */
import store from '../store'
// import {request} from './request'

const demo = {
  'code': 1,
  'message': '成功',
  'result': {
    'userId': '619385e00047405886abac4212480f14',
    'userName': null,
    'password': null,
    'loginName': '张紫薇',
    'beeId': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzM4NCJ9.eyJzdWIiOiJwcm9kdWN0IiwicGFyYW0iOlsiNjE5Mzg1ZTAwMDQ3NDA1ODg2YWJhYzQyMTI0ODBmMTQiXSwiaXNzIjoic2lub3NvZnQueXNtYXJ0IiwiZXhwIjoxNzI1MzQ4NzYxfQ.AM47hjuTJ3pBd6oL5o7AyGLM57e_pK3TuUwzfpyZM8sElDLJ2pah73fMJmOuflg6',
    'employeeId': null,
    'isInitPassword': 'N',
    'menus': [
      {
        'id': 'home',
        'name': '首页',
        'code': null,
        'menuId': null,
        'uri': '/home',
        'icon': 'HomeOutlined',
        'parentId': '0',
        'childFlag': 'N',
        'isMenu': 'Y',
        'orderFlag': 0,
        'status': null,
        'showFlag': 'Y',
        'submenus': null,
        'actions': [
          {
            'id': 'a33857cb22d04b92aa337a6b2a74fdcd',
            'name': '修改密码',
            'code': 'changePassword',
            'menuId': 'home',
            'uri': '/bee-system-service/system/user/changePassword',
            'icon': 'EditOutlined',
            'parentId': null,
            'childFlag': null,
            'isMenu': 'N',
            'orderFlag': 0,
            'status': null,
            'showFlag': null,
            'submenus': null,
            'actions': null
          },
          {
            'id': 'c09cbf8e3ca54cc8b7118d386a7dc6ce',
            'name': '获取jsconfig数据列表',
            'code': 'getJsStr',
            'menuId': 'home',
            'uri': '/bee-rule-service/rule/ruleKeyword/getJsStr',
            'icon': null,
            'parentId': null,
            'childFlag': null,
            'isMenu': 'N',
            'orderFlag': 1,
            'status': null,
            'showFlag': null,
            'submenus': null,
            'actions': null
          },
          {
            'id': '3ad1578fc5a340c59ca42893439559f0',
            'name': '首页-初始化',
            'code': 'home_init',
            'menuId': 'home',
            'uri': '/oa-service/oa/homePage/init',
            'icon': 'SearchOutlined',
            'parentId': null,
            'childFlag': null,
            'isMenu': 'N',
            'orderFlag': 1,
            'status': null,
            'showFlag': null,
            'submenus': null,
            'actions': null
          },
          {
            'id': 'a13b8667e44e496db04c6d4ccd30206f',
            'name': '修改邮箱授权码',
            'code': 'employee_changeMailPWD',
            'menuId': 'home',
            'uri': '/oa-service/oa/employee/changeMailPWD',
            'icon': 'MailOutlined',
            'parentId': null,
            'childFlag': null,
            'isMenu': 'N',
            'orderFlag': 9,
            'status': null,
            'showFlag': null,
            'submenus': null,
            'actions': null
          }
        ]
      },
      {
        'id': '5gtcLL4k',
        'name': '供应商管理',
        'code': null,
        'menuId': null,
        'uri': null,
        'icon': 'CodepenOutlined',
        'parentId': '0',
        'childFlag': 'Y',
        'isMenu': 'Y',
        'orderFlag': 1,
        'status': null,
        'showFlag': 'Y',
        'submenus': [
          {
            'id': 'TnNTGkYz',
            'name': '供应商配置',
            'code': null,
            'menuId': null,
            'uri': '/agent/agentManage/index',
            'icon': 'CodeSandboxOutlined',
            'parentId': '5gtcLL4k',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 2,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '3eae603018144c9fae0c7ebcf0c445e9',
                'name': '供应商-查询详情',
                'code': 'supplier_queryOne',
                'menuId': 'TnNTGkYz',
                'uri': '/product-service/product/supplier/querySupplierInfoById',
                'icon': 'ios-cloud',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '5a1a5406e27544668dbf123d1e15a63c',
                'name': '供应商-查询列表',
                'code': 'supplier_querySupplier',
                'menuId': 'TnNTGkYz',
                'uri': '/product-service/product/supplier/common/querySupplier',
                'icon': 'md-search',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2c7d3d06801643ef9d34f69fcbec861d',
                'name': '供应商-保存',
                'code': 'supplier_add',
                'menuId': 'TnNTGkYz',
                'uri': '/product-service/product/supplier/add',
                'icon': 'md-add',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b136e0b20afd4123a58ef1cfbc5f31ae',
                'name': '供应商-第一层供应商查询',
                'code': 'supplier_querySupLevelSt',
                'menuId': 'TnNTGkYz',
                'uri': '/product-service/product/supplier/querySupLevelSt',
                'icon': 'ios-trash',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'ce83b743ba004145b56a19877aae2160',
                'name': '供应商-同步其他环境供应商',
                'code': 'sync_supplierInfo',
                'menuId': 'TnNTGkYz',
                'uri': '/product-service/product/supplier/syncSupplierInfo',
                'icon': 'ClockCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b136e0b20afd4123a58ef1cfbc5f31ec',
                'name': '供应商-删除',
                'code': 'supplier_delete',
                'menuId': 'TnNTGkYz',
                'uri': '/product-service/product/supplier/removeById',
                'icon': 'ios-trash',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '031144014b1545c7bd9661adaef1911d',
                'name': '供应商-修改',
                'code': 'supplier_update',
                'menuId': 'TnNTGkYz',
                'uri': '/product-service/product/supplier/update',
                'icon': 'ios-create',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b136e0b20afd4123a58ef1cfbc5f3qwr',
                'name': '供应商-多层供应商查询',
                'code': 'supplier_querySupLevelTh',
                'menuId': 'TnNTGkYz',
                'uri': '/product-service/product/supplier/querySupLevelTh',
                'icon': 'ios-trash',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'y8NysGfE',
            'name': '协议配置',
            'code': null,
            'menuId': null,
            'uri': '/agent/agreement/index',
            'icon': 'ApiOutlined',
            'parentId': '5gtcLL4k',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 3,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '2f6de4dadf9b4196aaf6ea8d604bdc70',
                'name': '协议管理-新增',
                'code': 'productAgreement_insert',
                'menuId': 'y8NysGfE',
                'uri': '/product-service/product/productAgreement/insert',
                'icon': 'ios-cloud',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b429918006884cf1acc68634f64a91db',
                'name': '协议管理-查询',
                'code': 'productAgreement_query',
                'menuId': 'y8NysGfE',
                'uri': '/product-service/product/productAgreement/selectListForPage',
                'icon': 'md-search',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '3b563c4c4da04514a34e65e4630aafd4',
                'name': '协议管理-更新',
                'code': 'productAgreement_update',
                'menuId': 'y8NysGfE',
                'uri': '/product-service/product/productAgreement/update',
                'icon': 'ios-create',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b429918006884cf1acc68634f64a9qax',
                'name': '协议管理-删除',
                'code': 'productAgreement_delete',
                'menuId': 'y8NysGfE',
                'uri': '/product-service/product/productAgreement/delete',
                'icon': 'md-search',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '41f0a4aba11c48e99cabf42d3448d09b',
                'name': '协议关联险种-新增',
                'code': 'productRelation_insert',
                'menuId': 'y8NysGfE',
                'uri': '/product-service/product/productRelation/insert',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '55a3c741d52a49c6bec61e11e7e385f3',
                'name': '协议关联险种-修改',
                'code': 'productRelation_update',
                'menuId': 'y8NysGfE',
                'uri': '/product-service/product/productRelation/update',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '6a2d2a833aa5469ab1325b5ef312554b',
                'name': '协议关联险种-查询',
                'code': 'productRelation_selectListForPageOne',
                'menuId': 'y8NysGfE',
                'uri': '/product-service/product/productRelation/selectListForPageOne',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '6d868cc480994b0b8e651ba69d1b3f43',
                'name': '协议关联险种-删除',
                'code': 'productRelation_delete',
                'menuId': 'y8NysGfE',
                'uri': '/product-service/product/productRelation/delete',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '639ef16dba544c539857ffdb7a8db24d',
                'name': '协议管理导出接口',
                'code': 'productAgreement_export',
                'menuId': 'y8NysGfE',
                'uri': '/product-service/product/productAgreement/export',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 9,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '3731c88698144fe98ba8e22604562ee2',
                'name': '协议查询1接口',
                'code': 'productAgreement_selectListForPageOne',
                'menuId': 'y8NysGfE',
                'uri': '/product-service/product/productAgreement/selectListForPageOne',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 10,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '2b6d0845351e468e9ff5a33ef12bb52f',
            'name': '模板配置',
            'code': null,
            'menuId': null,
            'uri': '/agent/moduleConfig/index',
            'icon': 'ShareAltOutlined',
            'parentId': '5gtcLL4k',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 4,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'caa1fe5d790049adad71f4ad9cf8ccdd',
                'name': '模板配置_查询',
                'code': 'moduleConfig_select',
                'menuId': '2b6d0845351e468e9ff5a33ef12bb52f',
                'uri': '/product-service/product/productSupSignTemplate/selectForPage',
                'icon': 'AmazonCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '8e142457f1a6435ba358ba184579f197',
                'name': '模板配置_添加',
                'code': 'moduleConfig_insert',
                'menuId': '2b6d0845351e468e9ff5a33ef12bb52f',
                'uri': '/product-service/product/productSupSignTemplate/insert',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '31b8adac80d846be95bd88b385591544',
                'name': '模板配置_更新',
                'code': 'moduleConfig_update',
                'menuId': '2b6d0845351e468e9ff5a33ef12bb52f',
                'uri': '/product-service/product/productSupSignTemplate/update',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '8bf8be2d336d433ab3e0750c58ea6713',
                'name': '模板配置_删除',
                'code': 'moduleConfig_del',
                'menuId': '2b6d0845351e468e9ff5a33ef12bb52f',
                'uri': '/product-service/product/productSupSignTemplate/delete',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '75c345bd71a04f71b1a6eac5e6227663',
                'name': '模板配置_区域列表',
                'code': 'moduleConfig_areaList',
                'menuId': '2b6d0845351e468e9ff5a33ef12bb52f',
                'uri': '/product-service/product/productSupSignTemplate/areaList',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '622f0c62d67c4a68a9c5b26d8cb0b962',
            'name': '签名配置',
            'code': null,
            'menuId': null,
            'uri': '/agent/signConfig/index',
            'icon': 'TagsOutlined',
            'parentId': '5gtcLL4k',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 5,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '3f2dedab694f4bd5aa8c91d0b2ea0f70',
                'name': '签名配置_查询',
                'code': 'sign_select',
                'menuId': '622f0c62d67c4a68a9c5b26d8cb0b962',
                'uri': '/product-service/product/productSupSignTemplateLink/selectForPage',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7ceb043abd6e4c71b6b368f57faf256e',
                'name': '签名配置_添加',
                'code': 'sign_insert',
                'menuId': '622f0c62d67c4a68a9c5b26d8cb0b962',
                'uri': '/product-service/product/productSupSignTemplateLink/insert',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '82e2d5260f044e25ae230f7d117b05d6',
                'name': '签名配置_更新',
                'code': 'sign_update',
                'menuId': '622f0c62d67c4a68a9c5b26d8cb0b962',
                'uri': '/product-service/product/productSupSignTemplateLink/update',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '26e5edf1fe494f21b0faf7809f98a4ae',
                'name': '签名配置_删除',
                'code': 'sign_del',
                'menuId': '622f0c62d67c4a68a9c5b26d8cb0b962',
                'uri': '/product-service/product/productSupSignTemplateLink/delete',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'dd3b4d8ed6ed4b8393830740baff111f',
                'name': '签名配置_模板',
                'code': 'sign_temp',
                'menuId': '622f0c62d67c4a68a9c5b26d8cb0b962',
                'uri': '/product-service/product/productSupSignTemplateLink/selectAllTemplateForPage',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'd9e4208aaf3d497e998b92b24bf437c4',
                'name': '签名配置_险种信息',
                'code': 'sign_riskInfo',
                'menuId': '622f0c62d67c4a68a9c5b26d8cb0b962',
                'uri': '/product-service/product/productSupSignTemplateLink/selectAllRisks',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'd74c26d39d054ec9ab0287139491a8d6',
            'name': '参数配置',
            'code': null,
            'menuId': null,
            'uri': '/agent/paramsConfig/index',
            'icon': 'BoxPlotFilled',
            'parentId': '5gtcLL4k',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 5,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '32fcbebf932745299c35e4ca21c05c7c',
                'name': '供应商_查询列表',
                'code': 'supplier_querySupplier',
                'menuId': 'd74c26d39d054ec9ab0287139491a8d6',
                'uri': '/product-service/product/supplier/common/querySupplier',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '1aa9e36f52874fa1895237d230da33b1',
                'name': '配置文件_查询',
                'code': 'query_supplier',
                'menuId': 'd74c26d39d054ec9ab0287139491a8d6',
                'uri': '/product-service/product/insuranceProfile/queryProfileBySupplier',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '182259c028404423a1d1a3fbddf4871a',
                'name': '配置文件_更新',
                'code': 'config_insert',
                'menuId': 'd74c26d39d054ec9ab0287139491a8d6',
                'uri': '/product-service/product/insuranceProfile/insert',
                'icon': 'AmazonCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          }
        ],
        'actions': []
      },
      {
        'id': '5bdfe814e2314e6ea6ff32fe5aa7380d',
        'name': '渠道管理',
        'code': null,
        'menuId': null,
        'uri': null,
        'icon': 'BankFilled',
        'parentId': '0',
        'childFlag': 'Y',
        'isMenu': 'Y',
        'orderFlag': 2,
        'status': null,
        'showFlag': 'Y',
        'submenus': [
          {
            'id': '39d5dbb8794a48d28634ec663484641e',
            'name': '渠道配置',
            'code': null,
            'menuId': null,
            'uri': '/channel/ChannelManagement/index',
            'icon': 'FunnelPlotFilled',
            'parentId': '5bdfe814e2314e6ea6ff32fe5aa7380d',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 1,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '2c59642187e54cb6b4b5e72191db6b4c',
                'name': '渠道-链接',
                'code': 'channel_createChannelPProductLink',
                'menuId': '39d5dbb8794a48d28634ec663484641e',
                'uri': '/product-service/product/channel/createChannelPProductLink',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '5d485a61b04040578a0ce3033fbf14da',
            'name': '协议配置',
            'code': null,
            'menuId': null,
            'uri': '/channel/ProtocolConfiguration/index',
            'icon': 'ControlFilled',
            'parentId': '5bdfe814e2314e6ea6ff32fe5aa7380d',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 2,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '15763e04a3ad498db04166c94c7761ab',
                'name': '协议-查询',
                'code': 'productChannelAgreement_selectListForPage',
                'menuId': '5d485a61b04040578a0ce3033fbf14da',
                'uri': '/product-service/product/productChannelAgreement/selectListForPage',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '485092b06e044caf9323e6f1dc81b75f',
                'name': '协议-删除',
                'code': 'productChannelAgreement_delete',
                'menuId': '5d485a61b04040578a0ce3033fbf14da',
                'uri': '/product-service/product/productChannelAgreement/delete',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '44cbcc3f5a4c4053a375fdfe6fb444fc',
                'name': '协议-新增',
                'code': 'productChannelAgreement_insert',
                'menuId': '5d485a61b04040578a0ce3033fbf14da',
                'uri': '/product-service/product/productChannelAgreement/insert',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '772a2d3370694a6ab9909361aab8041e',
                'name': '协议-修改',
                'code': 'productChannelAgreement_update',
                'menuId': '5d485a61b04040578a0ce3033fbf14da',
                'uri': '/product-service/product/productChannelAgreement/update',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          }
        ],
        'actions': []
      },
      {
        'id': 'I0WSj2Mv',
        'name': '产品信息管理',
        'code': null,
        'menuId': null,
        'uri': null,
        'icon': 'AppstoreOutlined',
        'parentId': '0',
        'childFlag': 'Y',
        'isMenu': 'Y',
        'orderFlag': 3,
        'status': null,
        'showFlag': 'Y',
        'submenus': [
          {
            'id': 'Oup727Ni',
            'name': '险种信息管理',
            'code': null,
            'menuId': null,
            'uri': '/ProductInfo/insuranceList/index',
            'icon': 'PullRequestOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 1,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '1430cb521ac1459fabbec47a5e42ad46',
                'name': '险种信息管理_新增',
                'code': 'productRisk_insert',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productRisk/insert',
                'icon': 'ios-cloud',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '24ce8e934e7b4213bc4d80f664da04e3',
                'name': '险种下拉选',
                'code': 'product-risk',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productRisk/query',
                'icon': 'StarFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '6778e093dc334c16b5c74bff38d6fa55',
                'name': '资料上传_查询',
                'code': 'productFile_detail',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productFile/detail',
                'icon': 'BellFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e6b868036f444e04a1354c41579f46a6',
                'name': '险种信息管理_修改',
                'code': 'productRisk_update',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productRisk/update',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0c9e81be42f84d84be23cff07ef9c97b',
                'name': '险种信息管理-查询',
                'code': 'productRisk_selectListForPage',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productRisk/selectListForPage',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'caa9e21cff1348f793002d817dc3340f',
                'name': '险种信息管理-删除',
                'code': 'productRisk_delete',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productRisk/delete',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'fb8f650a9ca24c80ac8909a22498594d',
                'name': '险种信息管理-上传文件-新增',
                'code': 'productFile_insert',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productFile/insert',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '93b904b67e5c4a6398ca0d94baa4a6c7',
                'name': '险种信息管理-上传文件-修改',
                'code': 'productFile_update',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productFile/update',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0d1ca1f2c23d44beb43c4d98803fef47',
                'name': '险种信息管理-上传文件-查询',
                'code': 'productFile_selectListForPage',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productFile/selectListForPage',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '20fef3f926b2401580ea402a4c1dbacb',
                'name': '险种信息管理-上传文件-删除',
                'code': 'productFile_delete',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productFile/delete',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0f26c652e5124ed687274294c8aa0347',
                'name': '险种信息导出',
                'code': 'productRisk_export',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productRisk/export',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 9,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '05481dab00fb474a9a1b8c2e0f582401',
                'name': '职业等级查询',
                'code': 'selOccupationLevel',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productRisk/selOccupationLevel',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 14,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a4b1128fd4d441f68167ea28419fed0a',
                'name': '检查险种是否满足编辑条件',
                'code': 'productRisk_checkUpdate',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/productRisk/checkUpdate',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 16,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'd1c62a2b29ff4433b1b860a41ecaaaf9',
                'name': '险种条款信息-详情',
                'code': 'riskTermDetail',
                'menuId': 'Oup727Ni',
                'uri': '/prospectus-service/prospectus/riskTerm/riskTermDetail',
                'icon': 'FullscreenExitOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 17,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '9c122a01ddf04938886783e960924d8d',
                'name': '险种条款信息-新增',
                'code': 'riskTermUpdate',
                'menuId': 'Oup727Ni',
                'uri': '/prospectus-service/prospectus/riskTerm/riskTermUpdate',
                'icon': 'FullscreenExitOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 18,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0d9fc7c855d14162b9a2d72735e68594',
                'name': '新供应商接口',
                'code': 'supplierQuerySupLevelSt',
                'menuId': 'Oup727Ni',
                'uri': '/product-service/product/supplier/common/querySupplier',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 29,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'Oup794Ni',
            'name': '责任库管理',
            'code': null,
            'menuId': null,
            'uri': '/ProductInfo/shoulderList/index',
            'icon': 'TagsOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 3,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '1430cb521ac1459fabbec47a5e42ad25',
                'name': '责任库_新增',
                'code': 'duty_add',
                'menuId': 'Oup794Ni',
                'uri': '/product-service/product/duty/add',
                'icon': 'ios-cloud',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2cde36db89ae4a56bd69eda37c613f73',
                'name': '责任库_查询',
                'code': 'duty_queryDuty',
                'menuId': 'Oup794Ni',
                'uri': '/product-service/product/duty/queryDuty',
                'icon': 'ios-search',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '6771e7c0da594091a51b3a837e28e022',
                'name': '责任库_删除',
                'code': 'duty_removeById',
                'menuId': 'Oup794Ni',
                'uri': '/product-service/product/duty/removeById',
                'icon': 'ios-analytics',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'd419dd46b1324595b6ab51848e502d47',
                'name': '责任库_拉列表责任库查询',
                'code': 'duty_selectList',
                'menuId': 'Oup794Ni',
                'uri': '/product-service/product/duty/queryDutyForSelect',
                'icon': 'ios-cloud-download',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'bd4055ef009849ae82753a75971f961e',
                'name': '责任库_更新',
                'code': 'duty_update',
                'menuId': 'Oup794Ni',
                'uri': '/product-service/product/duty/update',
                'icon': 'ios-cloud-download',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '5cd29629acff4d3597822421a81c3b2c',
                'name': '责任库_详情',
                'code': 'duty_queryDutyInfoById',
                'menuId': 'Oup794Ni',
                'uri': '/product-service/product/duty/queryDutyInfoById',
                'icon': 'ios-cloud-download',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '570f1f34fcf54bc7bda436842cd9a30f',
                'name': '检查责任是否满足编辑条件',
                'code': 'riskDuty_checkUpdate1',
                'menuId': 'Oup794Ni',
                'uri': '/product-service/product/riskDuty/checkUpdate',
                'icon': 'AmazonCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 16,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '9ac52810867244db8009645b26642bb2',
            'name': '险种责任信息管理',
            'code': null,
            'menuId': null,
            'uri': '/ProductInfo/riskDuty/index',
            'icon': 'TagOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 4,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '45a16e659e104280b05c518d7b159089',
                'name': '更新',
                'code': 'riskDuty_updateRiskDuty',
                'menuId': '9ac52810867244db8009645b26642bb2',
                'uri': '/product-service/product/riskDuty/updateRiskDuty',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f20bacd4a7fd4f809a8700136dd25bd4',
                'name': '删除',
                'code': 'riskDuty_deleteRiskDuty',
                'menuId': '9ac52810867244db8009645b26642bb2',
                'uri': '/product-service/product/riskDuty/deleteRiskDuty',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'fd2684d9b8d846c4a21bc1ca22b0b8a7',
                'name': '查询列表',
                'code': 'selectRisk_dutyList',
                'menuId': '9ac52810867244db8009645b26642bb2',
                'uri': '/product-service/product/riskDuty/selectRiskDutyList',
                'icon': 'TagFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f4483d44a9ed42c99bd53bf853469aca',
                'name': '查询单个',
                'code': '2',
                'menuId': '9ac52810867244db8009645b26642bb2',
                'uri': '/product-service/product/riskDuty/selectRiskDutyById',
                'icon': 'TagFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '9c0fbdc5bffa440f94c42bd4ecd8d26f',
                'name': '新增',
                'code': '4',
                'menuId': '9ac52810867244db8009645b26642bb2',
                'uri': '/product-service/product/riskDuty/insertRiskDuty',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '881c5205bf634f86a721df1ea6d8649a',
            'name': '险种投保要素管理',
            'code': null,
            'menuId': null,
            'uri': '/product/Insuranceelements/index',
            'icon': 'TableOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 5,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '1c2077c3695048f994ae276fba8cfd14',
                'name': '要素关联查询要素类别下拉',
                'code': 'tbys_category',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/selectListForElementType',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2c2533183f4a4184b2147a7ae5f9e81f',
                'name': '投保要素修改',
                'code': 'tbys_updata',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/update',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f54d309026d94040ae2551601e9bfbfb',
                'name': '要素关联查询一个',
                'code': '10',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/queryOneElementRelation',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '01b03886a7cf4d4db35fb3c5dc0b941f',
                'name': '要素关联新增',
                'code': 'tbys_gladd',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/insertElementRelation',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '3473826f412142858a4b3715a738a67d',
                'name': '投保要素新增',
                'code': 'tbys_add',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/insert',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b879974465174a31bab86f79fd655892',
                'name': '要素关联查询列表',
                'code': 'tbys_caclist',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/selectListForPageElementRelation',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0e18ba279814456c8d49596132326350',
                'name': '要素关联删除',
                'code': 'tbys_gldel',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/deleteElementRelation',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7263ecf553a9401eac621c5de9a8c953',
                'name': '投保要素查询列表',
                'code': 'tbys_list',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/selectListForPage',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b7bf9f6650814853b158d5a4d8dcf2e4',
                'name': '要素关联查询显示值下拉',
                'code': 'tbys_show',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/selectListForElementCal',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'bda7d449f3464dac8c0af2ac2e5482f1',
                'name': '要素关联修改',
                'code': 'tbys_glupdata',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/updateElementRelation',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '22e19db4691e406b826202349cfba902',
                'name': '投保要素删除',
                'code': 'tbys_del',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/delete',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '223901a75ff24e8cbc3ea7178029061d',
                'name': '投保要素查询单个',
                'code': '5',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/queryOne',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'fb193459999741b2b7795943dabe4b64',
                'name': '投保要素值关联',
                'code': 'productRelation_importExcel',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/importExcel',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 14,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '82a5a65f27f44420b182f8fba9cac6ad',
                'name': '险种投保要素关联责任修改',
                'code': 'productElementRiskDutyLink_update',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElementRiskDutyLink/update',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 20,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '279c98ec324e451abfbdec66dd1081c8',
                'name': '编辑查询是否已生成费率',
                'code': 'select_ListRateTable',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/rate/selectListRateTable',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 23,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e20f422cade4430db66153713646eaf7',
                'name': '根据投保要素id查询险种责任接口',
                'code': 'productElementRiskDutyLink_selectByElementId',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElementRiskDutyLink/selectByElementId',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 23,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '4504173a57964265a78565c80d4d5cc4',
                'name': '导出接口1',
                'code': 'productElement_downloadd',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/download',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 24,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c477a0fc7ddc48e89fd5e60505a174a5',
                'name': '根据费率表生成投保要素',
                'code': 'productLabel17_selectListForPage',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElement/createElement',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 34,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '9967daabdaeb451986551e57afd9ea0e',
                'name': '删除',
                'code': 'productElementRiskDutyLink_delete',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElementRiskDutyLink/delete',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 36,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b608d48d84064e90a8e39527e9b8474d',
                'name': '保存',
                'code': 'productElementRiskDutyLink_save',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElementRiskDutyLink/save',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 38,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b608d48d84064e90a8e39527e9b8474d',
                'name': '保存',
                'code': 'productElementRiskDutyLink_save',
                'menuId': '881c5205bf634f86a721df1ea6d8649a',
                'uri': '/product-service/product/productElementRiskDutyLink/save',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 38,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '1d658da9c951490189600abd89a6e38f',
            'name': '险种投保费率管理',
            'code': null,
            'menuId': null,
            'uri': '/product/Ratemanagement/index',
            'icon': 'CalculatorOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 6,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'cb310f647e0441ac8d8fbc45cbb13bf1',
                'name': '保存导入费率表',
                'code': 'fldr_save',
                'menuId': '1d658da9c951490189600abd89a6e38f',
                'uri': '/product-service/product/rate/importFile',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '636697f49bfd4add87848d75ebd0854d',
                'name': '批量删除',
                'code': 'tbfl_del',
                'menuId': '1d658da9c951490189600abd89a6e38f',
                'uri': '/product-service/product/rate/delete',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '4e93eeed6a9044219506bf5a8433bbfa',
                'name': '初始化费率表',
                'code': 'initRateTable',
                'menuId': '1d658da9c951490189600abd89a6e38f',
                'uri': '/product-service/product/rate/syn',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '47020ac2e98f42468ebd05ce7178364d',
                'name': '费率管理编辑',
                'code': 'flgl_updat',
                'menuId': '1d658da9c951490189600abd89a6e38f',
                'uri': '/product-service/product/rate/select',
                'icon': 'CodepenCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '681e829f717c4e728cbe782732aab09c',
                'name': '创建费率表',
                'code': 'tbfl_addtable',
                'menuId': '1d658da9c951490189600abd89a6e38f',
                'uri': '/product-service/product/rate/create',
                'icon': 'CodepenCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'wqPzP6ws',
            'name': '产品信息管理',
            'code': null,
            'menuId': null,
            'uri': '/product/informationManage/index',
            'icon': 'DeploymentUnitOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 7,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '0a79f2f5e90b4a0786a86d49aaf0969a',
                'name': '查询-产品信息-供应商信息-接口',
                'code': 'pppq-01',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/querySupAndProductInfo',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '8e782f8022d24ccab3d801f06a45938c',
                'name': '初始化',
                'code': 'ppqa_init',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/init',
                'icon': 'ios-cloud',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f3843e730f544cf483aa420b242b75a8',
                'name': '产品渠道-查询接口',
                'code': 'productChannelRelationship_selectChannelForPage',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productChannelRelationship/selectChannelForPage',
                'icon': 'AimOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '9ad90a6f00484734b61a9361d376c424',
                'name': '产品渠道_新增接口',
                'code': 'productChannelRelationship_addChannel',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productChannelRelationship/addChannel',
                'icon': 'AimOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'fa830164cd39416b86e5dc1037f739a7',
                'name': '产品渠道_删除接口',
                'code': 'productChannelRelationship_deleteChannel',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productChannelRelationship/deleteChannel',
                'icon': 'AimOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'ec1993ca39c641eb84ed3f83de4c0eef',
                'name': '分页查询',
                'code': 'ppqa_selectInfo',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/selectListForPage/common',
                'icon': 'ios-search',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2e4fde5de62a4346aa9222dc4b28137c',
                'name': '新增',
                'code': 'ppqa_analytic_record',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/insert',
                'icon': 'ios-analytics',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '8d9933d9d3a64c58b931039dffe5815b',
                'name': '删除',
                'code': 'ppqa_delete',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/delete',
                'icon': 'ios-cloud-download',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '64f204ae384344758d1de8263ef2ee7b',
                'name': '更新',
                'code': 'productInfo_update',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/update',
                'icon': 'ios-cloud-download',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '6632ccdbc56943299a0bd4576943fbfb',
                'name': '详情',
                'code': 'ppqa_send',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/queryOne',
                'icon': 'ios-mail',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '8b2f6e8832fa4a5bb0ceaa77ced0ea98',
                'name': '查询产品下拉选',
                'code': 'select',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/select',
                'icon': 'ios-mail',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '5119bfcbc8c84539895b13586d92a043',
                'name': '险种下拉选查询',
                'code': 'selectRisk',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/selectRisk',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '76d485f426664c2f8a912fb39103ea15',
                'name': '检查产品是否满足条件',
                'code': 'productInfocheck_Update',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/checkUpdate',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 9,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e9e2269b0b174003b2c6c3e83db81a54',
                'name': '保存-产品与险种关联关系接口',
                'code': 'saveProductInfoRisk',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/saveProductInfoRisk',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 9,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'baa2129645d64ce5a33e9b36d053094a',
                'name': '删除-产品与险种关联关系接口',
                'code': 'deleteProductInfoRisk',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/deleteProductInfoRisk',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 10,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '86f7b9971210436fac6ee4faf7436ad0',
                'name': '查询-产品与险种关联关系列表接口',
                'code': 'queryProductInfoRisk',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/queryProductInfoRisk',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 11,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '4bed7d56f3874da7bb9544c2863ada05',
                'name': '保存-产品信息-拓展信息-接口',
                'code': 'saveProductInfoExtend',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/saveProductInfoExtend',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 12,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '77e7ac1de85845b89ed3b8c4b61725fb',
                'name': '修改-产品信息-拓展信息-接口',
                'code': 'updateProductInfoExtend',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/updateProductInfoExtend',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 13,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '214d4a1028eb49efb7ac9a692726081e',
                'name': '详情-产品信息-拓展信息-接口',
                'code': 'queryProductInfoExtend',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/queryProductInfoExtend',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 14,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '02dc0780af9640e986a8138acc7d7194',
                'name': '环境同步-同步',
                'code': 'syncProductInfo',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/syncProductInfo',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 15,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '1425f889c9e84b748985802dcd39f961',
                'name': '产品模板查询',
                'code': 'productFlowTemplate_all',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productFlowTemplate/listAll',
                'icon': 'AppstoreFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 15,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '1a59355e9d7d45fabfa171c010d5aea8',
                'name': '环境同步-查询',
                'code': 'syncSelectListForPage',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/syncSelectListForPage',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 16,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f489c424055144d3b693c7ee23fbef69',
                'name': '险种下拉，根据产品供应商查询主-附加险',
                'code': 'selectRisk_BySupIdhh',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/selectRiskBySupId',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 19,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7b1b07e4ee7d4aa3984fda36402042a7',
                'name': '导出接口',
                'code': 'productInfo_export',
                'menuId': 'wqPzP6ws',
                'uri': '/product-service/product/productInfo/export',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 20,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '1ae7925016794e22ababdcca623d3981',
            'name': '产品信息分享管理',
            'code': null,
            'menuId': null,
            'uri': '/product/informationShare/index',
            'icon': 'ShareAltOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 8,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'fb774f78c3a2472b982ce87708d2ab93',
                'name': '获取详情数据',
                'code': 'share_details',
                'menuId': '1ae7925016794e22ababdcca623d3981',
                'uri': '/product-service/product/productInfoShare/details',
                'icon': 'BlockOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '98f2c223f0da4347823100f0a4e02a48',
                'name': '新增数据',
                'code': 'share_add',
                'menuId': '1ae7925016794e22ababdcca623d3981',
                'uri': '/product-service/product/productInfoShare/add',
                'icon': 'EditOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '3aad44894df24b9cbdd534569c32a112',
            'name': '投保须知信息管理（暂停）',
            'code': null,
            'menuId': null,
            'uri': '/product/InsuranceNoticeManagement/index',
            'icon': 'InfoCircleOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 9,
            'status': null,
            'showFlag': 'N',
            'submenus': null,
            'actions': [
              {
                'id': 'dc78c7c603a34752ab2e88d91a76efb1',
                'name': '投保须知信息管理新增接口',
                'code': 'productInsureTotice_insert',
                'menuId': '3aad44894df24b9cbdd534569c32a112',
                'uri': '/product-service/product/productInsureTotice/insert',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7626371bc3e74cb6bbdd222c08f5aaa4',
                'name': '投保须知信息管理修改接口',
                'code': 'productInsureTotice_update',
                'menuId': '3aad44894df24b9cbdd534569c32a112',
                'uri': '/product-service/product/productInsureTotice/update',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0486a5d803fc42f0b0fdb50fe9b022eb',
                'name': '投保须知信息管理查询接口',
                'code': 'productInsureTotice_selectListForPage',
                'menuId': '3aad44894df24b9cbdd534569c32a112',
                'uri': '/product-service/product/productInsureTotice/selectListForPage',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b71958bd78294e8e9add2068337ef435',
                'name': '投保须知信息管理查询1接口',
                'code': 'productInsureTotice_selectListForPageOne',
                'menuId': '3aad44894df24b9cbdd534569c32a112',
                'uri': '/product-service/product/productInsureTotice/selectListForPageOne',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '47d2d7ad03b94cd49aef0c7ad92e3dbc',
                'name': '投保须知信息管理删除接口',
                'code': 'productInsureTotice_delete',
                'menuId': '3aad44894df24b9cbdd534569c32a112',
                'uri': '/product-service/product/productInsureTotice/delete',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '307def564ae341239514314732c258be',
            'name': '产品渠道配置管理',
            'code': null,
            'menuId': null,
            'uri': '/product/productChannel/index',
            'icon': 'NodeIndexOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 9,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'dd3f7230db0e47879b7fd4f5f466a48f',
                'name': '产品模板',
                'code': 'productFlowTemplate_all',
                'menuId': '307def564ae341239514314732c258be',
                'uri': '/product-service/product/productFlowTemplate/listAll',
                'icon': 'AccountBookFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '93488c13eb564a12972de2361de04078',
            'name': '产品发布管理',
            'code': null,
            'menuId': null,
            'uri': '/product/allManagement/index',
            'icon': 'RocketOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 11,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '82e7571d79654dada0aa180bb62abbd6',
                'name': '查询',
                'code': 'product_select',
                'menuId': '93488c13eb564a12972de2361de04078',
                'uri': '/product-service/product/productInfoPutOff/selectProduct',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0a658e375bcb415194cad808808a8cfe',
                'name': '上下架',
                'code': 'product_updateToPutOff',
                'menuId': '93488c13eb564a12972de2361de04078',
                'uri': '/product-service/product/productInfoPutOff/updateToPutOff',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '71fb9a3e82fb4cf4bab93c1916fcc288',
                'name': '下架',
                'code': '3',
                'menuId': '93488c13eb564a12972de2361de04078',
                'uri': '/product-service/product/productInfoPutOff/updateToOff',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7c33478720ee4a0a9ebf3e726856ebd5',
                'name': '上架',
                'code': '2',
                'menuId': '93488c13eb564a12972de2361de04078',
                'uri': '/product-service/product/productInfoPutOff/updateToPut',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f4b83b81770f4e43ac8807b4bd33bda0',
                'name': '预约上架',
                'code': 'productInfoPutOff',
                'menuId': '93488c13eb564a12972de2361de04078',
                'uri': '/product-service/product/productInfoPutOff/updateTemer',
                'icon': 'AccountBookFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'fb9cc6abb4534581a34248844d7a24a5',
            'name': '险种利益演示配置',
            'code': null,
            'menuId': null,
            'uri': '/product/interestShow/index',
            'icon': 'PayCircleOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 12,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '9d81a174d2ee4e5fb3749da8899c5523',
                'name': '利益演示-查看详细信息',
                'code': 'selectBenefit',
                'menuId': 'fb9cc6abb4534581a34248844d7a24a5',
                'uri': '/prospectus-service/prospectus/benefitShowConfig/selectBenefit',
                'icon': 'EditFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '1b242186d13345b690d8b5de1cd76203',
                'name': '利益演示-上传调用',
                'code': 'uploadBenefitFile',
                'menuId': 'fb9cc6abb4534581a34248844d7a24a5',
                'uri': '/prospectus-service/prospectus/benefitShowConfig/uploadBenefitFile',
                'icon': 'EditFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'dd19e3ea115d459a94ecf7f23fa93665',
                'name': '利益演示-新增配置',
                'code': 'benefit-updateBenefit',
                'menuId': 'fb9cc6abb4534581a34248844d7a24a5',
                'uri': '/prospectus-service/prospectus/benefitShowConfig/updateBenefit',
                'icon': 'EditFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '588e450e58c54e7d91890796b52b1d2c',
                'name': '利益演示-生成模板',
                'code': 'benefit-generateTemplate',
                'menuId': 'fb9cc6abb4534581a34248844d7a24a5',
                'uri': '/product-service/product/productBenefitShow/generateTemplate',
                'icon': 'EditFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '6174d4d79bfd4fde802b6882b59bebf3',
            'name': '销售市场规则管理',
            'code': null,
            'menuId': null,
            'uri': '/product/saleMarket/index',
            'icon': 'ControlOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 13,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '70405d65ff7348ccba91940cb71bd55f',
                'name': '销售市场-渠道配置列表查询',
                'code': 'channel_queryListForPage',
                'menuId': '6174d4d79bfd4fde802b6882b59bebf3',
                'uri': '/product-service/product/channel/queryListForPage',
                'icon': 'LogoutOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c9157a9b26aa4a8d861ee96c01771f40',
                'name': '销售市场-渠道配置新增',
                'code': 'channel_save',
                'menuId': '6174d4d79bfd4fde802b6882b59bebf3',
                'uri': '/product-service/product/channel/save',
                'icon': 'LogoutOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '38f0aed58588485992d5874d0f3c85f7',
                'name': '销售市场-渠道配置修改',
                'code': 'channel_update',
                'menuId': '6174d4d79bfd4fde802b6882b59bebf3',
                'uri': '/product-service/product/channel/update',
                'icon': 'LogoutOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '812a9e4309a24a69a3309a70f2b66bdb',
                'name': '销售市场-渠道配置删除',
                'code': 'channel_delete',
                'menuId': '6174d4d79bfd4fde802b6882b59bebf3',
                'uri': '/product-service/product/channel/delete',
                'icon': 'LogoutOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '3d1f2dc1f4d547e9bafe1b08a927af6b',
                'name': '销售市场-渠道名称下拉',
                'code': 'channel_select',
                'menuId': '6174d4d79bfd4fde802b6882b59bebf3',
                'uri': '/product-service/product/channel/select',
                'icon': 'LogoutOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'ca336d7889a546aa8498f788c8c5a78f',
                'name': '销售市场-产品渠道名称列表',
                'code': 'productInfo_queryListForPage',
                'menuId': '6174d4d79bfd4fde802b6882b59bebf3',
                'uri': '/product-service/product/channel/productInfo/queryListForPage',
                'icon': 'LogoutOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '899dd25bea5349749d1a756f96268dea',
                'name': '销售市场-渠道产品新增or修改',
                'code': 'channel_productInfo_change',
                'menuId': '6174d4d79bfd4fde802b6882b59bebf3',
                'uri': '/product-service/product/channel/productInfo/change',
                'icon': 'LogoutOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0de117cd8b7e4c57b25cd3214aa0780f',
                'name': '销售市场-产品售卖地区-查询',
                'code': 'channel_area_list',
                'menuId': '6174d4d79bfd4fde802b6882b59bebf3',
                'uri': '/product-service/product/channel/area/list',
                'icon': 'LogoutOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2272c15bbb7a456289120d6f6b8ad1bb',
                'name': '销售市场-产品售卖地区-保存',
                'code': 'channel_area_save',
                'menuId': '6174d4d79bfd4fde802b6882b59bebf3',
                'uri': '/product-service/product/channel/area/save',
                'icon': 'LogoutOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 9,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '1a771ed57030434d9e1d0c1fc29b34e7',
                'name': '查询产品名称',
                'code': 'select',
                'menuId': '6174d4d79bfd4fde802b6882b59bebf3',
                'uri': '/product-service/product/productInfo/select',
                'icon': 'AccountBookFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 10,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'e001005',
            'name': '投保规则管理',
            'code': null,
            'menuId': null,
            'uri': '/product/merchandiseRule/index',
            'icon': 'RadarChartOutlined',
            'parentId': 'I0WSj2Mv',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 98,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'adb88b23c6c041c2a4a78a4cb3c151e4',
                'name': '产品投保规则查询接口',
                'code': 'productInsureRule_selectListForPage',
                'menuId': 'e001005',
                'uri': '/product-service/product/productInsureRule/selectListForPage',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7546500c642b4802b38b3ba3c3d277ad',
                'name': '查询产品下拉框',
                'code': 'info_select',
                'menuId': 'e001005',
                'uri': '/product-service/product/productInfo/select',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a4d9ef7cdb394101a1382a0fd6ba1131',
                'name': '投保规则管理新增接口',
                'code': 'productInsureRule_insert',
                'menuId': 'e001005',
                'uri': '/product-service/product/productInsureRule/insert',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '60aa41e1cba44ebd9ad72891ee7f90f8',
                'name': '投保规则管理修改接口',
                'code': 'productInsureRule_update',
                'menuId': 'e001005',
                'uri': '/product-service/product/productInsureRule/update',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '6763e4bb27cd4b9c9f7c9b89f8892749',
                'name': '投保规则管理删除接口',
                'code': 'productInsureRule_delete',
                'menuId': 'e001005',
                'uri': '/product-service/product/productInsureRule/delete',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '9ddb49471ede489ea67fda2c7d8560ab',
                'name': '投保规则管理查询1接口',
                'code': 'productInsureRule_selectListForPageOne',
                'menuId': 'e001005',
                'uri': '/product-service/product/productInsureRule/selectListForPageOne',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '69ff59b5205444ca8a5f3243e3932cd3',
                'name': '根据产品id查询规则集列表接口',
                'code': 'productInfoRuleLink_selectByInfoId',
                'menuId': 'e001005',
                'uri': '/product-service/product/productInfoRuleLink/selectByInfoId',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c35e19efc034485dac282afe24f603cd',
                'name': '规则集列表',
                'code': 'ruleCollection129_list',
                'menuId': 'e001005',
                'uri': '/bee-rule-service/rule/ruleCollection/list',
                'icon': 'AlipaySquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '5bfcde5da841456397f19121e9679136',
                'name': '增加1',
                'code': 'productInfoRuleLink_save1',
                'menuId': 'e001005',
                'uri': '/product-service/product/productInfoRuleLink/save',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 10,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b238b6bb9d4a4b4d8056dea90dac0ddc',
                'name': '删除1',
                'code': 'productproductInfoRuleLink_delete',
                'menuId': 'e001005',
                'uri': '/product-service/product/productInfoRuleLink/delete',
                'icon': 'AlipaySquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 11,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          }
        ],
        'actions': []
      },
      {
        'id': '26a38bbb8d9a4620b060cc9b71b60f4e',
        'name': '计划书管理',
        'code': null,
        'menuId': null,
        'uri': '',
        'icon': 'BookOutlined',
        'parentId': '0',
        'childFlag': 'Y',
        'isMenu': 'Y',
        'orderFlag': 3,
        'status': null,
        'showFlag': 'Y',
        'submenus': [
          {
            'id': '392148678trfyh2f808391yd72hu',
            'name': '计划书管理',
            'code': null,
            'menuId': null,
            'uri': '/planbook/planbookList/planbookList',
            'icon': 'FacebookOutlined',
            'parentId': '26a38bbb8d9a4620b060cc9b71b60f4e',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 1,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '57077a9c01aa468e9229ee6551101fd5',
                'name': '查询',
                'code': 'prospectusList',
                'menuId': '392148678trfyh2f808391yd72hu',
                'uri': '/prospectus-service/prospectus/detail/api/prospectusList',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e24479b9c1d44ec39ec100f646bf45db',
                'name': '详情',
                'code': 'prospectusDetail',
                'menuId': '392148678trfyh2f808391yd72hu',
                'uri': '/prospectus-service/prospectus/detail/api/prospectusDetail',
                'icon': 'FolderViewOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '387003ea018f4ec598de189c14d33c62',
            'name': '通用素材配置',
            'code': null,
            'menuId': null,
            'uri': '/planbook/generalContentConfiguration/generalContentConfiguration',
            'icon': 'DeploymentUnitOutlined',
            'parentId': '26a38bbb8d9a4620b060cc9b71b60f4e',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 2,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '52e2fbbfbd774be28e508c3540290861',
                'name': '通用素材新增',
                'code': 'saveTemplateSummary',
                'menuId': '387003ea018f4ec598de189c14d33c62',
                'uri': '/prospectus-service/prospectus/prospectusSummary/saveTemplateSummary',
                'icon': 'SaveOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a897b345feaa486d9ccf01b594bb0ba6',
                'name': '通用素材删除',
                'code': 'delTemplateSummary',
                'menuId': '387003ea018f4ec598de189c14d33c62',
                'uri': '/prospectus-service/prospectus/prospectusSummary/delTemplateSummary',
                'icon': 'DeleteOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '9d076e3489ec4cc79816cf0b3694fdba',
                'name': '通用素材查询列表',
                'code': 'templateSummaryList',
                'menuId': '387003ea018f4ec598de189c14d33c62',
                'uri': '/prospectus-service/prospectus/prospectusSummary/templateSummaryList',
                'icon': 'UnorderedListOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '3b42c68dadac4145984b51f345649f2f',
                'name': '通用素材详情',
                'code': 'templateSummaryDetail',
                'menuId': '387003ea018f4ec598de189c14d33c62',
                'uri': '/prospectus-service/prospectus/prospectusSummary/templateSummaryDetail',
                'icon': 'CodeOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7ae3dad235324c33ab1ac781e62a9f95',
                'name': '获取可配置的分类类型数据',
                'code': 'summaryTypeList',
                'menuId': '387003ea018f4ec598de189c14d33c62',
                'uri': '/prospectus-service/prospectus/prospectusSummary/summaryTypeList',
                'icon': 'EyeInvisibleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '51f9ec6eaea04899a1595a933c9a0544',
            'name': '封面素材配置',
            'code': null,
            'menuId': null,
            'uri': '/planbook/coverManagement/coverManagement',
            'icon': 'PictureOutlined',
            'parentId': '26a38bbb8d9a4620b060cc9b71b60f4e',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 4,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '56db2477913d42b497469ef369b9dfc4',
                'name': '新增',
                'code': 'prospectusCoverInsert',
                'menuId': '51f9ec6eaea04899a1595a933c9a0544',
                'uri': '/prospectus-service/prospectus/cover/prospectusCoverInsert',
                'icon': 'AppstoreAddOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '368f48eeb4aa4d48b816ff50a5963dde',
                'name': '查询',
                'code': 'prospectusCoverSelectList',
                'menuId': '51f9ec6eaea04899a1595a933c9a0544',
                'uri': '/prospectus-service/prospectus/cover/api/prospectusCoverSelectList',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '73f008c36b004b31af67adb7ecdca951',
                'name': '删除',
                'code': 'prospectusCoverDelete',
                'menuId': '51f9ec6eaea04899a1595a933c9a0544',
                'uri': '/prospectus-service/prospectus/cover/prospectusCoverDelete',
                'icon': 'DeleteOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '3f471e53b34c43d7b5f1cc643d5dd35b',
            'name': '利益演示公式配置',
            'code': null,
            'menuId': null,
            'uri': '/planbook/benefitDemonstrationFormulaConfiguration/FormulaConfiguration',
            'icon': 'AlipayCircleFilled',
            'parentId': '26a38bbb8d9a4620b060cc9b71b60f4e',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 5,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '93f94eb073304fb8b1afa55ad8c4fbb8',
                'name': '查询利益演示列表信息',
                'code': 'selectGuarantee',
                'menuId': '3f471e53b34c43d7b5f1cc643d5dd35b',
                'uri': '/prospectus-service/prospectus/benefitGuarantee/selectGuarantee',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a6582979a4b3477c8c6f9cb3eaf192c9',
                'name': '删除利益演示信息',
                'code': 'deleteGuarantee',
                'menuId': '3f471e53b34c43d7b5f1cc643d5dd35b',
                'uri': '/prospectus-service/prospectus/benefitGuarantee/deleteGuarantee',
                'icon': 'DeleteFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '1d557357cab640e483369401d930ebe2',
                'name': '新增利益演示保障信息',
                'code': 'insertGuarantee',
                'menuId': '3f471e53b34c43d7b5f1cc643d5dd35b',
                'uri': '/prospectus-service/prospectus/benefitGuarantee/insertGuarantee',
                'icon': 'FolderAddOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '85c14e1fe1c14805b9680807a976e5df',
                'name': '修改利益演示信息',
                'code': 'updateGuarantee',
                'menuId': '3f471e53b34c43d7b5f1cc643d5dd35b',
                'uri': '/prospectus-service/prospectus/benefitGuarantee/updateGuarantee',
                'icon': 'EditOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '1ef446a96a5240f2b6173ae4fe53ca0e',
                'name': '插入新公式/更新公式',
                'code': 'insertFormula',
                'menuId': '3f471e53b34c43d7b5f1cc643d5dd35b',
                'uri': '/prospectus-service/prospectus/benefitFormula/insertFormula',
                'icon': 'AmazonCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '17cc21efdd6c45eb8c5f770898559450',
                'name': '删除公式',
                'code': 'deleteFormula',
                'menuId': '3f471e53b34c43d7b5f1cc643d5dd35b',
                'uri': '/prospectus-service/prospectus/benefitFormula/deleteFormula',
                'icon': 'AmazonSquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'abd6a2494f784bfdb34f7b216cad6c30',
                'name': '查询当前的投保要素可选责任',
                'code': 'selectFactorsAndDuties',
                'menuId': '3f471e53b34c43d7b5f1cc643d5dd35b',
                'uri': '/prospectus-service/prospectus/benefitFormula/selectFactorsAndDuties',
                'icon': 'SelectOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '497d9cc1de3d417a86d3cd7e2965e22e',
                'name': '产品公式详情',
                'code': 'selectFormulasForGuarantee',
                'menuId': '3f471e53b34c43d7b5f1cc643d5dd35b',
                'uri': '/prospectus-service/prospectus/benefitFormula/selectFormulasForGuarantee',
                'icon': 'BorderlessTableOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          }
        ],
        'actions': []
      },
      {
        'id': '8f10bdab9a5f419882534c388afeea23',
        'name': '保单管理',
        'code': null,
        'menuId': null,
        'uri': null,
        'icon': 'FilePptOutlined',
        'parentId': '0',
        'childFlag': 'Y',
        'isMenu': 'Y',
        'orderFlag': 4,
        'status': null,
        'showFlag': 'Y',
        'submenus': [
          {
            'id': 'c051c023766e4757bc0d3250773b336f',
            'name': '保单信息管理',
            'code': null,
            'menuId': null,
            'uri': '/product/guaranteeManage/index',
            'icon': 'AppstoreFilled',
            'parentId': '8f10bdab9a5f419882534c388afeea23',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 1,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'a3c80c4e6da24f49888567f6ca9cc381',
                'name': '投保单-保单-列表查询',
                'code': 'productInsured_queryListForPage',
                'menuId': 'c051c023766e4757bc0d3250773b336f',
                'uri': '/product-service/product/productInsured/api/queryListForPage',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c4a2799221ad46128820ff8a6af048a2',
                'name': '获取回溯数据',
                'code': 'rwebData_getData',
                'menuId': 'c051c023766e4757bc0d3250773b336f',
                'uri': '/trade-service/trade/rwebData/getData',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e458c3cabceb46ceb1ee22fa32a3cce6',
                'name': '投保单-保单-查询详情',
                'code': 'productInsured_queryDetail',
                'menuId': 'c051c023766e4757bc0d3250773b336f',
                'uri': '/product-service/product/productInsured/api/queryDetail',
                'icon': 'AlipaySquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2cc066cedb064779ae4b9eb16929615e',
                'name': '投保单-保单-撤销投保单',
                'code': 'productInsured_cancelInsuranceSlip1',
                'menuId': 'c051c023766e4757bc0d3250773b336f',
                'uri': '/trade-service/trade/insuredPayData/api/cancelInsured',
                'icon': 'AmazonCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          }
        ],
        'actions': []
      },
      {
        'id': 'cdd0ae4ec79a4c2c9b5c1df7218b8a48',
        'name': '物料管理',
        'code': null,
        'menuId': null,
        'uri': null,
        'icon': 'DropboxOutlined',
        'parentId': '0',
        'childFlag': 'Y',
        'isMenu': 'Y',
        'orderFlag': 5,
        'status': null,
        'showFlag': 'Y',
        'submenus': [
          {
            'id': 'f0564b4973bb4d3e98eee2aa7d6a0274',
            'name': '标签管理',
            'code': null,
            'menuId': null,
            'uri': '/material/productLabel/index',
            'icon': 'TagsOutlined',
            'parentId': 'cdd0ae4ec79a4c2c9b5c1df7218b8a48',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 1,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'f85ca3d73a5a4ce09809f60de221af85',
                'name': '产品标签新增接口',
                'code': 'productLabel_insert',
                'menuId': 'f0564b4973bb4d3e98eee2aa7d6a0274',
                'uri': '/product-service/product/productLabel/insert',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '06ec7d1d183d41e1a27846d8a7255791',
                'name': '产品标签新增接口1',
                'code': 'productLabel_insertOne',
                'menuId': 'f0564b4973bb4d3e98eee2aa7d6a0274',
                'uri': '/product-service/product/productLabel/insertOne',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '860f23f51ec342cfa59fd1f6c5b8c676',
                'name': '产品标签修改接口',
                'code': 'productLabel_update',
                'menuId': 'f0564b4973bb4d3e98eee2aa7d6a0274',
                'uri': '/product-service/product/productLabel/update',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a926a1511b7d496d8fbd88a02d871768',
                'name': '产品标签删除接口',
                'code': 'productLabel_delete',
                'menuId': 'f0564b4973bb4d3e98eee2aa7d6a0274',
                'uri': '/product-service/product/productLabel/delete',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '20155adb042e4250b9dbc14af0a21d6d',
                'name': '产品标签查询接口',
                'code': 'productLabel_selectListForPage',
                'menuId': 'f0564b4973bb4d3e98eee2aa7d6a0274',
                'uri': '/product-service/product/productLabel/selectListForPage',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7e14f045638a4358b0faf83b0645a51e',
                'name': '产品标签指定查询接口',
                'code': 'productLabel_selectListForPageOne',
                'menuId': 'f0564b4973bb4d3e98eee2aa7d6a0274',
                'uri': '/product-service/product/productLabel/selectListForPageOne',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'd59464d7016a4adc968b36ea262a85a3',
                'name': '产品标签指定查询1接口',
                'code': 'productLabel_selectListForPageTwo',
                'menuId': 'f0564b4973bb4d3e98eee2aa7d6a0274',
                'uri': '/product-service/product/productLabel/selectListForPageTwo',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'df7a5e0eb8fd4440ab37d8558a3cd3f6',
                'name': '素材标签查询',
                'code': 'productLabel_selectTreeList2',
                'menuId': 'f0564b4973bb4d3e98eee2aa7d6a0274',
                'uri': '/product-service/product/productLabel/selectTreeList',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 11,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
            'name': '产品素材',
            'code': null,
            'menuId': null,
            'uri': '/material/productSourceMaterial/index',
            'icon': 'ShareAltOutlined',
            'parentId': 'cdd0ae4ec79a4c2c9b5c1df7218b8a48',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 3,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'c3e475c818a24873afeb5163bf2a3112',
                'name': '产品素材-查询',
                'code': 'productMaterial_selectProductMaterial',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/selectProductMaterial',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f2bf7a6fe406465c9dd8f5cae3bc4c24',
                'name': '产品素材-新增照片',
                'code': 'productMaterial_insertPicture',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/insertPicture',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'aa363266f5e840f2895f07328c001395',
                'name': '产品素材-删除素材',
                'code': 'productMaterial_deleteMaterial',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/deleteMaterial',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '77bfe234ae7041d8936c7c99ba161808',
                'name': '产品素材-上架素材',
                'code': 'productMaterial_updatePut',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/updatePut',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e2ddaeb704344823afde7fce6f914992',
                'name': '产品素材-下架素材',
                'code': 'productMaterial_updateOff',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/updateOff',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b1561b5dc9ce4f75beacb5351ce55cc2',
                'name': '产品素材-置顶素材',
                'code': 'productMaterial_updateTop',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/updateTop',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '28df34671ea941ffa0f45ff4c0706e78',
                'name': '产品素材-更新图片',
                'code': 'productMaterial_updatePicture',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/updatePicture',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2049ef8a70dd4adc9c621b9c1a02bce6',
                'name': '产品素材-新增视频',
                'code': 'productMaterial_insertVideo',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/insertVideo',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '9705e47798d44606a6ba961238b9b105',
                'name': '产品素材-新增文件',
                'code': 'productMaterial_insertFile',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/insertFile',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 9,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7377116dbf6e47279f66b9c14c0d24bd',
                'name': '产品素材-新增音频',
                'code': 'productMaterial_insertAudio',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/insertAudio',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 10,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '96464c5f19324a578c4a123facf17c62',
                'name': '产品素材-更新文件',
                'code': 'productMaterial_updateFile',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/updateFile',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 11,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c854f0d085324752849f4a7d3f235a33',
                'name': '产品素材-更新音频',
                'code': 'productMaterial_updateAudio',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/updateAudio',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 12,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '247f866da96d4cdd9125434778d84068',
                'name': '产品素材-更新视频',
                'code': 'productMaterial_updateVideo',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/updateVideo',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 13,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f4268bb3ece64c65bdd0058dd4a80103',
                'name': '产品素材-素材详情',
                'code': 'productMaterial_selectMaterialById',
                'menuId': 'bd90ce9cdd5a40b1b1f5a382a13e9af8',
                'uri': '/product-service/product/productMaterial/selectMaterialById',
                'icon': 'MenuOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 14,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '7b34db67d68c4c33b68f2ee0e4becb7b',
            'name': '产品标签配置',
            'code': null,
            'menuId': null,
            'uri': '/material/productLabelRelevance/index',
            'icon': 'PictureFilled',
            'parentId': 'cdd0ae4ec79a4c2c9b5c1df7218b8a48',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 4,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '30890a8282ca46068a5f3bc629192f8e',
                'name': '新增接口',
                'code': 'productInfoLabel1_insert',
                'menuId': '7b34db67d68c4c33b68f2ee0e4becb7b',
                'uri': '/product-service/product/productInfoLabel/insert',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '781abe0f899141798e90a3d73635b8ed',
                'name': '删除接口',
                'code': 'productInfoLabel1_delete',
                'menuId': '7b34db67d68c4c33b68f2ee0e4becb7b',
                'uri': '/product-service/product/productInfoLabel/delete',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e0ba34ae780d4e629fe673fe2754d03c',
                'name': '查询标签集合',
                'code': 'productInfoLabel1_selectByProductId',
                'menuId': '7b34db67d68c4c33b68f2ee0e4becb7b',
                'uri': '/product-service/product/productInfoLabel/selectByProductId',
                'icon': 'AlipaySquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '8b9dc9a4aaa64590a4e6ffb6dc9a457f',
            'name': '产品海报（暂停）',
            'code': null,
            'menuId': null,
            'uri': '/material/poster/index',
            'icon': 'NotificationFilled',
            'parentId': 'cdd0ae4ec79a4c2c9b5c1df7218b8a48',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 6,
            'status': null,
            'showFlag': 'N',
            'submenus': null,
            'actions': [
              {
                'id': '525707dbce1347148fd84f893af90eb7',
                'name': '产品海报分页查询',
                'code': 'pppq-001',
                'menuId': '8b9dc9a4aaa64590a4e6ffb6dc9a457f',
                'uri': '/product-service/product/productPoster/queryByPage',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'dd581d2dc53b4d38bbd2aacaf6f816d6',
                'name': '查询产品海报详情',
                'code': 'pppq-003',
                'menuId': '8b9dc9a4aaa64590a4e6ffb6dc9a457f',
                'uri': '/product-service/product/productPoster/queryDetails',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e2403977f04a4c60a28228316cb65ea9',
                'name': '新增产品海报',
                'code': 'pppa-002',
                'menuId': '8b9dc9a4aaa64590a4e6ffb6dc9a457f',
                'uri': '/product-service/product/productPoster/add',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2471f307601f42a09e18812220747e46',
                'name': '编辑产品海报',
                'code': 'pppu-004',
                'menuId': '8b9dc9a4aaa64590a4e6ffb6dc9a457f',
                'uri': '/product-service/product/productPoster/update',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'de7fd3250c4a48b397446dcf9e327ec1',
                'name': '产品海报预览-预览编辑-编辑海报预览信息',
                'code': 'pppe-006',
                'menuId': '8b9dc9a4aaa64590a4e6ffb6dc9a457f',
                'uri': '/product-service/product/posterPreview/edit',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '79bc263f8760427c89e83fbed1cef8bc',
                'name': '产品海报预览-预览查询-某个海报的预览',
                'code': 'pppq-005',
                'menuId': '8b9dc9a4aaa64590a4e6ffb6dc9a457f',
                'uri': '/product-service/product/posterPreview/queryByPreview',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '9166dca386dd4bdcab858a62288b0661',
                'name': '删除产品海报',
                'code': 'posterDel',
                'menuId': '8b9dc9a4aaa64590a4e6ffb6dc9a457f',
                'uri': '/product-service/product/productPoster/deletePoster',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '4141c96f2e1b4285883bca764988fb65',
                'name': '上架',
                'code': 'updateP',
                'menuId': '8b9dc9a4aaa64590a4e6ffb6dc9a457f',
                'uri': '/product-service/product/productPoster/updatePut',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c1fb076e6d1a4b49b1e3bafe8bf2d1e4',
                'name': '下架',
                'code': 'updateO',
                'menuId': '8b9dc9a4aaa64590a4e6ffb6dc9a457f',
                'uri': '/product-service/product/productPoster/updateOff',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c720d62acc7d452b954cabbc3a221481',
                'name': '置顶',
                'code': 'updateTo',
                'menuId': '8b9dc9a4aaa64590a4e6ffb6dc9a457f',
                'uri': '/product-service/product/productPoster/updateTop',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '628adf5e095d49e5a490ebc7a3ce3bc4',
            'name': '产品扩展信息（废弃）',
            'code': null,
            'menuId': null,
            'uri': '/material/ProductExtensionInformation/index',
            'icon': 'PartitionOutlined',
            'parentId': 'cdd0ae4ec79a4c2c9b5c1df7218b8a48',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 98,
            'status': null,
            'showFlag': 'N',
            'submenus': null,
            'actions': [
              {
                'id': '1b8c52529190425594cbd82ccbc911df',
                'name': '查询-产品要素详情',
                'code': 'pppq007',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/productSpread/queryDetails',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'ca2d432c368a4ca79691ad134ddd1b27',
                'name': '分页查询-产品要素列表',
                'code': 'pppq-01',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/productSpread/queryByPage',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c735e7b9f3b042fb9cac87145bf881ac',
                'name': '修改-产品要素',
                'code': 'pppu-updata',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/productSpread/update',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '1fba5ee266f14d25bd01f2e7b5cb67cc',
                'name': '查询某个产品-要素列表',
                'code': 'pppq-kzys',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/productSpread/queryByProductInfoId',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '256d65698f734d48957fc64df02abf78',
                'name': '批量添加或修改某个产品的要素信息',
                'code': 'pppb-addorr',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/productSpread/batchUpdateOrAdd',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'd8622d1f5efd44de99ecaa6be53bea94',
                'name': '保存产品要素',
                'code': 'kzxx_addor',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/productSpread/batchUpdateOrAdd',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b7cdedd120ac42b497d9ab93090675e1',
                'name': '添加-产品要素',
                'code': 'pppa-addlist',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/productSpread/add',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7398eabca3c54613abba89abd9dc1c46',
                'name': '删除-产品要素',
                'code': 'pppr-delelet',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/productSpread/remove',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '4d2db86e07854fe1bc5ebda3e9236e1b',
                'name': '要素模板-查询当前模板的所有要素信息',
                'code': 'ppeq-mbxq',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/elementTemplate/queryElementInfo',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c5c8da378405475295802cf0c9b8b9fa',
                'name': '要素模板-添加模板(另存为模板)',
                'code': 'pppa-addnode',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/elementTemplate/add',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0a910d49eb3348b68b814d522b0e6481',
                'name': '要素模板-删除模板',
                'code': 'pper-002',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/elementTemplate/removeElementTemp',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '3c7005b1fa04499cb9b0c0f13ce0f771',
                'name': '查询要素模板(只需要传分页参数)',
                'code': 'ppeq-copylist',
                'menuId': '628adf5e095d49e5a490ebc7a3ce3bc4',
                'uri': '/product-service/product/elementTemplate/queryElementTemplate',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          }
        ],
        'actions': []
      },
      {
        'id': '82767e48fd9349f0837d78fb568cad84',
        'name': '投保流程配置',
        'code': null,
        'menuId': null,
        'uri': null,
        'icon': 'BranchesOutlined',
        'parentId': '0',
        'childFlag': 'Y',
        'isMenu': 'Y',
        'orderFlag': 6,
        'status': null,
        'showFlag': 'Y',
        'submenus': [
          {
            'id': 'ed506a9e24fb4c83bc66b42094af9042',
            'name': '元素配置',
            'code': null,
            'menuId': null,
            'uri': '/InsuranceConfiguration/element/ElementList',
            'icon': 'GoldOutlined',
            'parentId': '82767e48fd9349f0837d78fb568cad84',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 1,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'a954113ee33b42b9b4423e045ab2420d',
                'name': '元素配置-新增/修改',
                'code': 'element_addElement',
                'menuId': 'ed506a9e24fb4c83bc66b42094af9042',
                'uri': '/trade-service/trade/element/addElement',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '330b1929e69f43bfbd0c9d7688e543eb',
                'name': '元素配置-删除',
                'code': 'element_deleteElement',
                'menuId': 'ed506a9e24fb4c83bc66b42094af9042',
                'uri': '/trade-service/trade/element/deleteElement',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c1141f985c894c37a735bf1ae0d179f3',
                'name': '元素配置-元素详情',
                'code': 'element_getElementById',
                'menuId': 'ed506a9e24fb4c83bc66b42094af9042',
                'uri': '/trade-service/trade/element/getElementById',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '406ac54e77a64761881dc51a4205a306',
                'name': '元素配置-关联配置',
                'code': 'element_healthToldList',
                'menuId': 'ed506a9e24fb4c83bc66b42094af9042',
                'uri': '/trade-service/trade/element/healthToldList',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7e989a44730b4c04a99236abca6bbf10',
                'name': '元素配置-查询1级职业',
                'code': 'productProfessionCommon_selectByFirst',
                'menuId': 'ed506a9e24fb4c83bc66b42094af9042',
                'uri': '/product-service/product/productProfessionCommon/api/selectByFirst',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e612dc40785d4ababcfab55a5209bbb7',
                'name': '元素配置-查询下级职业',
                'code': 'productProfessionCommon_selectByNext',
                'menuId': 'ed506a9e24fb4c83bc66b42094af9042',
                'uri': '/product-service/product/productProfessionCommon/api/selectByNext',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '3cf5b9d3ebfd46db9a1b7662a79569bb',
                'name': '上传身份证ocr识别',
                'code': 'ocr_idCard',
                'menuId': 'ed506a9e24fb4c83bc66b42094af9042',
                'uri': '/bee-common-service/common/ocr/idCard',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'aa853e1964124d9eafad81082ba0450e',
                'name': '银行卡上传ocr识别',
                'code': 'ocr_bankCard',
                'menuId': 'ed506a9e24fb4c83bc66b42094af9042',
                'uri': '/bee-common-service/common/ocr/bankCard',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '40b92075b4744163bd52649adc53001f',
                'name': '元素配置_文件上传',
                'code': 'element_fileUpload',
                'menuId': 'ed506a9e24fb4c83bc66b42094af9042',
                'uri': '/trade-service/trade/insuredExcelMapping/api/getInsuredCount',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 9,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'cbbc02bcd4d64a5183e6c7b33129078d',
            'name': '模块配置',
            'code': null,
            'menuId': null,
            'uri': '/InsuranceConfiguration/moduleConfiguration/index',
            'icon': 'BuildOutlined',
            'parentId': '82767e48fd9349f0837d78fb568cad84',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 2,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'cfe789fca62340e48904258066e63941',
                'name': '模块-列表查询',
                'code': 'module_moduleList',
                'menuId': 'cbbc02bcd4d64a5183e6c7b33129078d',
                'uri': '/trade-service/trade/module/moduleList',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '3b9838abf1384b4a827e6bf8f5a989a3',
                'name': '模块-元素列表',
                'code': 'element_elementList',
                'menuId': 'cbbc02bcd4d64a5183e6c7b33129078d',
                'uri': '/trade-service/trade/element/elementList',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'be056f8b1cb24fc79669a6cf0c5502a7',
                'name': '模块-批量删除',
                'code': 'module_delete',
                'menuId': 'cbbc02bcd4d64a5183e6c7b33129078d',
                'uri': '/trade-service/trade/module/delete',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f57aee82136e402e8f8862570a88c3b6',
                'name': '模块-模块详情',
                'code': 'module_moduleDetails',
                'menuId': 'cbbc02bcd4d64a5183e6c7b33129078d',
                'uri': '/trade-service/trade/module/moduleDetails',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0d30c07c60104116b68a6dc9bd4b0cbb',
                'name': '模块-新增模块',
                'code': 'module_addModule',
                'menuId': 'cbbc02bcd4d64a5183e6c7b33129078d',
                'uri': '/trade-service/trade/module/addModule',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'aee6686ecf294482a7361e14a97d4410',
                'name': '模块-更新状态',
                'code': 'module_updateStatus',
                'menuId': 'cbbc02bcd4d64a5183e6c7b33129078d',
                'uri': '/trade-service/trade/module/updateStatus',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '5e173e8c36444b978a4f6bcf5af49688',
            'name': '流程节点配置',
            'code': null,
            'menuId': null,
            'uri': '/InsuranceConfiguration/process/proManagement',
            'icon': 'BranchesOutlined',
            'parentId': '82767e48fd9349f0837d78fb568cad84',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 3,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'bb7e6f2a61bb45629cba0761bcafc54a',
                'name': '流程管理-列表查询',
                'code': 'process_processList',
                'menuId': '5e173e8c36444b978a4f6bcf5af49688',
                'uri': '/trade-service/trade/process/processList',
                'icon': 'BulbOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7a0770adb2444d1ea8abc0b0026afdc2',
                'name': '接口类型-查询',
                'code': 'getProductInterface',
                'menuId': '5e173e8c36444b978a4f6bcf5af49688',
                'uri': '/trade-service/trade/process/getProductInterface',
                'icon': 'AccountBookFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '29f288a0830c400881bc81ff738bc378',
                'name': '流程管理-预览详情',
                'code': 'process_processDetails',
                'menuId': '5e173e8c36444b978a4f6bcf5af49688',
                'uri': '/trade-service/trade/process/processDetails',
                'icon': 'BulbOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '52dda5e1e7db47fe9a6ec4431c1c4383',
                'name': '流程管理-新增流程',
                'code': 'process_addProcess',
                'menuId': '5e173e8c36444b978a4f6bcf5af49688',
                'uri': '/trade-service/trade/process/addProcess',
                'icon': 'BulbOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a228dbefd54e4d6b8283fa4b1524bab8',
                'name': '流程管理-删除流程',
                'code': 'process_delete',
                'menuId': '5e173e8c36444b978a4f6bcf5af49688',
                'uri': '/trade-service/trade/process/delete',
                'icon': 'BulbOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2f75070bfb9044a4889b19afb18b4eae',
                'name': '流程管理-更新流程',
                'code': 'process_updateStatus',
                'menuId': '5e173e8c36444b978a4f6bcf5af49688',
                'uri': '/trade-service/trade/process/updateStatus',
                'icon': 'BulbOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'dc91b74fbaf942d984afba2a764e5992',
                'name': '流程管理-获取模板列表',
                'code': 'process_getTemplateList',
                'menuId': '5e173e8c36444b978a4f6bcf5af49688',
                'uri': '/trade-service/trade/process/getTemplateList',
                'icon': 'BulbOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'd47db44c41924ccb833beaa243995183',
                'name': '流程管理-节点配置修改',
                'code': 'process_updateNodes',
                'menuId': '5e173e8c36444b978a4f6bcf5af49688',
                'uri': '/trade-service/trade/process/updateNodes',
                'icon': 'BulbOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f1ac2c307fab4beca4e3fae9302948e2',
                'name': '流程管理-获取条款类型',
                'code': 'productFile_dataTypeList',
                'menuId': '5e173e8c36444b978a4f6bcf5af49688',
                'uri': '/product-service/product/productFile/dataTypeList',
                'icon': 'BulbOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'ef03a82b911c4a1ab3d56c7995a94b3b',
            'name': '产品流程配置',
            'code': null,
            'menuId': null,
            'uri': '/productConfiguration/associationConfig/index',
            'icon': 'SisternodeOutlined',
            'parentId': '82767e48fd9349f0837d78fb568cad84',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 4,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '098b31295f3f45ceb65afdf6aa28236e',
                'name': '获取流程列表',
                'code': 'process_getProgressList',
                'menuId': 'ef03a82b911c4a1ab3d56c7995a94b3b',
                'uri': '/trade-service/trade/process/getProgressList',
                'icon': 'AlipaySquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '38a1670f791a48299fd4b4048bb93eb7',
                'name': '查询产品关联流程配置',
                'code': 'productInfo_getListByProductAndProgress',
                'menuId': 'ef03a82b911c4a1ab3d56c7995a94b3b',
                'uri': '/product-service/product/productInfo/getListByProductAndProgress',
                'icon': 'AlipaySquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '17b9c7cffcf942b69964f2e01d70d6b7',
                'name': '关联取消关联',
                'code': 'productInfo_associatedProcess12',
                'menuId': 'ef03a82b911c4a1ab3d56c7995a94b3b',
                'uri': '/product-service/product/productInfo/associatedProcess',
                'icon': 'AlipaySquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '6188f171acbe4339b6b261a2be402c74',
            'name': '产品模板配置',
            'code': null,
            'menuId': null,
            'uri': '/InsuranceConfiguration/productTemplate/index',
            'icon': 'AppstoreFilled',
            'parentId': '82767e48fd9349f0837d78fb568cad84',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 5,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '5aa46875a9924128af1f1b5fdf971809',
                'name': '模板_列表查询',
                'code': 'productFlowTemplate_selectList',
                'menuId': '6188f171acbe4339b6b261a2be402c74',
                'uri': '/product-service/product/productFlowTemplate/selectList',
                'icon': 'FileSearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2a643ef075f5416587b3ddb19d6638de',
                'name': '模板_删除',
                'code': 'productFlowTemplate_del',
                'menuId': '6188f171acbe4339b6b261a2be402c74',
                'uri': '/product-service/product/productFlowTemplate/delete',
                'icon': 'DeleteOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '94bc3e3c91d04b1987b7d520a7546e6b',
                'name': '模板_新增/修改',
                'code': 'productFlowTemplate_add',
                'menuId': '6188f171acbe4339b6b261a2be402c74',
                'uri': '/product-service/product/productFlowTemplate/addFlowTemplate',
                'icon': 'SaveOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '55ebf3eb3399406aaf2563bbd084f3e6',
            'name': '健康告知配置（废弃）',
            'code': null,
            'menuId': null,
            'uri': '/InsuranceConfiguration/healthNotificationConfiguration/index',
            'icon': 'MenuOutlined',
            'parentId': '82767e48fd9349f0837d78fb568cad84',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 99,
            'status': null,
            'showFlag': 'N',
            'submenus': null,
            'actions': []
          }
        ],
        'actions': []
      },
      {
        'id': 'ba316b6ce66b4c81a4fc895c4dd1696f',
        'name': '接口平台管理',
        'code': null,
        'menuId': null,
        'uri': null,
        'icon': 'ApiOutlined',
        'parentId': '0',
        'childFlag': 'Y',
        'isMenu': 'Y',
        'orderFlag': 8,
        'status': null,
        'showFlag': 'Y',
        'submenus': [
          {
            'id': '67cf9a9272fd4ffdad87ce81236b4d1b',
            'name': '提供方接口管理',
            'code': null,
            'menuId': null,
            'uri': '/interfaceAdmin/InterfaceProviderManagement/index',
            'icon': 'SendOutlined',
            'parentId': 'ba316b6ce66b4c81a4fc895c4dd1696f',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 1,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '5ddf57f8ae29406fb509cca1e1419192',
                'name': '接口管理_查询',
                'code': 'ExcPort_selectList12',
                'menuId': '67cf9a9272fd4ffdad87ce81236b4d1b',
                'uri': '/edt-service/edt/ExcPort/selectList',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'ca40a9abaf414613802d46e7228672d3',
                'name': '接口管理_更新',
                'code': 'ExcPort_update',
                'menuId': '67cf9a9272fd4ffdad87ce81236b4d1b',
                'uri': '/edt-service/edt/ExcPort/update',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7920a8f5833f490f94be64f2a05b2183',
                'name': '接口管理_新增',
                'code': 'excport_insert23',
                'menuId': '67cf9a9272fd4ffdad87ce81236b4d1b',
                'uri': '/edt-service/edt/ExcPort/insert',
                'icon': 'FireFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a0eb1ce584c4479fbd080fe03b7c6ed0',
                'name': '详情查询',
                'code': 'ExcPort_selectById',
                'menuId': '67cf9a9272fd4ffdad87ce81236b4d1b',
                'uri': '/edt-service/edt/ExcPort/selectById',
                'icon': 'AccountBookFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0139dcbfe51c4f59b019a461c91d8856',
                'name': '接口管理_查询列表',
                'code': 'ExcPort_selectName',
                'menuId': '67cf9a9272fd4ffdad87ce81236b4d1b',
                'uri': '/edt-service/edt/ExcPort/selectName',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f2e6fcf2cd394de9b5c8f6df00b620d6',
                'name': '接口管理_批量删除',
                'code': 'ExcPort_deleteList',
                'menuId': '67cf9a9272fd4ffdad87ce81236b4d1b',
                'uri': '/edt-service/edt/ExcPort/deleteList',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'bb8d206c50ab42c1ad0dc0fcbb9846cc',
                'name': '数据同步_查询',
                'code': 'syncEdtData_selectEdtDataList',
                'menuId': '67cf9a9272fd4ffdad87ce81236b4d1b',
                'uri': '/product-service/product/syncEdtData/selectEdtDataList',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '08b469de098b4b21bfd9b27204643ba2',
                'name': '数据同步_数据同步',
                'code': 'syncEdtData_syncEdtData',
                'menuId': '67cf9a9272fd4ffdad87ce81236b4d1b',
                'uri': '/product-service/product/syncEdtData/syncEdtData',
                'icon': 'AmazonCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '28d8a88d33e049db8611bad5f8655b95',
            'name': '提供方接口参数管理',
            'code': null,
            'menuId': null,
            'uri': '/interfaceAdmin/InterfaceProviderParameters/index',
            'icon': 'SendOutlined',
            'parentId': 'ba316b6ce66b4c81a4fc895c4dd1696f',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 2,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'adfab253787445359859bf4ade4993c1',
                'name': '新增',
                'code': 'ExcPortConfig_insert',
                'menuId': '28d8a88d33e049db8611bad5f8655b95',
                'uri': '/edt-service/edt/ExcPortConfig/insert',
                'icon': 'AlipaySquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '67e9873e7fc9421d887e6493ad8b4e6d',
                'name': '统一删除',
                'code': 'ExcPortConfig_deleteTree',
                'menuId': '28d8a88d33e049db8611bad5f8655b95',
                'uri': '/edt-service/edt/ExcPortConfig/deleteTree',
                'icon': 'AlipaySquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'ff87b1afbe3d4c15a28b51c0e28e0136',
                'name': '参数导入',
                'code': 'ExcPortConfig_importPc',
                'menuId': '28d8a88d33e049db8611bad5f8655b95',
                'uri': '/edt-service/edt/ExcPortConfig/importPc',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '4a33b765aa1c4b81b8d8d9060d9152fb',
                'name': '更新',
                'code': 'ExcPortConfig_update',
                'menuId': '28d8a88d33e049db8611bad5f8655b95',
                'uri': '/edt-service/edt/ExcPortConfig/update',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '5a1bac8896c84c4dadc10c2dd960deb6',
                'name': '导出Json',
                'code': 'ExcExport_exportJson',
                'menuId': '28d8a88d33e049db8611bad5f8655b95',
                'uri': '/edt-service/edt/ExcExport/exportJson',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '8665c1fc7daf49dfa06eadebc2470049',
                'name': '导出xml',
                'code': 'ExcExport_exportXml',
                'menuId': '28d8a88d33e049db8611bad5f8655b95',
                'uri': '/edt-service/edt/ExcExport/exportXml',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a247b21c003c48b187a77946b8995e53',
                'name': '查询详情',
                'code': 'ExcPortConfig_selectById',
                'menuId': '28d8a88d33e049db8611bad5f8655b95',
                'uri': '/edt-service/edt/ExcPortConfig/selectById',
                'icon': 'AlipaySquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'bf9a1398cbf34f2ba4ecb9f211cc93cc',
                'name': '单个删除',
                'code': 'ExcPortConfig_delete',
                'menuId': '28d8a88d33e049db8611bad5f8655b95',
                'uri': '/edt-service/edt/ExcPortConfig/delete',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f006e75bba5b4ecd96f7f2b8793475f5',
                'name': '树形查询',
                'code': 'ExcPortConfig_selectTree',
                'menuId': '28d8a88d33e049db8611bad5f8655b95',
                'uri': '/edt-service/edt/ExcPortConfig/selectTree',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '3ef0d669ed6242e697b6729f7bff6bea',
            'name': '调用方管理',
            'code': null,
            'menuId': null,
            'uri': '/interfaceAdmin/dockingChannelManagement/index',
            'icon': 'MailOutlined',
            'parentId': 'ba316b6ce66b4c81a4fc895c4dd1696f',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 3,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '130d9a76050247f3b15738672aff865b',
                'name': '对接渠道管理_更新',
                'code': 'ExcInsurer_update',
                'menuId': '3ef0d669ed6242e697b6729f7bff6bea',
                'uri': '/edt-service/edt/ExcInsurer/update',
                'icon': 'AmazonCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0382cea822b644c98031bb88e0a49cf3',
                'name': '对接渠道管理_删除',
                'code': 'ExcInsurer_deleteList',
                'menuId': '3ef0d669ed6242e697b6729f7bff6bea',
                'uri': '/edt-service/edt/ExcInsurer/deleteList',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'd85f11ff1dca4fd0b7605c5c4cd2fcc4',
                'name': '对接渠道管理_查询',
                'code': 'ExcInsurer_selectList',
                'menuId': '3ef0d669ed6242e697b6729f7bff6bea',
                'uri': '/edt-service/edt/ExcInsurer/selectList',
                'icon': 'AmazonCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '8d7f029d7c2e43a3a86ec6d9568878b3',
                'name': '对接渠道管理_新增',
                'code': 'ExcInsurer_insert',
                'menuId': '3ef0d669ed6242e697b6729f7bff6bea',
                'uri': '/edt-service/edt/ExcInsurer/insert',
                'icon': 'AlipaySquareFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '874da34e7b6449679fe5392e890e4f1d',
                'name': '对接渠道管理_查询列表',
                'code': 'ExcInsurer_selectTree',
                'menuId': '3ef0d669ed6242e697b6729f7bff6bea',
                'uri': '/edt-service/edt/ExcInsurer/selectTree',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'd80536a44c4f4f4fbce70735c050e821',
            'name': '调用方接口管理',
            'code': null,
            'menuId': null,
            'uri': '/interfaceAdmin/channelAdmin/index',
            'icon': 'MailOutlined',
            'parentId': 'ba316b6ce66b4c81a4fc895c4dd1696f',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 4,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '7ce50752d30045669ba0e1f129b0fed4',
                'name': '接口管理_查询',
                'code': 'ExcInsurerPort_selectList',
                'menuId': 'd80536a44c4f4f4fbce70735c050e821',
                'uri': '/edt-service/edt/ExcInsurerPort/selectList',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'cfc874197e7e4c2b8fdc822b43ac333a',
                'name': '查询详情',
                'code': 'ExcInsurerPort_selectById',
                'menuId': 'd80536a44c4f4f4fbce70735c050e821',
                'uri': '/edt-service/edt/ExcInsurerPort/selectById',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '164bea76079c493ab72d624ec1856ddf',
                'name': '打开',
                'code': 'ExcInsurerPort_updatePut',
                'menuId': 'd80536a44c4f4f4fbce70735c050e821',
                'uri': '/edt-service/edt/ExcInsurerPort/updatePut',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7c1ee1aad2e64a09a9ec3558927e5b8d',
                'name': '渠道接口_新增',
                'code': 'ExcInsurerPort_insert',
                'menuId': 'd80536a44c4f4f4fbce70735c050e821',
                'uri': '/edt-service/edt/ExcInsurerPort/insert',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e16f96ad3f8247b6b74b85dc13d3cab0',
                'name': '接口管理_删除',
                'code': 'ExcInsurerPort_deleteList',
                'menuId': 'd80536a44c4f4f4fbce70735c050e821',
                'uri': '/edt-service/edt/ExcInsurerPort/deleteList',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '301b5203007146f58a5f2ac0a1d81fa7',
                'name': '接口管理_更新',
                'code': 'ExcInsurerPort_update',
                'menuId': 'd80536a44c4f4f4fbce70735c050e821',
                'uri': '/edt-service/edt/ExcInsurerPort/update',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '4d08755878544ec3ad0d6ee23e97c51d',
                'name': '列表查询',
                'code': 'ExcInsurerPort_selectPortList',
                'menuId': 'd80536a44c4f4f4fbce70735c050e821',
                'uri': '/edt-service/edt/ExcInsurerPort/selectPortList',
                'icon': 'AccountBookFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'd44fe7340a9b44d8a28043d6708b0d26',
                'name': '关闭',
                'code': 'ExcInsurerPort_updateOff',
                'menuId': 'd80536a44c4f4f4fbce70735c050e821',
                'uri': '/edt-service/edt/ExcInsurerPort/updateOff',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 14,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'ada17eec4df742e5921a5c779e8282b5',
            'name': '调用方接口参数管理',
            'code': null,
            'menuId': null,
            'uri': '/interfaceAdmin/channeParmsAdmin/index',
            'icon': 'MailOutlined',
            'parentId': 'ba316b6ce66b4c81a4fc895c4dd1696f',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 5,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '94cddfa9de034615a133a93230d58c37',
                'name': '树形查询',
                'code': 'ExcInsurerConfig_selectList',
                'menuId': 'ada17eec4df742e5921a5c779e8282b5',
                'uri': '/edt-service/edt/ExcInsurerConfig/selectList',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a24a14b379b24f0cb73f51fb99157177',
                'name': '接口参数导入保存',
                'code': 'ExcInsurerConfig_importPc',
                'menuId': 'ada17eec4df742e5921a5c779e8282b5',
                'uri': '/edt-service/edt/ExcInsurerConfig/importPc',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b46707ab68004cedbb39eff5ad1dbf85',
                'name': '左侧树结构',
                'code': 'select_treeLeft',
                'menuId': 'ada17eec4df742e5921a5c779e8282b5',
                'uri': '/edt-service/edt/ExcInsurerConfig/selectTree',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '4b086b0dba1e4d2788275df88b8386ad',
                'name': '统一删除',
                'code': 'deleteAll1_Tree',
                'menuId': 'ada17eec4df742e5921a5c779e8282b5',
                'uri': '/edt-service/edt/ExcInsurerConfig/deleteTree',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '02d330aa2eae46ea8f96897962efe8a8',
                'name': '新增',
                'code': 'ExcInsure_insert',
                'menuId': 'ada17eec4df742e5921a5c779e8282b5',
                'uri': '/edt-service/edt/ExcInsurerConfig/insert',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '24c4baa6d13e4609ba1bb9d173864e2c',
                'name': '删除',
                'code': 'ExcInsurerC_delete',
                'menuId': 'ada17eec4df742e5921a5c779e8282b5',
                'uri': '/edt-service/edt/ExcInsurerConfig/delete',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7a295ba211f54c89a63255d4b3bb7796',
                'name': '编辑',
                'code': 'ExcInsurerC_update',
                'menuId': 'ada17eec4df742e5921a5c779e8282b5',
                'uri': '/edt-service/edt/ExcInsurerConfig/update',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '9aed9949d7b946bcb09392b1ce2b3276',
                'name': '查询一条',
                'code': 'ExcInsurerC_selectById',
                'menuId': 'ada17eec4df742e5921a5c779e8282b5',
                'uri': '/edt-service/edt/ExcInsurerConfig/selectById',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 10,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'a79c789afdf44602bfa833d556c41039',
            'name': '调用方接口参数映射',
            'code': null,
            'menuId': null,
            'uri': '/interfaceAdmin/channelreflect/index',
            'icon': 'SwapOutlined',
            'parentId': 'ba316b6ce66b4c81a4fc895c4dd1696f',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 6,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'c3ecb162e04e40cba22090b12f227d0c',
                'name': '树形查询',
                'code': 'ExcInsurerConfig_selectList1',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerPortConfig/selectList',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '25b1bac68bef4682a50d4f55acf25174',
                'name': '清空参数映射',
                'code': 'excInsurerPortConfig_reset',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerPortConfig/reset',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '928990a3cc02409caa8edfe271641813',
                'name': '查询',
                'code': 'select_query1',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerPortConfig/selectQuery',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '19db38426fd44988a69ef2c54a2e8375',
                'name': '自动匹配接口',
                'code': 'ExcInsurerPortC_match',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerPortConfig/match',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7669f3edc87c4af1bee3c797cb5ae6ae',
                'name': '查询详情接口',
                'code': 'ExcInsurerPortC_SQ',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerPortConfig/selectQuery',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '05ddefbd6b5d4dd7b74aa28445e2a2a8',
                'name': '更新接口',
                'code': 'ExcInsurerP_update123',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerPortConfig/update',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f3ebadb58e0b457299b8b6ba4894e4ea',
                'name': '删除接口',
                'code': 'ExcInsurerPortConfig_delete123',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerPortConfig/delete',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b35564c9c9ab43d59113c3c79a70dd71',
                'name': '同步源数据',
                'code': 'ExcInsurerPortConfig_incrementMatch',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerPortConfig/incrementMatch',
                'icon': 'EyeFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '8589b72e17844bf38264c42e0988a3d9',
                'name': '获取接口列表',
                'code': 'ExcQuickInt_getPort',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcQuickInter/getPort',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 9,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '239037f34ef24fbd882921260492e662',
                'name': '删除全部',
                'code': 'ExcInsurerConfig_deleteAll',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerPortConfig/deleteAll',
                'icon': 'AlipayCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 10,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '49af5d41a9b4451d915bf082c2e95a92',
                'name': '映射关系查询',
                'code': 'getParamName',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerPortConfig/getParamName',
                'icon': 'AccountBookFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 11,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '998eac441be6471bb30dbacf8df59913',
                'name': '调用方字段获取',
                'code': 'selectListByPortId',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerConfig/selectListByPortId',
                'icon': 'AccountBookFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 12,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '56867e39288e4c399cf7e7982cf3edac',
                'name': '主页面删除',
                'code': 'productDoubleRecordSystem_deleteAll',
                'menuId': 'a79c789afdf44602bfa833d556c41039',
                'uri': '/edt-service/edt/ExcInsurerPortConfig/deleteAll',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 13,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'ec67ad53e3824ca887bb13f488617fa6',
            'name': '调用方接口参数值映射(暂存)',
            'code': null,
            'menuId': null,
            'uri': '/interfaceAdmin/codeValueCorrespondence/index',
            'icon': 'SwapOutlined',
            'parentId': 'ba316b6ce66b4c81a4fc895c4dd1696f',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 7,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'a37a340fb8f140c484e2d3149d3a8bb0',
                'name': '获取左侧树结构',
                'code': 'ExcQuickParam_getTree',
                'menuId': 'ec67ad53e3824ca887bb13f488617fa6',
                'uri': '/edt-service/edt/ExcQuickParam/getTree',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a5a85a62df4f43fc8c5381f689669a8d',
                'name': '渠道参数接口',
                'code': 'ExcInsurerC_selectListForIpId',
                'menuId': 'ec67ad53e3824ca887bb13f488617fa6',
                'uri': '/edt-service/edt/ExcInsurerConfig/selectListForIpId',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'ffe1496ec945490d9b5f9cd9c42de8bc',
                'name': '分页查询',
                'code': 'ExcCodeValue_selectList1',
                'menuId': 'ec67ad53e3824ca887bb13f488617fa6',
                'uri': '/edt-service/edt/ExcCodeValue/selectList',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '50b699c6bbed4761895c392be464ae48',
                'name': '新增',
                'code': 'ExcCodeValue_insert1',
                'menuId': 'ec67ad53e3824ca887bb13f488617fa6',
                'uri': '/edt-service/edt/ExcCodeValue/insert',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '6eb8c1915b0940f7a71d5a791b7d803d',
                'name': '删除',
                'code': 'ExcCodeValue_delete1',
                'menuId': 'ec67ad53e3824ca887bb13f488617fa6',
                'uri': '/edt-service/edt/ExcCodeValue/delete',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2cce77234a77491ea0413434c175f990',
                'name': '更新',
                'code': 'ExcCodeValue_update1',
                'menuId': 'ec67ad53e3824ca887bb13f488617fa6',
                'uri': '/edt-service/edt/ExcCodeValue/update',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'fac215720f9c4d56996cc1c8ba4cc6cd',
            'name': '接口平台日志查询',
            'code': null,
            'menuId': null,
            'uri': '/interfaceAdmin/transactionJournalManage/index',
            'icon': 'AliwangwangFilled',
            'parentId': 'ba316b6ce66b4c81a4fc895c4dd1696f',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 8,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'f52e51fb4c354d6f8b5016180d7b4842',
                'name': '列表查询',
                'code': 'ExcLogselect_selectList',
                'menuId': 'fac215720f9c4d56996cc1c8ba4cc6cd',
                'uri': '/edt-service/edt/ExcLog/selectList',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f0580e789307468a87bdc70b38d2c91d',
                'name': '重新发送',
                'code': 'resend_run',
                'menuId': 'fac215720f9c4d56996cc1c8ba4cc6cd',
                'uri': '/edt-service/edt/ExcLog/api/resend',
                'icon': 'AliwangwangFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'ef1a84af93404551903bbe08fb004c23',
                'name': '查看详情',
                'code': 'excLog_selectDetail',
                'menuId': 'fac215720f9c4d56996cc1c8ba4cc6cd',
                'uri': '/edt-service/edt/ExcLog/selectDetail',
                'icon': 'DeploymentUnitOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          }
        ],
        'actions': []
      },
      {
        'id': 'e3a8f673ec5e402b980625b07c20f538',
        'name': 'WEB模板管理（开发中）',
        'code': null,
        'menuId': null,
        'uri': null,
        'icon': 'SnippetsFilled',
        'parentId': '0',
        'childFlag': 'Y',
        'isMenu': 'Y',
        'orderFlag': 9,
        'status': null,
        'showFlag': 'Y',
        'submenus': [
          {
            'id': '73d1c203b68d4293bbe90c72bbeff601',
            'name': '模板列表',
            'code': null,
            'menuId': null,
            'uri': '/bee_editor/index',
            'icon': 'SlackOutlined',
            'parentId': 'e3a8f673ec5e402b980625b07c20f538',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 1,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'f56697965bfb4fd19ee89fed5318c626',
                'name': '新增模板',
                'code': 't_page_add',
                'menuId': '73d1c203b68d4293bbe90c72bbeff601',
                'uri': '/bee-editor-service/editor/template/edit',
                'icon': 'SaveOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '11adfbc73f4a428788a8b081687f726d',
                'name': '修改模板',
                'code': 't_page_edit',
                'menuId': '73d1c203b68d4293bbe90c72bbeff601',
                'uri': '/bee-editor-service/editor/template/edit',
                'icon': 'EditOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '298fac16ff3748f2a860e4c26ec740db',
                'name': '删除模板',
                'code': 't_page_delete',
                'menuId': '73d1c203b68d4293bbe90c72bbeff601',
                'uri': '/bee-editor-service/editor/template/delByIds',
                'icon': 'DeleteOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '264b6a00cafe4c688dd02afb8e9816ba',
                'name': '关联产品',
                'code': 't_page_link',
                'menuId': '73d1c203b68d4293bbe90c72bbeff601',
                'uri': '/bee-editor-service/editor/template/toProduct/edit',
                'icon': 'LinkOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7aec7065c85d4f9e895cd0a9016f2711',
                'name': '查看关联产品列表',
                'code': 't_page_link_products',
                'menuId': '73d1c203b68d4293bbe90c72bbeff601',
                'uri': '/bee-editor-service/editor/template/toProduct/selProductByTemplateId',
                'icon': 'LinkOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'fe864b6e489043af8de8e6e0e3e55680',
                'name': '预览',
                'code': 't_page_preview',
                'menuId': '73d1c203b68d4293bbe90c72bbeff601',
                'uri': '/bee-editor-service/editor/template/detail',
                'icon': 'EyeOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '584ba2de6e5043f7872d4f7cac75d0c9',
                'name': '查询模板',
                'code': 't_page_list',
                'menuId': '73d1c203b68d4293bbe90c72bbeff601',
                'uri': '/bee-editor-service/editor/template/selectByPage',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 9,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'f1342a4b8c4d49418696e0a5aa1b8ef6',
            'name': '编辑器',
            'code': null,
            'menuId': null,
            'uri': '/bee_editor/editor',
            'icon': 'EditOutlined',
            'parentId': 'e3a8f673ec5e402b980625b07c20f538',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 2,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': []
          }
        ],
        'actions': []
      },
      {
        'id': '7a468048314041e3a709af779c6ca89c',
        'name': '规则引擎',
        'code': null,
        'menuId': null,
        'uri': null,
        'icon': 'NodeExpandOutlined',
        'parentId': '0',
        'childFlag': 'Y',
        'isMenu': 'Y',
        'orderFlag': 10,
        'status': null,
        'showFlag': 'Y',
        'submenus': [
          {
            'id': '93906c2ae43d4dea9ab60035d9134e20',
            'name': '关键字管理',
            'code': null,
            'menuId': null,
            'uri': '/ruleEngine/KeywordManagement/index',
            'icon': 'KeyOutlined',
            'parentId': '7a468048314041e3a709af779c6ca89c',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 1,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '345bcae94cfe4b4fbcfa10a7df49b061',
                'name': '获取jsconfig',
                'code': 'getJsStr',
                'menuId': '93906c2ae43d4dea9ab60035d9134e20',
                'uri': '/bee-rule-service/rule/ruleKeyword/getJsStr',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '3d2b342946fa45bbb3c65226acefd7fe',
                'name': '关键字-新增/编辑',
                'code': 'ruleKeyword_add',
                'menuId': '93906c2ae43d4dea9ab60035d9134e20',
                'uri': '/bee-rule-service/rule/ruleKeyword/add',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '650e4ffd2be745d98e8b7bae48dd04fb',
                'name': '关键字-删除',
                'code': 'ruleKeyword_delete',
                'menuId': '93906c2ae43d4dea9ab60035d9134e20',
                'uri': '/bee-rule-service/rule/ruleKeyword/delete',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '8588b07dd2ee4464948a8f10f360c1dc',
                'name': '关键字-批量增加',
                'code': 'ruleKeyword_addList',
                'menuId': '93906c2ae43d4dea9ab60035d9134e20',
                'uri': '/bee-rule-service/rule/ruleKeyword/addList',
                'icon': 'KeyOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '6ffd0b88038a4735927c35735aa9594a',
                'name': '关键字-列表查询',
                'code': 'ruleKeyword_list',
                'menuId': '93906c2ae43d4dea9ab60035d9134e20',
                'uri': '/bee-rule-service/rule/ruleKeyword/list',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'f7146af47f0844d98b35dec3057bee91',
            'name': '规则管理',
            'code': null,
            'menuId': null,
            'uri': '/ruleEngine/RuleManagement/index',
            'icon': 'TagOutlined',
            'parentId': '7a468048314041e3a709af779c6ca89c',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 2,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'f6322170ece543aba07370d99cde8670',
                'name': '规则-列表查询',
                'code': 'ruleScript_list',
                'menuId': 'f7146af47f0844d98b35dec3057bee91',
                'uri': '/bee-rule-service/rule/ruleScript/list',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'd8629451ef454266bb470e7c96b9be21',
                'name': '规则-新增/编辑',
                'code': 'ruleScript_add',
                'menuId': 'f7146af47f0844d98b35dec3057bee91',
                'uri': '/bee-rule-service/rule/ruleScript/add',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '7e8f56e4bc544763a55e7956bd375a15',
                'name': '规则-详情',
                'code': 'ruleScript_details',
                'menuId': 'f7146af47f0844d98b35dec3057bee91',
                'uri': '/bee-rule-service/rule/ruleScript/details',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c7ba9d0a6b4b4748bf0a7cab4f936df4',
                'name': '规则-删除',
                'code': 'ruleScript_delete',
                'menuId': 'f7146af47f0844d98b35dec3057bee91',
                'uri': '/bee-rule-service/rule/ruleScript/delete',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '79e73483e30848bd8ab438ec0dc7ecb1',
                'name': '规则-查询原始规则列表',
                'code': 'ruleScript_originalRuleList',
                'menuId': 'f7146af47f0844d98b35dec3057bee91',
                'uri': '/bee-rule-service/rule/ruleScript/originalRuleList',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e05e7c24a517499d90b1ed6c2ba592b2',
                'name': '接口类型-查询',
                'code': 'getProductInterface',
                'menuId': 'f7146af47f0844d98b35dec3057bee91',
                'uri': '/trade-service/trade/process/getProductInterface',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '39f5c8792ebe461bb5405e8319525a5e',
            'name': '规则集管理',
            'code': null,
            'menuId': null,
            'uri': '/ruleEngine/RuleSetManagement/index',
            'icon': 'TagsOutlined',
            'parentId': '7a468048314041e3a709af779c6ca89c',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 3,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '19db69e18acc4e01af689ed0f9c1aad1',
                'name': '规则集-列表查询',
                'code': 'ruleCollection_list',
                'menuId': '39f5c8792ebe461bb5405e8319525a5e',
                'uri': '/bee-rule-service/rule/ruleCollection/list',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c92acd304dec451cb0bc9968bd026d4e',
                'name': '规则集-新增/编辑',
                'code': 'ruleCollection_add',
                'menuId': '39f5c8792ebe461bb5405e8319525a5e',
                'uri': '/bee-rule-service/rule/ruleCollection/add',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '821868c581a647b09cc76d7a79dbcaf0',
                'name': '规则集-删除',
                'code': 'ruleCollection_delete',
                'menuId': '39f5c8792ebe461bb5405e8319525a5e',
                'uri': '/bee-rule-service/rule/ruleCollection/delete',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2a4eaab450c642c4927361ad0646ccbf',
                'name': '规则集-详情',
                'code': 'ruleCollection_details',
                'menuId': '39f5c8792ebe461bb5405e8319525a5e',
                'uri': '/bee-rule-service/rule/ruleCollection/details',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'de133b4868414528a4f7e61c0c5a3cbb',
                'name': '规则集-发布',
                'code': 'ruleCollection_ruleRelease',
                'menuId': '39f5c8792ebe461bb5405e8319525a5e',
                'uri': '/bee-rule-service/rule/ruleCollection/ruleRelease',
                'icon': 'MediumOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          }
        ],
        'actions': []
      },
      {
        'id': 'owI7prtV',
        'name': '系统管理',
        'code': null,
        'menuId': null,
        'uri': '',
        'icon': 'SettingOutlined',
        'parentId': '0',
        'childFlag': 'Y',
        'isMenu': 'Y',
        'orderFlag': 999,
        'status': null,
        'showFlag': 'Y',
        'submenus': [
          {
            'id': 'tQHSCbue',
            'name': '用户管理',
            'code': null,
            'menuId': null,
            'uri': '/system/user/index',
            'icon': 'UserOutlined',
            'parentId': 'owI7prtV',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 1,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'f2b293d6e5a94f29b8ad83464b0c5ccd',
                'name': '用户-查询',
                'code': 'user_list',
                'menuId': 'tQHSCbue',
                'uri': '/bee-system-service/system/user/selectUserList',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c9d6f35b944e4ff39980c464c7110fce',
                'name': '用户-保存',
                'code': 'user_post',
                'menuId': 'tQHSCbue',
                'uri': '/bee-system-service/system/user/saveUserInfo',
                'icon': 'SaveOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'fb9e82dc59ac476eba36d3e9c934e884',
                'name': '用户-修改',
                'code': 'user_put',
                'menuId': 'tQHSCbue',
                'uri': '/bee-system-service/system/user/updateUserInfo',
                'icon': 'EditOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '4611402c2ac94e658122763bd53a953d',
                'name': '用户-删除',
                'code': 'user_delete',
                'menuId': 'tQHSCbue',
                'uri': '/bee-system-service/system/user/deleteUsersInfo',
                'icon': 'DeleteOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c54cfa8e29084135b9a3d8b324a0e0b9',
                'name': '用户-重置密码',
                'code': 'user_rest_pwd',
                'menuId': 'tQHSCbue',
                'uri': '/bee-system-service/system/user/resetPwd',
                'icon': 'SwapOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b64dbafbaca545eca17a01dd871e9a57',
                'name': '用户-查询角色',
                'code': 'user_queryRole',
                'menuId': 'tQHSCbue',
                'uri': '/bee-system-service/system/role/selectAllByUserId',
                'icon': 'CrownFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '32c2a563b013445cb6be23a367c85d94',
                'name': '用户-批量重置密码',
                'code': 'user_sendForgotPwdEmail',
                'menuId': 'tQHSCbue',
                'uri': '/bee-system-service/system/user/sendForgotPwdEmail',
                'icon': 'MehOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'p3ByPZ46',
            'name': '角色管理',
            'code': null,
            'menuId': null,
            'uri': '/system/role/index',
            'icon': 'LockOutlined',
            'parentId': 'owI7prtV',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 2,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '24e92b1e78634879bdfd2970296be64e',
                'name': '查询-全部角色',
                'code': 'role_selectList',
                'menuId': 'p3ByPZ46',
                'uri': '/bee-system-service/system/role/selectAll',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 0,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '18f82d75ab4041ae978f065d74925869',
                'name': '角色-保存',
                'code': 'role_save',
                'menuId': 'p3ByPZ46',
                'uri': '/bee-system-service/system/role/save',
                'icon': 'FileAddOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '17b8bce146e642d3a5b8d0af085a9654',
                'name': '角色-修改',
                'code': 'role_update',
                'menuId': 'p3ByPZ46',
                'uri': '/bee-system-service/system/role/update',
                'icon': 'EditOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e7960cbec965498e9f91afa753aa31bc',
                'name': '角色-删除',
                'code': 'role_delete',
                'menuId': 'p3ByPZ46',
                'uri': '/bee-system-service/system/role/deleteByIds',
                'icon': 'DeleteOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '35f2fb845c5343dda698b2088f4f1e75',
                'name': '查询-角色菜单',
                'code': 'role_menuQuery',
                'menuId': 'p3ByPZ46',
                'uri': '/bee-system-service/system/menuAction/selectAllInfoRoleId',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'dad3e5af47ec4fdab601310149dd16c5',
                'name': '保存角色菜单',
                'code': 'role_saveMenus',
                'menuId': 'p3ByPZ46',
                'uri': '/bee-system-service/system/menuAction/saveMenuAndAction',
                'icon': 'SaveOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '1b5f0ffb41264da79ba798f4bf350329',
                'name': '角色用户-添加',
                'code': 'role_saveUserRole',
                'menuId': 'p3ByPZ46',
                'uri': '/bee-system-service/system/userRole/saveUserRoles',
                'icon': 'FileAddOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '07192fe6712648fea45043ee614f4166',
                'name': '角色用户-查询',
                'code': 'role_queryUserRole',
                'menuId': 'p3ByPZ46',
                'uri': '/bee-system-service/system/userRole/selectInfoByRoleId',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '1599d616fd484fccb7dd5da9e8d5a448',
                'name': '角色用户-删除',
                'code': 'role_deleteUserRole',
                'menuId': 'p3ByPZ46',
                'uri': '/bee-system-service/system/userRole/deleteUserRoleByUserIds',
                'icon': 'DeleteOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '6b9163017c4a49d1af767012abcfeb12',
                'name': '用户-查询',
                'code': 'role_userQuery',
                'menuId': 'p3ByPZ46',
                'uri': '/bee-system-service/system/user/selectOutRoleUser',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 10,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'ynwmG0yn',
            'name': '菜单管理',
            'code': null,
            'menuId': null,
            'uri': '/system/menu/index',
            'icon': 'MenuOutlined',
            'parentId': 'owI7prtV',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 3,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'd702b1acb6fe49d3986219905a0befdc',
                'name': '查询-全部菜单',
                'code': 'menu_query_all',
                'menuId': 'ynwmG0yn',
                'uri': '/bee-system-service/system/menuAction/selectAllInfo',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 0,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '728f63732bc44858994940c7b4477ee7',
                'name': '菜单-增加',
                'code': 'menu_save',
                'menuId': 'ynwmG0yn',
                'uri': '/bee-system-service/system/menu/save',
                'icon': 'FileAddOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'd4bcc3904b1e4b2f83a17587faaf5b91',
                'name': '菜单-修改',
                'code': 'menu_update',
                'menuId': 'ynwmG0yn',
                'uri': '/bee-system-service/system/menu/updateInfo',
                'icon': 'EditOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '9feb2bc4cc254feaa58d5763ea93475a',
                'name': '菜单-删除',
                'code': 'menu_delete',
                'menuId': 'ynwmG0yn',
                'uri': '/bee-system-service/system/menu/deleteInfoByIds',
                'icon': 'DeleteOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'd5098ed978b746daae2d3c3f811ce1b1',
                'name': '操作-增加',
                'code': 'menu_action_save',
                'menuId': 'ynwmG0yn',
                'uri': '/bee-system-service/system/action/save',
                'icon': 'ThunderboltOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '44ee5b84d91f4dbd9cef872b3ff2b835',
                'name': '操作-修改',
                'code': 'menu_action_update',
                'menuId': 'ynwmG0yn',
                'uri': '/bee-system-service/system/action/updateInfo',
                'icon': 'ThunderboltOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a8e196b9cfe64a428fe83f4ef57be743',
                'name': '操作-删除',
                'code': 'menu_action_delete',
                'menuId': 'ynwmG0yn',
                'uri': '/bee-system-service/system/action/deleteInfoByIds',
                'icon': 'DeleteOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '8cce81467ff24e0791667b22182bbb72',
                'name': '查询-菜单角色',
                'code': 'menu_role_query',
                'menuId': 'ynwmG0yn',
                'uri': '/bee-system-service/system/menu/selectAllByMenuId',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '77770eb6f3534804aa3ff3089ae7b4c5',
                'name': '查询-操作角色',
                'code': 'action_role_query',
                'menuId': 'ynwmG0yn',
                'uri': '/bee-system-service/system/action/selectAllRoleByActionId',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 8,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '75b2729e228a49b3a9f2c0731ad1e65e',
                'name': '保存-菜单角色',
                'code': 'menu_role_save',
                'menuId': 'ynwmG0yn',
                'uri': '/bee-system-service/system/menu/saveMenuRoleByMenuId',
                'icon': 'SaveOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 9,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b43df7b6283f45a88eba758e5c836b3e',
                'name': '保存-操作角色',
                'code': 'action_role_save',
                'menuId': 'ynwmG0yn',
                'uri': '/bee-system-service/system/action/saveRoleActionByActionId',
                'icon': 'SaveOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 10,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'LrY51vxJ',
            'name': '图标示例',
            'code': null,
            'menuId': null,
            'uri': '/system/icon/index',
            'icon': 'SmileOutlined',
            'parentId': 'owI7prtV',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 4,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': []
          },
          {
            'id': 'k320ddvn',
            'name': '数据字典',
            'code': null,
            'menuId': null,
            'uri': '/system/dictionary/index',
            'icon': 'ReadOutlined',
            'parentId': 'owI7prtV',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 5,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'bbdb73ee12e0416eadf90af813880495',
                'name': '字典-查询数据项',
                'code': 'dictionary_query',
                'menuId': 'k320ddvn',
                'uri': '/product-service/product/api/queryArea',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 0,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a1f3cb9cb9a7457283680c4d92c8e1f1',
                'name': '字典-分页查询',
                'code': 'dictionary_selectAll',
                'menuId': 'k320ddvn',
                'uri': '/bee-system-service/system/dictionary/selectAll',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0158804882b2429c8ac3e8848b0cefd9',
                'name': '字典-新增类型',
                'code': 'dictionary_save_type',
                'menuId': 'k320ddvn',
                'uri': '/bee-system-service/system/dictionary/save',
                'icon': 'FileAddOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '21209834001740c1993e82d6db12159e',
                'name': '字典-新增数据项',
                'code': 'dictionary_save',
                'menuId': 'k320ddvn',
                'uri': '/bee-system-service/system/dictionary/selectAll',
                'icon': 'AppstoreAddOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '820849f53bb4474cadcf8d2ef09630e3',
                'name': '字典-详情',
                'code': 'dictionary_query_detail',
                'menuId': 'k320ddvn',
                'uri': '/bee-system-service/system/dictionray/detail',
                'icon': 'FileSyncOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '79fde7d292c4457e8c05e3b6000c8dbd',
                'name': '字典-删除',
                'code': 'dictionary_delete',
                'menuId': 'k320ddvn',
                'uri': '/bee-system-service/system/dictionary/deleteByIds',
                'icon': 'DeleteOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e4005ae96e85497792132f384cf1eerd',
                'name': '市、县信息查询',
                'code': 'queryCityAndCountyInfo',
                'menuId': 'k320ddvn',
                'uri': '/product-service/product/api/queryCityAndCountyInfo',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '4658cf613f1c41e3af621f2a13b0c56d',
                'name': '字典-修改',
                'code': 'dictionary_update',
                'menuId': 'k320ddvn',
                'uri': '/bee-system-service/system/dictionary/update',
                'icon': 'EditFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e4005ae96e85497792132f384cf1ea42',
                'name': '字典-查询数据类型',
                'code': 'dictionary_query_byType',
                'menuId': 'k320ddvn',
                'uri': '/bee-system-service/system/dictionary/api/selectAllForTree',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e4005ae96e85497792132f384cf1errt',
                'name': '省信息查询',
                'code': 'queryProvinceInfo',
                'menuId': 'k320ddvn',
                'uri': '/product-service/product/api/queryProvinceInfo',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'e4005ae96e85497792132f384cf1e32w',
                'name': '省市区级联查询',
                'code': 'queryArea',
                'menuId': 'k320ddvn',
                'uri': '/product-service/product/api/queryArea',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 7,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'ce04600f7bc44540b678aeb8f52ad125',
                'name': '获取字典code对应的名称',
                'code': 'selectNameByTypeAndCode',
                'menuId': 'k320ddvn',
                'uri': '/bee-system-service/system/dictionary/selectNameByTypeAndCode',
                'icon': 'ThunderboltFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 15,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'c54a2101968b41858e2749d8adeaab3f',
                'name': '获取上传参数接口',
                'code': 'getTempToken',
                'menuId': 'k320ddvn',
                'uri': '/product-service/product/cos/getTempToken',
                'icon': null,
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 15,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'ynwmH1yn',
            'name': '数据同步配置',
            'code': null,
            'menuId': null,
            'uri': '/system/dataSync/index',
            'icon': 'FileSyncOutlined',
            'parentId': 'owI7prtV',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 6,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '15199d4f899842d986fdc3e9b3eeacr1',
                'name': '环境数据同步-查询',
                'code': 'env_sync_list',
                'menuId': 'ynwmH1yn',
                'uri': '/product-service/product/envSync/queryListForPage',
                'icon': 'ios-search',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '15199d4f899842d986fdc3e9b3eeae10',
                'name': '环境数据同步-详情',
                'code': 'env_sync_queryOne',
                'menuId': 'ynwmH1yn',
                'uri': '/product-service/product/envSync/queryOne',
                'icon': 'ios-search',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '15199d4f899842d986fdc3e9b3eeae32',
                'name': '环境数据同步-删除',
                'code': 'env_sync_delete',
                'menuId': 'ynwmH1yn',
                'uri': '/product-service/product/envSync/delete',
                'icon': 'ios-search',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '15199d4f899842d986fdc3e9b3eeaerd',
                'name': '环境数据同步-新增',
                'code': 'env_sync_save',
                'menuId': 'ynwmH1yn',
                'uri': '/product-service/product/envSync/save',
                'icon': 'ios-search',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '15199d4f899842d986fdc3e9b3eeaetr',
                'name': '环境数据同步-更新',
                'code': 'env_sync_update',
                'menuId': 'ynwmH1yn',
                'uri': '/product-service/product/envSync/update',
                'icon': 'ios-search',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'f4e14b457d5f4ca9931024584a3d04f9',
                'name': '环境数据同步-系统名称or编码',
                'code': 'env_sync_query',
                'menuId': 'ynwmH1yn',
                'uri': '/product-service/product/envSync/query',
                'icon': 'MehOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 6,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '8bc3a799f3444536ab1b32ee89188257',
            'name': '定时任务',
            'code': null,
            'menuId': null,
            'uri': '/system/monitor/dtt',
            'icon': 'ClockCircleOutlined',
            'parentId': 'owI7prtV',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 7,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '25d74879ebf94e3c85bcaaada6418e95',
                'name': '定时任务初始化',
                'code': 'dtt_init',
                'menuId': '8bc3a799f3444536ab1b32ee89188257',
                'uri': '/bee-system-service/system/monitor/dtt/dtt_list',
                'icon': 'ClockCircleOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'bdf9f4e5eaa145549e8cfb32d43ff018',
                'name': '状态更新',
                'code': 'dtt_change',
                'menuId': '8bc3a799f3444536ab1b32ee89188257',
                'uri': '/bee-system-service/system/monitor/dtt/dtt_change_status',
                'icon': 'ClockCircleOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '1fd4cbfb61e14dafbe2426779df1cd1c',
                'name': '实例状态更新',
                'code': 'dtt_change2',
                'menuId': '8bc3a799f3444536ab1b32ee89188257',
                'uri': '/bee-system-service/system/monitor/dtt/dtt_change_status2',
                'icon': 'ClockCircleOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0944a12442dd405eb9fc0e76b07cf487',
                'name': '执行计划更新',
                'code': 'dtt_change_cron',
                'menuId': '8bc3a799f3444536ab1b32ee89188257',
                'uri': '/bee-system-service/system/monitor/dtt/dtt_change_cron_new',
                'icon': 'ClockCircleOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '2e038ecf13c447098ba6950f667c61fc',
                'name': '获取企业列表',
                'code': 'getCorpList',
                'menuId': '8bc3a799f3444536ab1b32ee89188257',
                'uri': '/bee-system-service/system/monitor/dtt/getCorpListByTaskId',
                'icon': 'ShopOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 5,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': 'b53f1fae7a134e77b9473d3988edfc9d',
            'name': '双录系统管理',
            'code': null,
            'menuId': null,
            'uri': '/system/sign/index',
            'icon': 'ControlFilled',
            'parentId': 'owI7prtV',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 8,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '7400a302a89047cbb808f30edd644a39',
                'name': '签名管理_查询',
                'code': 'productDoubleRecordSystem_queryAll',
                'menuId': 'b53f1fae7a134e77b9473d3988edfc9d',
                'uri': '/product-service/product/productDoubleRecordSystem/queryAll',
                'icon': 'AlertFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'fb0659a8ab554ab1a82758169a711410',
                'name': '签名管理_新增',
                'code': 'productDoubleRecordSystem_addOne',
                'menuId': 'b53f1fae7a134e77b9473d3988edfc9d',
                'uri': '/product-service/product/productDoubleRecordSystem/insertDr',
                'icon': 'AmazonCircleFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '0c22a68ad26841449fff3fa06eb76078',
                'name': '签名管理_更新',
                'code': 'productDoubleRecordSystem_updateOne',
                'menuId': 'b53f1fae7a134e77b9473d3988edfc9d',
                'uri': '/product-service/product/productDoubleRecordSystem/updateDr',
                'icon': 'AccountBookFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'b02d0133e3cc46dc8cf43db19ab292cc',
                'name': '签名管理_删除',
                'code': 'productDoubleRecordSystem_del',
                'menuId': 'b53f1fae7a134e77b9473d3988edfc9d',
                'uri': '/product-service/product/productDoubleRecordSystem/deleteDr',
                'icon': 'AccountBookFilled',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '522db22cc4a44dc8ba52a011b530a40d',
            'name': '全局配置',
            'code': null,
            'menuId': null,
            'uri': '/system/globalConfig/index',
            'icon': 'UsbOutlined',
            'parentId': 'owI7prtV',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 9,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': 'e4a33c8cdcdd446da27fef08b2767575',
                'name': '产品发布开关',
                'code': 'productSwitch_update',
                'menuId': '522db22cc4a44dc8ba52a011b530a40d',
                'uri': '/product-service/product/productSwitch/api/update',
                'icon': 'BlockOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          },
          {
            'id': '2fe735fd6022430dba315150b8565389',
            'name': 'excel表头维护',
            'code': null,
            'menuId': null,
            'uri': '/system/headerMaintenance/index',
            'icon': 'HeartOutlined',
            'parentId': 'owI7prtV',
            'childFlag': 'N',
            'isMenu': 'Y',
            'orderFlag': 10,
            'status': null,
            'showFlag': 'Y',
            'submenus': null,
            'actions': [
              {
                'id': '1b472c051eff40f2a9490649b33d7a97',
                'name': '查询',
                'code': 'excel_selectList',
                'menuId': '2fe735fd6022430dba315150b8565389',
                'uri': '/product-service/product/excelMapping/selectList',
                'icon': 'SearchOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 1,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '4800aabee16f47c297b5c607c5ceaa4f',
                'name': '删除',
                'code': 'excel_deleteBatch',
                'menuId': '2fe735fd6022430dba315150b8565389',
                'uri': '/product-service/product/excelMapping/deleteBatch',
                'icon': 'DeleteOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 2,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': '494a6961c3be4897b460edcbd63af905',
                'name': '新增数据',
                'code': 'excel_insert',
                'menuId': '2fe735fd6022430dba315150b8565389',
                'uri': '/product-service/product/excelMapping/insert',
                'icon': 'SaveOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 3,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              },
              {
                'id': 'a79a5933cf0541fc84e94ed407feb6d5',
                'name': '修改数据',
                'code': 'excel_update',
                'menuId': '2fe735fd6022430dba315150b8565389',
                'uri': '/product-service/product/excelMapping/update',
                'icon': 'EditOutlined',
                'parentId': null,
                'childFlag': null,
                'isMenu': 'N',
                'orderFlag': 4,
                'status': null,
                'showFlag': null,
                'submenus': null,
                'actions': null
              }
            ]
          }
        ],
        'actions': []
      }
    ]
  },
  'pagination': null
}

// export const login = function (uri, param) {
export const login = function () {
  return new Promise((resolve) => {
    let res = demo
    if (res && res.code === 1) {
      store.commit('user/init', {
        userId: res.result.userId,
        userName: res.result.userName,
        loginId: res.result.employeeId,
        loginName: res.result.loginName,
        corpId: res.result.corpId,
        corpName: res.result.corpName,
        corpList: [],
        beeId: res.result.beeId,
        isInitPassword: res.result.isInitPassword,
        roleTree: res.result['menus']
      })
      resolve({code: 1, message: '登录成功！'})
    } else {
      resolve({code: 0, message: res.message})
    }
  })
  // return request(uri, param, true, 'post').then(res => {
  //   if (res && res.code === 1) {
  //     store.commit('user/init', {
  //       userId: res.result.userId,
  //       userName: res.result.userName,
  //       loginId: res.result.employeeId,
  //       loginName: res.result.loginName,
  //       corpId: res.result.corpId,
  //       corpName: res.result.corpName,
  //       corpList: [],
  //       beeId: res.result.beeId,
  //       isInitPassword: res.result.isInitPassword,
  //       roleTree: res.result['menus']
  //     })
  //     return Promise.resolve({code: 1, message: '登录成功！'})
  //   } else {
  //     return Promise.resolve({code: 0, message: res.message})
  //   }
  // })
}
