import {request} from './request'
import store from '../store'

const extendUri = {
  // --- bee-system-service ---
  queryDictionaryType: '/bee-system-service/system/common/dictionary/getByType',
  queryDictionaryTypes: '/bee-system-service/system/common/dictionary/getByTypes',
  dictionary_query_byType: '/bee-system-service/system/dictionary/api/selectAllForTree',
  // --- product-service ---
  queryArea: '/product-service/product/api/queryArea',
  queryOccupation: '/product-service/product/productProfessionCommon/api/selectByFirst',
  querySubOccupation: '/product-service/product/productProfessionCommon/api/selectByNext',
  querySupplier: '/product-service/product/supplier/common/querySupplier',
  postSelectById: '/product-service/product/productInfo/api/selectById',
  postQueryListForPage: '/product-service/product/productInsured/api/queryListForPage',
  queryListForPageFront: '/product-service/product/productInsured/api/queryListForPageFront',
  calPremium: '/product-service/product/productPremium/api/CalPremium',
  productInfoSelect: '/product-service/product/productInfo/select',
  // 新增的 职业查询
  selectprofessionByName: '/product-service/product/productProfessionCommon/api/selectProfessionByName',
  // --- trade-service ---
  getProductInterface: '/trade-service/trade/process/getProductInterface',
  processDetails: '/trade-service/trade/process/api/processDetails',
  record: '/trade-service/trade/submit/api/record',
  saveData: '/trade-service/trade/data/api/saveData',
  selectByInsuredId: '/trade-service/trade/data/api/selectByInsuredId',
  queryCashValueByTradeNo: '/trade-service/trade/insuredPayData/api/getRelaTimeCashValue',
  getOperatorSwitch: '/trade-service/trade/data/api/getOperatorSwitch',
  getElectronicProposal: '/trade-service/trade/insuredPayData/api/getElectronicProposal',
  confirmBankAccountProtocol: '/trade-service/trade/insuredPayData/api/confirmBankAccountProtocol',
  applyBankAccountProtocol: '/trade-service/trade/insuredPayData/api/applyBankAccountProtocol',
  // confirmCharge: '/trade-service/trade/insuredPayData/api/confirmCharge',
  confirmCharge: '/trade-service/trade/insuredPayData/api/paymentInterface',
  processNodeDetails: '/trade-service/trade/process/api/processNodeDetails',
  rwebData: '/trade-service/trade/rwebData/api/save',
  supplierQuerySupLevelSt: '/product-service/product/supplier/querySupLevelSt',
  // 查询保单号&流水号
  // -----------------------------------------
  // 创建新的链接
  createProductLink: '/product-service/product/productDetailLink/api/createProductLink',
  // 解析产品详情
  getLinkData: '/product-service/product/productDetailLink/api/getLinkData',
  // getTenNum: '/product-servic/product/test/testRequest'
  getTenNum: '/trade-service/trade/genSerialNum/api/genOrderNo',
  // 跳转节点
  getProcessByProductId: '/trade-service/trade/process/api/getProcessByProductId',

  getPayStateByTradeNo: '/trade-service/trade/order/api/selInsuredPayInfoByTradeNo',
  // 校验核保 （特殊标识用来区分是否进行核保）
  // 只校验不核保 弃用
  // saveDataRuleCheck: '/trade-service/trade/order/api/saveDataRuleCheck'
  // 新增缓存数据接口 （特殊标识用来区分是否进行核保）
  cacheParameters: '/trade-service/trade/order/api/cacheParameters',

  // 获取数据接口
  getCacheParameters: '/trade-service/trade/order/api/getCacheParameters',
  // 删除接口
  delCacheParameters: '/trade-service/trade/order/api/delCacheParameters',
  // 告知关联
  getNodeHealthTold: '/trade-service/trade/process/api/getNodeHealthTold',
  // 查询元素关联的健康告知
  getElementHealthTold: '/trade-service/trade/element/api/getElementHealthTold',
  // 查询是否有未完成的订单
  getTradeNoInDoing: '/trade-service/trade/order/api/getTradeNoByShareId',
  // -------------签名流程所需接口------------start------------
  // 短信校验-获取需校验的代理人
  // getPhoneDate: 'https://www.fastmock.site/mock/7a0f6b235434d2ea83e7dd52c4e891c3/product/getPhoneDate',
  getPhoneDate: '/trade-service/trade/insuredSignature/api/getCheckedMessage',
  // 校验验证码
  // sendSMS: 'https://www.fastmock.site/mock/7a0f6b235434d2ea83e7dd52c4e891c3/product/senSMS',
  sendSMS: '/trade-service/trade/insuredSignature/api/getMessage',

  // 获取验证码
  // getVCode: 'https://www.fastmock.site/mock/7a0f6b235434d2ea83e7dd52c4e891c3/product/getVCode',
  getVCode: '/trade-service/trade/insuredSignature/api/sendMessage',
  // 签名
  getSignList: '/trade-service/trade/insuredSignature/api/getTradeSigned',
  // getSignList: 'https://www.fastmock.site/mock/7a0f6b235434d2ea83e7dd52c4e891c3/product/sign/getSignList',
  // 发送签名信息（图片是base64的字符串）
  signEvent: '/trade-service/trade/insuredSignature/api/updateStatus',
  // 获取文件及签名信息
  getSignDate: '/trade-service/trade/insuredSignature/api/selectSignFile',
  // getSignDate: 'https://www.fastmock.site/mock/7a0f6b235434d2ea83e7dd52c4e891c3/product/sign/getSignDate',
  // 人脸识别
  // 获取人脸识别信息
  // face: 'https://www.fastmock.site/mock/7a0f6b235434d2ea83e7dd52c4e891c3/product/api/faceApi',
  face: '/trade-service/trade/insuredSignature/api/face',

  // 瑞泰人脸识别
  faceRecognition: '/trade-service/trade/insuredSignature/api/faceRecognition',
  // 获取人脸识别结果
  // getFaceResult: 'https://www.fastmock.site/mock/7a0f6b235434d2ea83e7dd52c4e891c3/product/api/getFaceResult',
  getFacialReturn: '/trade-service/trade/insuredSignature/api/getFacialReturn',
  getFaceResult: '/trade-service/trade/insuredSignature/api/notifyCallBack/',
  // -------------签名流程所需接口------------end------------
  //告知问卷存储
  savecacheHealthTold: '/trade-service/trade/order/api/cacheHealthTold',
  //告知问卷获取
  getCacheHealthTold: '/trade-service/trade/order/api/getCacheHealthTold ',
  //告知问卷删除
  delCacheHealthTold: '/trade-service/trade/order/api/delCacheHealthTold',
  // 多被保人的保费查询
  getMultipleCalPremium: '/product-service/product/productPremium/api/multipleCalPremium',
  // 多被保人的数据储存
  getCacheNodeDetails: '/trade-service/trade/order/api/cacheNodeDetails',

  // 获取双录信息
  getDoubleRecordsInfo: '/trade-service/trade/doubleRecording/api/getDoubleRecordingStatus',
  // getDoubleRecordsInfo: 'https://www.fastmock.site/mock/7a0f6b235434d2ea83e7dd52c4e891c3/product/getDoubleRecordsInfo'
  element_fileUpload : '/trade-service/trade/insuredExcelMapping/api/getInsuredCount',
  // 获取支付后sharedId
  getShareIdByTradeNo: '/trade-service/trade/insured/api/getShareId',

  // 智能核保
  getIntelligent: '/trade-service/trade/intelligenceHB/api/getLink',
  // 智能核保完成后的接口
  getReceive : '/edt-service/edt/intelligenceHB/api/receive',
  // 确认页调用流程链接口
  getInterfaceRequest: '/trade-service/trade/data/api/interfaceRequest',
  // 清除上一次智能核保的标识
  clearHistoryInfo: '/trade-service/trade/intelligenceHB/api/clearHistoryInfo',
  // 获取短信
  getTKSignatureLink: '/trade-service/trade/insuredSignature/api/sendTKSignatureLink',
  getSelectContStatus: '/trade-service/trade/data/api/selectContStatus',
  getCheckedMessage: '/trade-service/trade/insuredSignature/api/getCheckedMessage',
  // 详情页跳转校验
  getProcessAgentVerify: '/trade-service/trade/process/api/processAgentVerify',
  // 这个是获取产品跳转链接
  getH5LinkData: '/product-service/product/productDetailLink/api/getH5LinkData',
  // 支付状态 - 国富
  getPaySel: '/trade-service/trade/guofu/api/paySel',
  getSendVerifyCode: '/trade-service/trade/process/api/sendVerifyCode',
  getCheckMessage: '/trade-service/trade/process/api/getMessage',
  // 获取疾病数据
  selAllAndCommon: '/trade-service/trade/disease/api/selAllAndCommon',
  // 初始化
  initMethod: '/trade-service/trade/disease/api/init',
  // 获取疾病的问题
  question: '/trade-service/trade/disease/api/question',
  // 保存疾病
  saveLogs: '/trade-service/trade/disease/api/saveLogs',
  // 获取PDF
  getPdfDecompose: '/trade-service/trade/pdfHandle/api/pdfDecompose',
  // 获取险种列表
  getRiskList: '/product-service/product/disease/api/getRiskList',
  // 获取疾病树集合
  getDiseaseList: '/product-service/product/disease/api/getDiseaseList',
  // 保存
  saveNotifyResult: '/product-service/product/disease/api/saveNotifyResult',
  // 获取问题列表
  getDiseaseQuestionList: '/product-service/product/disease/api/getDiseaseQuestionList?id=5&getType=1',
  // 获取结论
  getOptionResult: '/product-service/product/disease/api/getOptionResult?optionId=2371',
  getProductCodeByShareId: '/trade-service/trade/order/api/getProductCodeByShareId',
  checkCustomerInfo: '/checkCustomerInfo',
  // setInsuredInfo: '/trade-service/trade/policy/api/renewalData',
  // 取续期投保数据
  getInsuredInfo: '/trade-service/trade/policy/api/getRenewalData',

  // 健告转人核 保存数据
  onSavePerson: '/trade-service/trade/uw/api/save',
  // 查询当前保单是否需要转人核
  getUwStatus: '/trade-service/trade/uw/api/getUwstatus',


}
/**
 * 获取非业务数据请求
 * 业务数据务必通过菜单权限控制获得URL
 * 函数命名格式： http请求方式+请求业务对象名称（或缩写）
 */
export const getExtendUri = function (key) {
    return extendUri[key]
}

export const getDictionaryDataInApi = function (bind, dicKey) {
  let data = store.getters['dictionary/getData'](dicKey)
  if (data) {
    bind.value = data
  } else {
    request(getExtendUri('dictionary_query_byType'), {data: {types: [dicKey]}}).then(result => {
      if (result.code === 1) {
        let d = result.result[dicKey]
        bind.value = d
        store.commit('dictionary/setCache', {type: dicKey, data: d})
      }
    })
  }
}

export const getDictionaryData = function (bind, dicKey) {
  let data = store.getters['dictionary/getData'](dicKey)
  if (data) {
    bind.value = data
  } else {
    request(getExtendUri('queryDictionaryType'), {data: {type: dicKey}}).then(result => {
      console.debug('getDictionaryData请求结果', result)
      if (result.code === 1) {
        bind.value = result.result
        store.commit('dictionary/setCache', {type: dicKey, data: result.result})
      }
    })
  }
}

export const reloadDictionaryData = function (dicKey) {
  request(getExtendUri('queryDictionaryType'), {data: {type: dicKey}}).then(result => {
    console.debug('getDictionaryData请求结果', result)
    if (result.code === 1) {
      store.commit('dictionary/setCache', {type: dicKey, data: result.result})
    }
  })
}

export const getDictionaryValue = function (dicKey, dicCode) {
  return store.getters['dictionary/getValue'](dicKey, '' + dicCode)
}

export const getRuleJsConfigMethod = () => {
  request('/bee-rule-service/rule/ruleKeyword/api/getJsStr', {}, true, 'post').then(res => {
    if (res && res.code === 1) {
      store.commit('rule/updateRuleJSInfo', res.result)
    }
  })
}

const onlyPayUrl = ['100402', '1043', '1006', '1010']
export const isOnlyPayUrl = (sId) => {
  for (let i = 0; i < onlyPayUrl.length; i++) {
    if (sId === onlyPayUrl[i]) {
      return true
    }
  }
  return false
}

/**
 * 判断tradeno支付状态
 * @param tradeNo
 * @returns {Promise<void>}
 */
export const tradeNoStateIshavePay = async (e) => {
  let payState = await request(getExtendUri('getPayStateByTradeNo'), {
    data: {
      tradeNo: e
    }
  }).then(res => {
    if (res && res.code === 1) {
      return res.result
    } else {
      return '-1'
    }
  })
  return payState
}


export const isSureSaveDateHaveShareId = (sDate) => {
  if (!sDate.baseInfo.shareId) {
    sDate.baseInfo.shareId = store.getters['insured/getShareId'] || ''
  }
  return sDate
}
