/**
 * 腾讯云对象存储工具类
 * 以及上传用到的工具类
 * 1. 封装上传文件方法，判断上传文件为视频还是图片,或其他
 * 2. 视频如果大于5M，则分片上传。
 * uploadObject 上传文件到腾讯云对象存储的指定桶
 * removeObject 删除腾讯云对象存储指定桶中的文件
 * getObjectList 查询桶下的数据
 *  文档：
 *  配置-地址： https://cloud.tencent.com/document/product/436/11459
 *  上传-地址 https://cloud.tencent.com/document/product/436/64960
 *  大致步骤描述：
 *   1. 获取临时密钥 getCOSSecretKey
 *   2. 初始化cos
 *   3. 判断上传文件的类型 （懒得判断了````暂时在页面控制）
 *   4. 判断文件大小 是否需要分片上传
 *   5. 上传到腾讯云cos
 */
// todo 待补充下载cos文件
import COS from 'cos-js-sdk-v5'
import {request} from './request'
import {message} from 'ant-design-vue'
/**
 * 华为云obs
 * 先做简单上传，暂不做分片断点续传
 * https://support.huaweicloud.com/sdk-browserjs-devg-obs/obs_24_0001.html
 */
import ObsClient from 'esdk-obs-browserjs'
// import {isObject} from 'lodash-es'


// 获取临时密钥 getCOSSecretKey
// 初始化cos
// 判断上传文件的类型
// 判断文件大小 是否需要分片上传
// 上传到腾讯云cos
/**
 * 随机一个uuid
 * @returns {string}
 */
const getUUID = () => {
  let s = []
  let hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '_'
  let uuid = s.join('')
  return uuid
}
/**
 * 格式化时间
 * @param _label
 * @returns {string}
 */
const getSimpleDate = (_label = '/') => {
  let date = new Date()
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let strDate = date.getDate()
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  return year + _label + month + _label + strDate
}
/**
 * 获取-上传-的文件的key
 * 此处可以自定义要上传的文件的key
 * @param folder
 * @param filename
 * @returns {string}
 */
const getKey = (folder, filename) => {
  const uuid = getUUID()
  return folder + getSimpleDate() + '/' + uuid + '/' + filename
}

/**
 * 获取临时密钥
 * @returns {Promise<{bucket: string, TmpSecretKey: string, folder: string, XCosSecurityToken: string, ExpiredTime: string, StartTime: string, ScopeLimit: boolean, region: string, TmpSecretId: string}>}
 */
// const cos = {
//   bucket: '',
//   region: '',
//   folder: '',
//   TmpSecretId: '',
//   TmpSecretKey: '',
//   XCosSecurityToken: '',
//   StartTime: '',
//   ExpiredTime: '',
//   ScopeLimit: true
// }

const getCOSSecretKey = (flag) => {
  let uri = '/product-service/product/cos/getTempToken'
  let param = {
    'data': {
      // 'type': flag
      'type': ''
    },
  }
  return request(uri, param).then(result => {
    console.log('请求结果:' + flag + JSON.stringify(result))
    return result
  })
}
const cosType = 'cos'
const obsType = 'obs'
/**
 * 再加一个获取列表，不显示在页面，我只看log数据使用
 */
export function getObjectListByCos(callback) {
  getCOSSecretKey(cosType).then(response => {
    let data = response.result
    console.log(data)
    // 后台接口返回 密钥相关信息
    var credentials = data && data.credentials
    if (!data || !credentials) return console.error('credentials invalid')
    // 初始化
    var cos = new COS({
      getAuthorization: (options, callback) => {
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          XCosSecurityToken: credentials.sessionToken,
          StartTime: data.startTime,
          ExpiredTime: data.expiredTime,
          expiration: data.expiration,
          requestId: data.requestId,
        })
      },
    })
    cos.getBucket({
      Bucket: data.app_cosBucket, // 存储桶名称
      Region: data.app_cosRegion, // 存储桶所在地域，必须字段
      Prefix: 'upload/common/product/',           /* Prefix表示列出的object的key以prefix开始，非必须 */
    }, function (err, data) {
      console.log(err || data.Contents)
      callback(data.Contents)
    })
  })


}

/**
 * 上传
 * 上传可以用高级上传方式（自动判断是否大文件去做分片处理）---不想用
 * 批量上传循环使用该方法啊
 * @param file
 * @param callback
 */
// eslint-disable-next-line no-unused-vars
function uploadObjectByCos(file, callback) {
  console.log('file, flag', file)
  /* 1.获取临时秘钥data
   2.初始化
   3.判断上传文件的类型
   4.判断文件大小 是否需要分片上传*/
  let type = file.name.split('.').pop() // 得到上传的后缀名
  console.log('file, type', type)
  let fileName = file.name || ''
  const origin_file_name = fileName.split('.').slice(0, fileName.split('.').length - 1).join('.') // 获取文件名称
  // 获取密钥
  getCOSSecretKey(cosType).then(response => {
    let data = response.result
    console.log(data)
    // 后台接口返回 密钥相关信息
    var credentials = data && data.credentials
    if (!data || !credentials) return console.error('credentials invalid')
    // 初始化
    var cos = new COS({
      getAuthorization: (options, callback) => {
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          XCosSecurityToken: credentials.sessionToken,
          StartTime: data.startTime,
          ExpiredTime: data.expiredTime,
          expiration: data.expiration,
          requestId: data.requestId,
        })
      },
    })
    // 获取上传文件大小
    let size = file.size
    let key = getKey(data.app_cosFolder, fileName)
    console.log('key', key)
    if (size / (1024 * 2024) < 5) {
      console.log('普通上传')
      cos.putObject(
        {
          Bucket: data.app_cosBucket, // 存储桶名称
          Region: data.app_cosRegion, // 存储桶所在地域，必须字段
          Key: key, // 名称
          StorageClass: 'STANDARD',
          Body: file, // 上传文件对象
          onHashProgress: (progressData) => {
            console.log('校验中', JSON.stringify(progressData))
          },
          onProgress: (progressData) => {
            const percent = parseInt(progressData.percent * 10000) / 100
            const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100
            console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;')
            callback(type, '', origin_file_name, key, percent, true)
          },
        },
        (err, data) => {
          if (err) {
            console.log('err', err)
            message.error('文件上传失败,请重新上传')
            callback(type, '', origin_file_name, key, -1, false)

          } else {
            let fileUrl = 'https://' + data.Location
            callback(type, fileUrl, origin_file_name, key, -1, true)
          }
        }
      )
    } else {
      console.log('大文件分块上传')
      // 上传分块
      cos.sliceUploadFile({
          Bucket: data.app_cosBucket, // 存储桶名称
          Region: data.app_cosRegion, // 存储桶所在地域，必须字段
          Key: key /* 必须 */,
          Body: file,
          onTaskReady: (taskId) => {
            /* 非必须 */
            console.log(taskId)
          },
          onHashProgress: (progressData) => {
            /* 非必须 */
            console.log(JSON.stringify(progressData))
          },
          onProgress: function (progressData) {
            const percent = parseInt(progressData.percent * 10000) / 100
            const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100
            console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;')
            callback(type, '', origin_file_name, key, percent, true)
          },
        },
        (err, data) => {
          if (err) {
            console.log(err)
            message.error('文件上传失败,请重新上传')
            callback(type, '', origin_file_name, key, -1, false)
          } else {
            let fileUrl = 'https://' + data.Location
            callback(type, fileUrl, origin_file_name, key,-1, true)
          }
        }
      )
    }
  })
}

// 删除腾讯云cos文件
export function removeObjectByCos(key, callback) {
  getCOSSecretKey(cosType).then(response => {
    let data = response.result
    console.log(data)
    // 后台接口返回 密钥相关信息
    var credentials = data && data.credentials
    var cos = new COS({
      getAuthorization: function (options, callback) {
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          XCosSecurityToken: credentials.sessionToken,
          StartTime: data.startTime,
          ExpiredTime: data.expiredTime,
          expiration: data.expiration,
          requestId: data.requestId,
        })
      },
    })
    cos.deleteObject(
      {
        Bucket: data.app_cosBucket, // 存储桶名称
        Region: data.app_cosRegion, // 存储桶所在地域，必须字段
        Key: key /* 必须 */,
      },
      function (err, data) {
        if (data) {
          console.log('文件删除成功')
          message.success('文件删除成功')
        } else {
          message.error('文件删除失败')
        }
        callback(data)
      }
    )
  })
}

// 查询视频文件的信息
export function getCOSVideoInfo(key, callback) {
  getCOSSecretKey(cosType).then(response => {
    let data = response.result
    console.log(data)
    // 后台接口返回 密钥相关信息
    var credentials = data && data.credentials
    var cos = new COS({
      getAuthorization: function (options, callback) {
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          XCosSecurityToken: credentials.sessionToken,
          StartTime: data.startTime,
          ExpiredTime: data.expiredTime,
          expiration: data.expiration,
          requestId: data.requestId,
        })
      },
    })
    cos.request({
        Bucket: data.app_cosBucket, // 存储桶名称
        Region: data.app_cosRegion, // 存储桶所在地域，必须字段
        Method: 'GET',
        Key: key,  /* 存储桶内的媒体文件，必须字段 */
        Query: {
          'ci-process': 'videoinfo' /** 固定值，必须 */
        }
      },
      function (err, data) {
        if (err) {
          console.log(err)
          message.error('查询视频文件信息失败！')
        } else {
          callback(data.Response)
        }
      }
    )
  })

}



// 下载腾讯云cos数据
function getDownloadInfoByCos(file) {
  getCOSSecretKey(cosType).then(response => {
    let data = response.result
    // 后台接口返回 密钥相关信息
    var credentials = data && data.credentials
    var cos = new COS({
      getAuthorization: function (options, callback) {
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          XCosSecurityToken: credentials.sessionToken,
          StartTime: data.startTime,
          ExpiredTime: data.expiredTime,
          expiration: data.expiration,
          requestId: data.requestId,
        })
      },
    })
    cos.getObject({
      Bucket: data.app_cosBucket, /* 必须 */
      Region: data.app_cosRegion,     /* 存储桶所在地域，必须字段 */
      Key: file.cosKey,              /* 必须 */
      DataType: 'blob',
      Protocol: 'https:',
      Sign: true,
      Expires: 3600, // 单位秒
    }, function (err, data) {
      var blob = new Blob([data.Body]) //data.Body是文件
      const elink = document.createElement('a')
      elink.download = file.name
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    })
  })

}

// // 获取 对象的值 忽略大小写下划线  openid  openId open_id
// export const getValueIgnoreCamelCase = (key, obj) => {
//   if (!isObject(obj)) {
//     return null
//   }
//   let keys = Object.keys(obj)
//   for (let i = 0; i < keys.length; i++) {
//     const k = keys[i]
//     if (key.toLowerCase() === k.toLowerCase()) {
//       return obj[k]
//     }
//   }
//   return undefined
// }

// 桶名称	 	product-8ec7
// 区域		cn-north-4
// 可用分区名称cn-north-4a/cn-north-4b/cn-north-4c/cn-north-4g
// Endpoint 	obs.cn-north-4.myhuaweicloud.com
// 访问域名	product-8ec7.obs.cn-north-4.myhuaweicloud.com

// // 桶名
// let bucketName = 'product-8ec7'
// // 临时access
// let accessKeyId = 'XRG0U8HTZVQBBQ4CZ5XA'
// // 临时secret
// let secretAccessKey = 'yvV742xAcvxHOV0Z6cnqYIdykKvCkp2lT2Xa5JLB'
// // 临时token，配合临时access和临时secret使用，否则403
// let security_token = '临时token'
// // endPoint
// let endPoint = 'obs.cn-north-4.myhuaweicloud.com'
// // 上传的路劲
// let path = 'upload/common/product/'
// // 访问的server
// let obsServer = `https://${endPoint}`
let obsInit = {
  bucketName: '',
  accessKeyId: '',
  secretAccessKey: '',
  security_token: '',
  endPoint: '',
  path: ''
}
let obsServer = ''


const initObsClient = async () => {
  let obsClient = await getCOSSecretKey(obsType).then(res => {
    if (res.code === 1 && res.result) {
      let data = res.result
      console.log('data', data)
      // obsInit = {
      //   bucketName: 'product-8ec7',
      //   accessKeyId: 'XRG0U8HTZVQBBQ4CZ5XA',
      //   secretAccessKey: 'yvV742xAcvxHOV0Z6cnqYIdykKvCkp2lT2Xa5JLB',
      //   security_token: '',
      //   endPoint: 'obs.cn-north-4.myhuaweicloud.com',
      //   path: 'upload/common/product/'
      // }
      // obsServer=`https://${obsInit.endPoint}`
      obsInit = {
        bucketName: data.app_cosBucket,
        accessKeyId: data.credentials.tmpSecretId,
        secretAccessKey: data.credentials.tmpSecretKey,
        security_token: data.credentials.sessionToken,
        endPoint:  data.app_endPoint.replace('https://',''),
        path: data.app_cosFolder.charAt(0) == '/'? data.app_cosFolder.substr(1): data.app_cosFolder
      }
      obsServer=data.app_endPoint
      // 创建ObsClient实例
      return new ObsClient({
        access_key_id: obsInit.accessKeyId,
        secret_access_key: obsInit.secretAccessKey,
        security_token: obsInit.security_token,
        server: obsServer
      })
    } else {
      message.error(res.message)
    }
  })
  // 创建ObsClient实例
  return obsClient
}


/**
 * 华为云文件上传
 * @param file
 * @param callback
 */
// eslint-disable-next-line no-unused-vars
const uploadFileByObs = async (file, callback) => {
  // 得到上传的后缀名
  let type = file.name.split('.').pop()
  console.log('file, type', type)
  let fileName = file.name || ''
  // 得到文件名
  const origin_file_name = fileName.split('.').slice(0, fileName.split('.').length - 1).join('.') // 获取文件名称
  console.log('origin_file_name', origin_file_name)
  let key = getKey(obsInit.path, fileName)
  let client = await initObsClient()
  client.putObject({
    Bucket: obsInit.bucketName,
    Key: key,
    SourceFile: file,
    ProgressCallback: function (transferredAmount, totalAmount, totalSeconds) {
      // 获取上传平均速率（KB/S）
      console.log(transferredAmount * 1.0 / totalSeconds / 1024)
      // 获取上传进度百分比
      console.log(transferredAmount * 100.0 / totalAmount)
      callback(type, '', origin_file_name, key, transferredAmount * 100.0 / totalAmount, true)
    }
  }, function (err, result) {
    if (err) {
      console.error('Error-->' + err)
      message.error('文件上传失败,请重新上传')
      callback(type, '', origin_file_name, key,-1, false)
    } else {
      console.log('Status2-->' + result.CommonMsg.Status)
      // 因为访问路径OBS没有给你返回需要自行拼接   拼接格式： https:// +  桶名 + endPoint  +  /  + 上传的文件路径
      // let fileUrl = `https://${visitPath}/${key}`
      let fileUrl = `https://${obsInit.bucketName}.${obsInit.endPoint}/${key}`
      callback(type, fileUrl, origin_file_name, key,-1,true)
    }
  })
}

/**
 * 删除华为云obs文件
 * @param key
 * @param callback
 */
const removeObjectByObs = async (key, callback) => {
  let client = await initObsClient()
  client.deleteObject({
    Bucket : obsInit.bucketName,
    Key : key
  }, function (err, result) {
    if(err){
      console.error('Error-->' + err)
    }else{
      console.log('Status-->' + result.CommonMsg.Status)
      callback('Deletion completed')
    }
  })
}
/**
 * obs文件下载
 */
const downloadFileByObs = async (file) => {
  let client = await initObsClient()
  client.getObject({
    Bucket : obsInit.bucketName,
    Key : file.cosKey,
    SaveByType : 'arraybuffer'
  }, function (err, result) {
    if(err){
      console.error('Error-->' + err)
    }else{
      console.log('Status-->' + result.CommonMsg.Status)
      if(result.CommonMsg.Status < 300 && result.InterfaceResult){
        console.log(result.InterfaceResult.Content)
        let blob = new Blob([result.InterfaceResult.Content]) //data.Body是文件
        const elink = document.createElement('a')
        elink.download = file.name
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      }
    }
  })
}



// 进度条
export const uploadFile2222 = async (file, callback) => {
  // 得到上传的后缀名
  let type = file.name.split('.').pop()
  console.log('file, type', type)
  let fileName = file.name || ''
  // 得到文件名
  const origin_file_name = fileName.split('.').slice(0, fileName.split('.').length - 1).join('.') // 获取文件名称
  console.log('origin_file_name', origin_file_name)
  let key = getKey(fileName)
  // 文件表单
  let param = new FormData()
  param.append('path', key)
  //通过append向form对象添加数据
  param.append('file', file)
  // 请求头
  let config = {
    //添加请求头
    headers: { 'Content-Type': 'multipart/form-data' },
    //添加上传进度监听事件
    onUploadProgress: e => {
      var completeProgress = ((e.loaded / e.total * 100) | 0)
      let progress = completeProgress
      // 回显进度条
      callback(type, '', origin_file_name, key, progress, true)
    }
  }
  param.config = config
  request('/bee-common-service/common/file/api/upload', param,true,'post').then(res => {
    console.log('result',res.result)
    if(res.code !== 1){
      message.error('文件上传失败,请重新上传')
      callback(type, '', origin_file_name, key, -1, false)
    }else{
      let fileUrl = res.result
      message.success('文件上传成功')
      callback(type, fileUrl, origin_file_name, key, -1, true)
    }
  })

}

export const uploadObject = (file, type, callback) => {
  if (type === 'cos') {
    uploadObjectByCos(file, callback)
  } else {
    uploadFileByObs(file, callback)
  }
}

// export const uploadObject = (file, type, callback) => {
//   uploadFile(file, callback)
// }
export const removeObject = (key, type, callback) => {
  if (type === 'cos') {
    removeObjectByCos(key, callback)
  } else {
    removeObjectByObs(key, callback)
  }
}
export const getDownloadInfo = (file, type) => {
  if (type === 'cos') {
    getDownloadInfoByCos(file)
  } else {
    downloadFileByObs(file)
  }
}
export default {
  uploadObject,
  removeObject,
  getDownloadInfo

}
