import {message} from 'ant-design-vue'
import store from '../store'
import {request} from './request'
import {getExtendUri} from './request_extend'
import {findPropValue, formatNumberToFix2, getPremCalculatorParam} from './utlis'
import {Dialog} from 'vant'
import {newPremiumList, specialRiskJudge} from './special_treatment'
import router from '@/router'
import {ref, watch} from 'vue'


const RiskState = {
  init_flag: -1,
  productInfo: undefined,
  riskListCopy: undefined,
  firstMainRiskInfo: undefined,
  moreMainRiskList: undefined,
  riskFormData: undefined,
  optionalDutys: undefined,
  optionalDutyCopy: undefined,
  checkedExit: undefined,
  requestTime: 0
}
const nameStr = '同主险'
const isNumber = (obj) => {
  return !isNaN(Number(obj))
}

// 初始化数据
const initRiskData = (tempData = [], mainRiskType = 'first') => {
  const main_risk_arr = tempData.filter(item => item.riskSign === '1' && item.riskSubFlag !== '1')
  if (mainRiskType === 'first') {
    RiskState.firstMainRiskFactorArray = []
    RiskState.firstMainRiskFactorNameArray = []
    let main_risk = main_risk_arr[0]
    main_risk['calculateFactor'].forEach((f) => {
      RiskState.firstMainRiskFactorArray.push(f.factorType)
      RiskState.firstMainRiskFactorNameArray.push(f.elementName)
    })
    main_risk['additionalRiskList'] = tempData.filter(item => ((item.riskSign === '1' && item.riskSubFlag === '1') || item.riskSign === '2'))
    // console.debug('查看这个', main_risk)
    return main_risk
  } else if (main_risk_arr.length > 1) {
    let more_arr = main_risk_arr.splice(0, 1)
    more_arr.forEach((item) => {
      item['additionalRiskList'] = tempData.filter(item => (item.riskSign === '2' || (item.riskSign === '1' && item.riskSubFlag === '1')))
    })
    return more_arr
  }
  return []
}
const resetRiskOptionalDutyFlag = () => {
  if (RiskState.optionalDutys.length > 0 && RiskState.riskFormData.riskList.length > 0) {
    RiskState.riskFormData.riskList.forEach(riskItem => {
      if (riskItem.dutyList &&  riskItem.dutyList.length > 0) {
        riskItem.dutyList.forEach(dutyItem => {
          if (RiskState.optionalDutys.includes(dutyItem.id)) {
            riskItem.hasOptionalDuty = true
          }
        })
      }
    })
  }
}
// 入口
const createRiskInfo = (productInfo, riskList = []) => {
  RiskState.productInfo = productInfo
  RiskState.riskListCopy = JSON.parse(JSON.stringify(RiskState.productInfo['riskList']))
  RiskState.firstMainRiskInfo = initRiskData(RiskState.productInfo['riskList'])
  RiskState.moreMainRiskList = initRiskData(RiskState.productInfo['riskList'], 'more')
  RiskState.riskFormData = {
    productId: RiskState.productInfo['productId'],
    riskList: new Array(RiskState.productInfo['riskList'].length)
  }
  RiskState.optionalDutys = []
  RiskState.optionalDutyCopy = []
  RiskState.checkedExit = []

  // level_1 遍历险种
  for (let i = 0; i < RiskState.productInfo['riskList'].length; i++) {
    let factors = RiskState.productInfo['riskList'][i]['calculateFactor']
    let dutys = RiskState.productInfo['riskList'][i]['dutyList']
    RiskState.productInfo['riskList'][i]['index'] = i
    RiskState.productInfo['riskList'][i]['isShow'] = true
    RiskState.riskFormData.riskList[i] = {
      index: i,
      doubleMainRiskFlag: RiskState.productInfo['riskList'][i]['doubleMainRiskFlag'],
      riskSubFlag: RiskState.productInfo['riskList'][i]['riskSubFlag'],
      riskId: RiskState.productInfo['riskList'][i]['riskId'],
      riskCode: RiskState.productInfo['riskList'][i]['riskCode'],
      riskName: RiskState.productInfo['riskList'][i]['riskName'],
      riskSign: RiskState.productInfo['riskList'][i]['riskSign'],
      supRiskCode: RiskState.productInfo['riskList'][i]['supRiskCode'],
      repulsionKey: RiskState.productInfo['riskList'][i]['repulsionKey'],
      premiumCalculation: RiskState.productInfo['riskList'][i]['premiumCalculation'],
      isSelected: RiskState.productInfo['riskList'][i]['riskSign'] === '1' || RiskState.productInfo['riskList'][i]['isSelected'] === 1,
      calculateFactor: new Array(factors.length),
      // coverageDataList: RiskState.productInfo['riskList'][i]['coverageDataList'],
      dutyList: new Array(dutys.length),
      exemptRisk: RiskState.productInfo['riskList'][i]['exemptRisk'],
      isShow: true,
      hasOptionalDuty: false
    }
    // 默认选中 附加险
    if ((RiskState.productInfo['riskList'][i]['riskSign'] === '2' || RiskState.productInfo['riskList'][i]['riskSign'] === '3') && RiskState.productInfo['riskList'][i]['isSelected'] === 1) {
      RiskState.checkedExit.push(RiskState.productInfo['riskList'][i]['riskName'])
    }
    // level_2 遍历责任
    for (let j = 0; j < dutys.length; j++) {
      if (dutys[j]['isRisk'] === 'Y') {
        RiskState.optionalDutys.push(dutys[j]['id'])
        RiskState.optionalDutyCopy.push(dutys[j]['id'])
      }
      RiskState.riskFormData.riskList[i]['dutyList'][j] = {
        id: dutys[j]['id'],
        riskId: RiskState.productInfo['riskList'][i]['riskId'],
        dutyCode: dutys[j]['dutyCode'],
        comDutyCode: dutys[j]['comDutyCode'],
        dutyName: dutys[j]['dutyName'],
        dutyIntroduce: dutys[j]['dutyIntroduce'],
        introduceFirstline: dutys[j]['deductibleExcess'] + '',
        isShow: dutys[j]['isShowStatus'] === 'Y',
        // isOptional -> isRisk 险种责任 是否可选
        isOptional: dutys[j]['isRisk'] === 'Y',
        // isSelected -> isRisk 投保要素关联险种责任 不可选的一定选中
        isSelected: dutys[j]['isRisk'] === 'N',
        countMode: dutys[j]['countMode'],
        isRelatedPremiumCalculation: dutys[j]['isRelatedPremiumCalculation'] === 'Y',
        premiumAmount: initDutyPremAmount(dutys[j]),
        repulsionKey: dutys[j]['repulsionKey'] || '',
        disabled: dutys[j]['factorTypeList'] && dutys[j]['factorTypeList'].length > 0
      }
    }

    // level_2 遍历投保要素
    let mult = 0, bc = 0, amnt = -1
    for (let j = 0; j < factors.length; j++) {
      const factorValues = factors[j]['factorValueList']
      RiskState.riskFormData.riskList[i]['calculateFactor'][j] = {
        factorId: factors[j]['factorId'],
        factorType: factors[j]['factorType'],
        isShow: factors[j]['isShow'] === 'Y',
        factorInMain: factors[j]['isWithMainRisk'] === 'Y',
        hasRelaDuty: false
      }
      const sameToMain = factors[j]['isWithMainRisk'] === 'Y' && RiskState.productInfo['riskList'][i]['riskSign'] !== '1'
      // level_3 遍历投保要素值
      if (factorValues && factorValues.length > 0) {
        RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValue'] = factorValues[0]['value']
        RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValueName'] = sameToMain ? nameStr : factorValues[0]['name']
        for (let k = 0; k < factorValues.length; k++) {
          // 投保要素值 初始化
          if (riskList.length > 0) {
            if (riskList[i] && riskList[i]['calculateFactor'][j]) {
              RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValue'] = riskList[i]['calculateFactor'][j]['factorValue']
              RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValueName'] = sameToMain ? nameStr : riskList[i]['calculateFactor'][j]['factorValueName']
            }
          }else if (factorValues[k]['defaultShow'] === 'Y') {
            RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValue'] = factorValues[k]['value']
            RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValueName'] = sameToMain ? nameStr : factorValues[k]['name']
          }
          if (factorValues[k]['dutyList'] && factorValues[k]['dutyList'].length > 0) {
            RiskState.riskFormData.riskList[i]['calculateFactor'][j]['hasRelaDuty'] = true
            resetOptionalDuty(factorValues[k]['dutyList'])
          }
        }
        // 投保要素值关联责任
        resetElementRelaDuty(factorValues, {value: RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValue'], hasRelaDuty: RiskState.riskFormData.riskList[i]['calculateFactor'][j]['hasRelaDuty']}, i)
        let detail = ''
        const calList = riskList.filter(item => item.riskId === RiskState.riskFormData.riskList[i]['riskId'])
        if(riskList.length && calList.length){
          detail = calList[0].calculateFactor.filter(cal => cal.factorType === factors[j]['factorType'])[0].factorValue
        }else{
          const defaultShowList = factorValues.filter(f => f.defaultShow === 'Y')
          detail = defaultShowList && defaultShowList.length ? defaultShowList[0]['value'] : factorValues[0]['value']
        }
        // 投保要素值关联其他投保要素
        resetFactorValueRelation(RiskState.riskFormData.riskList[i]['riskId'], factors, {factorType: factors[j]['factorType'], value:detail})
      }
      // 初始化 保额 = 份数 * 基础保额
      if (factors[j]['factorType'] === 'Mult') {
        mult = RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValue']
      } else if (factors[j]['factorType'] === 'BaseCoverage') {
        bc = RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValue']
      }
      if (mult > 0 && bc > 0) {
        amnt = mult * bc
      }
      if (amnt >= 0 && factors[j]['factorType'] === 'Amnt') {
        RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValue'] = amnt + ''
        RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValueName'] = amnt + ''
      }
    }
  }
  resetRiskOptionalDutyFlag()
  specialRiskJudge(RiskState.riskFormData, 0)
  if (riskList.length > 0) {
    RiskState.riskFormData.riskList = riskList
    RiskState.riskListCopy.riskList = riskList
  }
  return RiskState
}
// 设置可选责任列表
const resetOptionalDuty = (valList) => {
  for (let j = 0; j < valList.length; j++) {
    if (valList[j]['isOptional'] !== 'Y' && RiskState.optionalDutyCopy.includes(valList[j]['riskDutyId'])) {
      RiskState.optionalDutyCopy.splice(RiskState.optionalDutyCopy.indexOf(valList[j]['riskDutyId']), 1)
      RiskState.optionalDutys.splice(RiskState.optionalDutys.indexOf(valList[j]['riskDutyId']), 1)
    }
  }
}
// 初始化责任保费/保额
const initDutyPremAmount = (dutyObj) => {
  if (dutyObj && dutyObj.countMode === '0') {
    return dutyObj.premiumAmount
  } else if (dutyObj && dutyObj.countMode === '1') {
    return dutyObj.floatCoefficient
  } else if (dutyObj && dutyObj.countMode === '2') {
    if (dutyObj.riskDutyPremiumList && dutyObj.riskDutyPremiumList.length > 0) {
      dutyObj.premiumAmount = dutyObj.riskDutyPremiumList[0]['coverage']
    }
    return dutyObj.premiumAmount
  } else if (dutyObj && dutyObj.countMode === '3') {
    if (dutyObj.riskDutyPremiumScopeList && dutyObj.riskDutyPremiumScopeList.length > 0) {
      dutyObj.premiumAmount = dutyObj.riskDutyPremiumScopeList[0]['coverage']
    }
    return dutyObj.premiumAmount
  }
  return ''
}
// 处理投保要素值关联
const resetFactorValueRelation = (riskId, factorList, valObject) => {
  if (!riskId) return
  const obj = {}
  let result = true
  const newFactorList = JSON.parse(JSON.stringify(RiskState.riskListCopy.filter(risk => {
    return risk.riskId === riskId
  })[0]['calculateFactor']))
  for (let j = 0; j < factorList.length; j++) {
    // 查找 当前变化的投保要素类型
    if (factorList[j].factorType === valObject.factorType) {
      const valueList = factorList[j]['factorValueList'] || []
      for (let k = 0; k < valueList.length; k++) {
        // 查找 当前变化的投保要素类型 的 值
        if (valueList[k]['value'] === valObject.value && valueList[k]['valueRelationList'] && valueList[k]['valueRelationList'].length > 0) {
          const relationList = valueList[k]['valueRelationList'] || []
          for (let item = 0; item < relationList.length; item++) {
            if (obj[relationList[item].elementType] == undefined) {
              obj[relationList[item].elementType] = []
            }
            obj[relationList[item].elementType].push(relationList[item].value)
          }
          break
        }
      }
      break
    }
  }
  if (Object.keys(obj).length === 0) {
    return result
  }
  for (let j = 0; j < factorList.length; j++) {
    let arr = obj[factorList[j].factorType] || []
    if (arr.length > 0) {
      // result = false
      factorList[j]['factorValueList'] = [...newFactorList.filter(factor => {
        return factor.factorType === factorList[j].factorType
      })[0]['factorValueList'].filter(item => {
        return arr.includes(item.value)
      })]
    }
  }
  return result
}
// 对附加险投保要素 赋值
const collationFactor = (valObject = {}) => {
  if (valObject === 'riskStatusChange' || valObject.riskId === RiskState.firstMainRiskInfo.riskId) {
    const mainRisk = RiskState.riskFormData['riskList'][RiskState.firstMainRiskInfo.index]
    for (let i = 0; i < RiskState.riskFormData['riskList'].length; i++) {
      const targetRisk = RiskState.riskFormData['riskList'][i]
      if (targetRisk.riskId !== mainRisk.riskId) {
        // 找到对应的附加险
        const riskIdValueList = RiskState.productInfo['riskList'].filter(risk => risk.riskId === targetRisk.riskId)

        for (let j = 0; j < targetRisk.calculateFactor.length; j++) {
          const targetFactor = targetRisk.calculateFactor[j]
          let factorValues = []
          if(riskIdValueList.length){
            factorValues = (riskIdValueList[0]['calculateFactor'].filter(item => item.factorType === targetFactor.factorType)[0] || {})['factorValueList'] || []
          }
          // const factorValues = (RiskState.productInfo['riskList'][i]['calculateFactor'][j]||{})['factorValueList'] || []
          if (factorValues.length > 0 && valObject.getDefault !== 1 && valObject !== 'riskStatusChange') {
            const defaultShow = factorValues.filter(f => f['defaultShow'] === 'Y')[0]
            if (defaultShow) {
              targetFactor['factorValue'] = defaultShow['value']
              targetFactor['factorValueName'] = defaultShow['name']
            } else {
              targetFactor['factorValue'] = factorValues[0]['value']
              targetFactor['factorValueName'] = factorValues[0]['name']
            }
          }
          if (targetFactor.factorInMain) {
            const mainFactor = mainRisk['calculateFactor'].filter(f => f['factorType'] === targetFactor['factorType'])[0]
            let mainFactorValue = mainFactor?.['factorValue']
            if (targetFactor?.['factorType'] === 'FeeYear' && targetRisk?.['exemptRisk'] === 'Y') {
              if (mainFactor) {
                if (isNumber(mainFactorValue)) {
                  mainFactorValue = Number(mainFactorValue) - 1
                } else {
                  mainFactorValue = (Number(mainFactorValue.substring(0, mainFactorValue.length - 1)) - 1) + 'Y'
                }
              }
            } else if (targetFactor['factorType'] === 'Period' && targetRisk['exemptRisk'] === 'Y') {
              const mainFactor1 = mainRisk['calculateFactor'].filter(f => f['factorType'] === 'FeeYear')[0]
              if (mainFactor1) {
                mainFactorValue = mainFactor1['factorValue']
              }
            }
            mainFactorValue = isNumber(mainFactorValue) ? Number(mainFactorValue) : mainFactorValue
            for (let x = 0; x < factorValues.length; x++) {
              let valStr = factorValues[x]['value'] + ''
              if (targetFactor['factorType'] === 'TextAge') {
                if ((mainFactorValue + '').indexOf('D') >= 0 && valStr.indexOf('D') >= 0) {
                  targetFactor['factorValue'] = factorValues[x]['value']
                  targetFactor['factorValueName'] = nameStr
                  break
                }
                valStr = valStr.replaceAll('Y', '')
              }
              if (valStr.indexOf('-') > 0) {
                let [min, max] = valStr.split('-')
                // min = isNumber(min) ? Number(min) : min
                min = isNumber(min) ? Number(min) : ((targetFactor['factorType'] === 'TextAge' && (min + '').indexOf('D') > 0) ? 0 : min)
                max = isNumber(max) ? Number(max) : max
                if(!isNumber(mainFactorValue) && mainFactorValue.indexOf('-') > 0){
                  if(valObject.textAgeValue){
                    if (min <= valObject.textAgeValue && valObject.textAgeValue <= max) {
                      targetFactor['factorValue'] = factorValues[x]['value']
                      targetFactor['factorValueName'] = nameStr
                      break
                    }
                  }else{
                    let [minMain, maxMain] = mainFactorValue.split('-')
                    minMain = isNumber(minMain) ? Number(minMain) : minMain
                    maxMain = isNumber(maxMain) ? Number(maxMain) : maxMain
                    if (min <= minMain && minMain <= max && maxMain <= max) {
                      targetFactor['factorValue'] = factorValues[x]['value']
                      targetFactor['factorValueName'] = nameStr
                      break
                    }
                  }

                }else{
                  if (min <= mainFactorValue && mainFactorValue <= max) {
                    targetFactor['factorValue'] = factorValues[x]['value']
                    targetFactor['factorValueName'] = nameStr
                    break
                  }
                }
              } else if (valStr === mainFactorValue + '') {
                targetFactor['factorValue'] = factorValues[x]['value']
                targetFactor['factorValueName'] = nameStr
                break
              }

            }
          }
        }
      }
    }
  }
}


// 对附加险恢复默认值
const oneCollationFactor = (valObject = {}) => {
  // 找到对应的附加险
  const riskIdValueList = RiskState.productInfo['riskList'].filter(risk => risk.riskId === valObject.riskId)[0]
  const riskFormData = RiskState.riskFormData['riskList'].filter(risk => risk.riskId === valObject.riskId)[0]
  for (let j = 0; j < riskIdValueList.calculateFactor.length; j++) {
    const targetFactor = riskIdValueList.calculateFactor[j]
    let data = riskFormData.calculateFactor.filter(item => item.factorType === targetFactor.factorType)[0]
    let factorValues = (riskIdValueList['calculateFactor'].filter(item => item.factorType === targetFactor.factorType)[0] || {})['factorValueList'] || []
    if (factorValues.length > 0) {
      const defaultShow = factorValues.filter(f => f['defaultShow'] === 'Y')[0]
      if (defaultShow) {
        data['factorValue'] = defaultShow['value']
        data['factorValueName'] = defaultShow['name']
      } else {
        data['factorValue'] = factorValues[0]['value']
        data['factorValueName'] = factorValues[0]['name']
      }
    }
  }
}
// 根据责任保额选择责任保额描述
const getDutyIntroduceFirstline = (duty) => {
  const riskArr = RiskState.productInfo['riskList'].filter(risk => risk.riskId === duty.riskId)
  if (riskArr.length === 1 && riskArr[0].dutyList.length > 0) {
    const dutyArr = riskArr[0].dutyList.filter(du => du.id === duty.id)
    if (dutyArr.length === 1 && dutyArr[0].countMode === '2' && dutyArr[0].riskDutyPremiumList.length > 0) {
      const coverageArr = dutyArr[0].riskDutyPremiumList.filter(prem => Number(prem.coverage) === Number(duty.premiumAmount))
      if (coverageArr.length === 1 && coverageArr[0].coverageValue) {
        return coverageArr[0].coverageValue
      }
    }
  }
  return undefined
}
// 设置投保要素关联的责任信息
const resetElementRelaDuty = (valueList, valObject, riskIdx) => {
  for (let k = 0; k < valueList.length; k++) {
    if (valueList[k]['value'] === valObject.value && valObject.hasRelaDuty) {
      const dutyArr = valueList[k]['dutyList'] || []
      const arr = RiskState.riskFormData.riskList[riskIdx]['dutyList'] || []
      RiskState.optionalDutys = [...RiskState.optionalDutyCopy]
      for (let d = 0; d < arr.length; d++) {
        arr[d]['isSelected'] = arr[d]['isOptional'] ? (RiskState.optionalDutys.includes(arr[d]['id']) ? arr[d]['isSelected'] : false) : true

        let list  = dutyArr.filter(item => item.riskDutyId === arr[d]['id'])
        if (list.length > 0){
          if (list[0]['isOptional'] === 'Y' && !RiskState.optionalDutys.includes(list[0]['riskDutyId'])) {
            RiskState.optionalDutys.push(list[0]['riskDutyId'])
          }
          arr[d]['isSelected'] = list[0]['isOptional'] === 'Y' ? arr[d]['isSelected'] : true
          arr[d]['disabled'] = list[0]['isOptional'] !== 'Y'

          if ( arr[d]['countMode'] === '2' ) {
            arr[d]['premiumAmount'] = list[0]['dutyAmount']
            arr[d]['introduceFirstline'] = getDutyIntroduceFirstline(arr[d]) || Number(list[0]['dutyAmount']).toLocaleString() + ' 元'
          }
        } else {
          arr[d]['isSelected'] = !arr[d]['isOptional']
          if ( arr[d]['countMode'] === '2' ) {
            arr[d]['introduceFirstline'] = getDutyIntroduceFirstline(arr[d])
          }
        }
      }
      return
    }
  }
}
// 处理责任信息
const collationDuty = (valObject = {}) => {
  console.debug('collationDuty', valObject, RiskState.productInfo)
  if(valObject.isInformation === 1) return
  for (let i = 0; i < RiskState.productInfo['riskList'].length; i++) {
    if (RiskState.productInfo['riskList'][i].riskId === valObject.riskId) {
      const factorList = RiskState.productInfo['riskList'][i]['calculateFactor'] || []
      for (let j = 0; j < factorList.length; j++) {
        if (factorList[j]['factorType'] === valObject.factorType) {
          const valueList = factorList[j]['factorValueList'] || []
          resetElementRelaDuty(valueList, {...valObject, hasRelaDuty: RiskState.riskFormData.riskList[RiskState.productInfo['riskList'][i]['index']]?.calculateFactor[j]['hasRelaDuty']}, i)
          return
        }
      }
      return
    }
  }
}
// 可选责任状态变化句柄
const optionalDutyChange = (dutyItem, callback, detail = {}) => {
  console.debug('optionalDutyChange==============', dutyItem)
  // 责任互斥
  if (dutyItem.repulsionKey && dutyItem.repulsionKey.length > 0) {
    const repulsionKeyArr = dutyItem.repulsionKey.split(',|，') || []
    for (let j = 0; j < repulsionKeyArr.length; j++) {
      const key = repulsionKeyArr[j]
      const repulsionArr = []
      const dutyList = RiskState.riskFormData.riskList[RiskState.firstMainRiskInfo.index]['dutyList'] || []
      for (let i = 0; i < dutyList.length; i++) {
        if (dutyList[i].repulsionKey === key && dutyList[i].isSelected && dutyList[i].id !== dutyItem.id) {
          repulsionArr.push(dutyList[i])
        }
      }
      if (repulsionArr.length) {
        // em....
        repulsionArr.push(dutyItem)
        Dialog.alert({
          message: repulsionArr.reduce((pre, cur) => pre.dutyName + ',\n' + cur.dutyName) + '\n只能选择一个。',
          theme: 'round-button',
          confirmButtonColor: '#0078fc'
        }).then(()=>{
          dutyItem.isSelected = false
        })
        return 'err'
      }
    }
  }
  if(JSON.stringify(detail) !== '{}'){
    detail.riskList.forEach((item, i) =>{
      RiskState.riskFormData.riskList[i].calculateFactor = item.calculateFactor
    })
  }
  const data = JSON.parse(JSON.stringify(RiskState.riskFormData))
  if (callback) {
    callback('premChange', 0)
  }
  if(RiskState.productInfo.detailPagePremiumCountFlag === 'N' && router.currentRoute.value.name === 'detail'){
    callback('premChange', 1)
    return
  }
  premCalculator(data, callback)
}

// 根据不同的险种进行不同的匹配
const insuranceElementMatching = (valObject = {}) => {
  // 1. 遍历险种
  for (let i = 0; i < RiskState.productInfo['riskList'].length; i++) {
    if (RiskState.productInfo['riskList'][i].riskId === valObject.riskId) {
      const factorList = RiskState.productInfo['riskList'][i]['calculateFactor'] || []
      // 保额 = 份数 * 基础保额
      let mult = 0, bc = 0, amnt = -1
      for (let j = 0; j < factorList.length; j++) {
        if (factorList[j]['factorType'] === 'Mult') {
          mult = RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValue']
        } else if (factorList[j]['factorType'] === 'BaseCoverage' && RiskState.riskFormData.riskList[i] && RiskState.riskFormData.riskList[i]['calculateFactor'][j]) {
          bc = RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValue']
        }
        if (mult > 0 && bc > 0) {
          amnt = mult * bc
        }
        if (amnt >= 0 && factorList[j]['factorType'] === 'Amnt') {
          RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValue'] = amnt + ''
          RiskState.riskFormData.riskList[i]['calculateFactor'][j]['factorValueName'] = amnt + ''
        }
      }
      return resetFactorValueRelation(((RiskState.riskFormData.riskList[i] || {})['riskId'] || ''), factorList, valObject)
    }
  }
  return true
}

const dwDate = (data) => {
  return getPremCalculatorParam(data,RiskState.productInfo)
}
// 保费/保额计算
const premCalculator = (data, callback, type='') => {
  for (let i = data.riskList.length - 1; i >= 0; i--) {
    if (!data.riskList[i].isSelected) {
      data.riskList.splice(i, 1)
    } else {
      let dutyArr = data.riskList[i]['dutyList']
      for (let j = dutyArr.length - 1; j >= 0; j--) {
        if (!dutyArr[j].isSelected) {
          data.riskList[i]['dutyList'].splice(j, 1)
        } else if (dutyArr[j].isRelatedPremiumCalculation) {
          data.riskList[i]['calculateFactor'].push({
            factorId: dutyArr[j].id,
            factorType: 'duty',
            factorValue: dutyArr[j].premiumAmount,
            isShow: true
          })
        }
      }

    }
  }
  //去重
  for (let i = 0; i < data.riskList.length; i++) {
    let map = new Map()
    for (let cal of data.riskList[i].calculateFactor) {
      if (!map.has(cal['factorId'])) {
        map.set(cal['factorId'], cal)
      }
    }
    data.riskList[i].calculateFactor = [...map.values()]
  }
  data = dwDate(data)
  store.dispatch('insured/changeInsureJSInfo', {
    riskList: JSON.stringify(data)
  })
  if(RiskState.productInfo.detailPagePremiumCountFlag === 'N' && router.currentRoute.value.name === 'detail'){
    callback('premChange', 1)
    return
  }
  RiskState.requestTime++
  let count = RiskState.requestTime
  console.debug('premCalculator-------RiskState.requestTime', count)
  let result = ''
  request(getExtendUri('calPremium'), {
    productInfo: data
  }).then(res => {

      // result = res
      // resetPrem(res, data, callback, count, type)
    resultList.value.push({
      count,
      result: res,
      data,
      callback,
      type
    })
  }).catch(err => {
    if (callback) {
      callback('premChange', '', '', result )
    }
    console.error('premCalculator error:', err)
  })
}
let resultList = ref([])

watch(resultList, () =>{
  if(resultList.value.length > 0){
    let { maxIndex } = resultList.value.reduce((acc, obj, index) => {
      if (obj.count > acc.maxValue) {
        return { maxValue: obj.count, maxIndex: index }
      }
      return acc
    }, { maxValue: -Infinity, maxIndex: -1 })
    let {count, result, data, callback, type} = resultList.value[maxIndex]
    resetPrem(result, data, callback, count, type)
  }
}, {immediate: true, deep: true})

const resetPrem = (res, data, callback, time, type = '') => {
  if (res.code === 1 && res.result) {
    let totalPremium = 0
    // 主险
    res.result.riskPremiumList.forEach(hasItem => {
      if (hasItem.riskId === RiskState.firstMainRiskInfo.riskId) {
        if (RiskState.firstMainRiskInfo.premiumCalculation === '1') {
          totalPremium = totalPremium + parseFloat(hasItem.premium)
        } else if (RiskState.firstMainRiskInfo.premiumCalculation === '2') {
          const eleAmnt = RiskState.riskFormData.riskList[RiskState.firstMainRiskInfo.index]['calculateFactor'].filter(n => n.factorType === 'Amnt')
          if (eleAmnt.length > 0) {
            eleAmnt[0].factorValue = parseFloat(hasItem.premium)
            eleAmnt[0].factorValueName = hasItem.premium + '元'
          }
          const elesPrem = RiskState.riskFormData.riskList[RiskState.firstMainRiskInfo.index]['calculateFactor'].filter(n => n.factorType === 'Prem')
          if (elesPrem.length > 0) {
            totalPremium = totalPremium + parseFloat(elesPrem[0].factorValue)
          }
        }
      }
    })
    // 增加特殊判断
    res.result.riskPremiumList = newPremiumList(res.result.riskPremiumList, data)
    // 附加险
    RiskState.firstMainRiskInfo.additionalRiskList.forEach(item => {
      console.log(item, 'item6666666')
      res.result.riskPremiumList.forEach(hasItem => {
        console.log(hasItem, '678888')
        if (hasItem.riskId === item.riskId) {
          if (item.premiumCalculation === '1') {
            item.premium = parseFloat(hasItem.premium)
            console.debug('hahahhaa------', hasItem)
            if (hasItem.newPremium) {
              item.newPremium = parseFloat(hasItem.newPremium)
              totalPremium = totalPremium + parseFloat(hasItem.newPremium)
            } else {
              totalPremium = totalPremium + parseFloat(hasItem.premium)
            }
          } else if (item.premiumCalculation === '2') {
            const eleAmnt = item['calculateFactor'].filter(n => n.factorType === 'Amnt')
            if (eleAmnt.length > 0) {
              eleAmnt[0].factorValue = parseFloat(hasItem.premium)
              eleAmnt[0].factorValueName = hasItem.premium + '元'
            }
            const elesPrem = item['calculateFactor'].filter(n => n.factorType === 'Prem')
            if (elesPrem.length > 0) {
              totalPremium = totalPremium + parseFloat(elesPrem[0].factorValue)
            }
          }
        }
      })
    })


    if (callback && time >= RiskState.requestTime && !type) {
      callback('premChange', 1, formatNumberToFix2(totalPremium), res.result, data)
    }else if(type){
      callback('premChange', 1, formatNumberToFix2(totalPremium), res.result, data)
    }
  } else {
    if (store.getters['insured/getInitPlanEvent'] === true) {
      message['error'](res.message)
    }
    if (callback) {
      callback('premChange', '', '',res)
    }
  }
}

const factorChange = (valObject, supRiskCode, callback, type = '') => {
  if (valObject === 'riskStatusChange') {
    // 附加险 互斥
    const repulsionRiskArr = RiskState.riskFormData.riskList.filter(risk => {
      return (risk['riskSign'] === '2' || risk['riskSign'] === '3') && risk['repulsionKey'] && risk['repulsionKey'].length > 0
    })
    const tempKeyArr = []
    for (let i = 0; i < repulsionRiskArr.length; i++) {
      tempKeyArr.push(...(repulsionRiskArr[i].repulsionKey.split(',|，') || []))
    }
    const repulsionKeyArr = Array.from(new Set(tempKeyArr))
    for (let j = 0; j < repulsionKeyArr.length; j++) {
      const key = repulsionKeyArr[j]
      const repulsionArr = []
      for (let i = 0; i < repulsionRiskArr.length; i++) {
        if (repulsionRiskArr[i].repulsionKey === key && repulsionRiskArr[i].isSelected) {
          repulsionArr.push(repulsionRiskArr[i])
        }
      }
      if (repulsionArr.length >= 2) {
        Dialog.alert({
          message: repulsionArr.reduce((pre, cur) => pre.riskName + ',\n' + cur.riskName) + '\n只能选择一个。',
          theme: 'round-button',
          confirmButtonColor: '#0078fc'
        }).then(() => {
          if (callback) {
            callback('nothing', -1)
          }
        })
        return
      }
    }

    const obj = window.calibrationMethod_supRiskCode ? window.calibrationMethod_supRiskCode(JSON.parse(JSON.stringify(RiskState.riskFormData)), supRiskCode) : {lock: true, message: ''}
    if (!obj.lock) {
      RiskState.riskFormData.riskList = obj.riskList
      message['error'](obj.message)
      return false
    }
  }
  // 临时解决：如果首次进来且没有值，那就手动附一个
  if (RiskState.init_flag === -1 && !valObject) {
    if (RiskState.riskFormData.riskList && RiskState.riskFormData.riskList.length > 0) {
      const tId = RiskState.riskFormData.riskList[0].riskId
      const tempObj = RiskState.riskFormData.riskList[0]['calculateFactor'][0]
      valObject = {
        riskId: tId,
        factorType: tempObj.factorType,
        value: tempObj.factorValue,
        name: tempObj.factorValueName,
        elementType: 'select',
        insuredIndex: 0
      }
    }
  }
  RiskState.init_flag = 0
  collationFactor(valObject)
  collationDuty(valObject)
  let bool = specialRiskJudge(RiskState.riskFormData, 1)
  console.debug('bool', bool)
  const needCalculatePrem = insuranceElementMatching(valObject)
  console.debug('needCalculatePrem', needCalculatePrem, valObject)
  // let bool = specialRiskJudge(RiskState.riskFormData, 1)
  // console.debug('bool', bool)
  if (needCalculatePrem || bool) {
    const data = JSON.parse(JSON.stringify(RiskState.riskFormData))
    data.insuredIndex = valObject.insuredIndex
    if (callback) {
      callback('premChange', 0)
    }

    premCalculator(data, callback, type)
  }
}
const  factorChangeInputInfo = ( index, type = '',callback) =>{
  const data = JSON.parse(JSON.stringify(RiskState.riskFormData))
  data.insuredIndex = index
  premCalculator(data, callback, type)
}

/**
 * 给录入页信息变化后的RiskState.riskFormData对象赋值修改
 * @param valObject
 */
const collationInputInfoFactor = (valObject = {}) => {
  // console.debug('collationFactor', valObject, RiskState.firstMainRiskInfo)
  console.debug('collationInputInfoFactor---riskFormData', valObject, RiskState.riskFormData)
  if (valObject === 'riskStatusChange' || valObject.riskId === RiskState.firstMainRiskInfo.riskId) {
    const mainRisk = RiskState.riskFormData['riskList'][RiskState.firstMainRiskInfo.index]
    for (let i = 0; i < RiskState.riskFormData['riskList'].length; i++) {
      const targetRisk = RiskState.riskFormData['riskList'][i]
      if (targetRisk.riskId === mainRisk.riskId) {
        let obj = findPropValue(targetRisk, 'factorType', valObject.factorType)
        if (JSON.stringify(obj) !== '{}' && obj !== undefined) {
          obj.factorValue = valObject.value
          obj.factorValueName = valObject.name
        }
        collationFactor(valObject)
        collationDuty(valObject)
      }
    }
  }
}


const PremCalculator = {
  createRiskInfo: createRiskInfo,
  factorChange: factorChange,
  optionalDutyChange: optionalDutyChange,
  initDutyPremAmount: initDutyPremAmount,
  collationInputInfoFactor:collationInputInfoFactor,
  factorChangeInputInfo: factorChangeInputInfo,
  oneCollationFactor:oneCollationFactor
}

export default PremCalculator
