import dayjs from 'dayjs'

const datetimeFormat = 'YYYY-MM-DD HH:mm:ss'
const dateFormat = 'YYYY-MM-DD'
export const now = function() {
  return dayjs().format(datetimeFormat)
}
export const today = function() {
  return dayjs().format(dateFormat)
}
