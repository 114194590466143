/**
 * 常用Ajax请求方法
 * version 2.0.0
 */
import Axios from 'axios'
import store from '../store'
import router from '../router'
import {Modal} from 'ant-design-vue'
import {usePagination} from 'vue-request'
import {getExtendUri, isSureSaveDateHaveShareId,} from './request_extend'


/**
 * 参考restful
 * @param url 请求地址 e.g. /product/:id/duty/:id
 * @param params 请求参数 json format e.g. {id:'0001', name:'XX百万医疗险'}
 * @param isLoading 是否显示请求过渡动画 默认 false
 * @param method 默认 post
 * @param mockData 模拟数据
 * @returns {Promise<any>}
 */
export const request = function (url, params = {}, isLoading, method, mockData) {
  // 比较懒，不想看有哪里调用了saveData````` 直接拦截处理吧
  if (url === getExtendUri('saveData')) {
    params = isSureSaveDateHaveShareId(params)
  }
  if (process.env['VUE_APP_MOCK'] === 'ON' && mockData && mockData.mock === true) {
    return Promise.resolve(mockData)
  }
  if (url == null || url == undefined || url === '') {
    return Promise.resolve({
      code: 0,
      message: '请求地址为空'
    })
  }
  switch (method) {
    case 'get':
      // 创建业务对象数据
      return API.get(url, params)
    default:
      // 默认请求类型
      // 列出业务对象数据
      return API.post(url, params, params.config)
  }
}

export const requestUsePagination = function (url, data, mockData) {
  const queryUtil = usePagination((params) => {
    return request(url, params, true, 'post', mockData)
  }, data)
  const queryRun = queryUtil['run']
  queryUtil['run'] = condition => queryRun.call(this,{...condition, ...condition.pagination})
  return queryUtil
}
export const getRequestURI = function (requestCode) {
  return store.getters['user/getActionMap'](requestCode)
}
/**
 * 请求间隔计时器，超时重新登录
 */
const gTimer = {
  sessionTimerId: -1,
  // 3 hour
  sessionTime: 3 * 3600 * 1000,
  sessionCallback: function() {
    store.commit('user/logout')
    Modal.warning({
      title: '需要登录认证',
      content: '有一会儿没来看我了，重新认证一下吧～',
      okText: '朕知道了',
      onOk: () => router.push({ name: 'Auth' })
    })
  },
  refresh: function () {
    if (this.sessionTimerId !== -1) {
      clearTimeout(this.sessionTimerId)
    }
    this.sessionTimerId = setTimeout(this.sessionCallback, this.sessionTime)
  }
}


const API = Axios.create({
  baseURL: process.env.VUE_APP_URL_PATH + '/backend',
  headers: {
    'Content-Type': 'application/json',
    'agentId': '',
    'beeId': '',
    'userId': '',
    'corpId': '',
    'loginId': ''
  }
})

API.interceptors.request.use(function (config) {
  config.headers.beeId = store.getters['user/getBeeId'] || ''
  config.headers.userId = store.getters['user/getUserId'] || ''
  config.headers.loginId = store.getters['insured/getUserId'] || store.getters['user/getLoginId']  || ''
  config.headers.corpId = store.getters['user/getCorpId'] || ''
  config.headers.agentId = ''

  gTimer.refresh()
  return config
}, function (error) {
  return Promise.reject(error)
})
const modal = {
  val: null
}
API.interceptors.response.use(function (response) {
  return Promise.resolve(response.data)
}, function (error = {}) {
  console.error('response error', error.response)
  if (error.response.status === 401) {
    if (modal.val === null) {
      modal.val = Modal.warning({
        title: '需要登录认证',
        content: '有一会儿没来看我了，重新认证一下吧～',
        okText: '朕知道了',
        onOk: () => {modal.val.destroy();modal.val=null;router.push({ name: 'Auth' })}
      })
    }
  }
  return Promise.reject(error.response)
})
