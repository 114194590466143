export function loadRuleScriptResourceList (scriptList = []) {
  return new Promise(exec => {
    scriptList.reduce((res, el) => res.then(() => loadScript(el)), Promise.resolve()).then(exec).catch(error => {
      console.error('loadRuleScriptResourceList error :', error)
      return Promise.reject(error)
    })
  })
}

function loadScript (url = '') {
  return new Promise((resolve, reject) => {
    if (url === undefined || url == null || url === '') return
    const filename = url.substring(url.lastIndexOf('/') + 1)
    window.product_rule_array = window.product_rule_array || []
    if (!window.product_rule_array.includes(filename)) {
      window.product_rule_array.push(filename)
      const script = document.createElement('script')
      script.onload = resolve
      script.onerror = reject
      script.src = url
      document.body.appendChild(script)
    } else {
      resolve()
    }
  })
}
