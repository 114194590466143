import router from '../router'
import {request} from './request'
// import store from '../store'
import {getExtendUri} from './request_extend'
import store from '../store'
// import {message} from 'ant-design-vue'

export const showArr = {
  'healthNotification': {
    'name': '健康告知',
    'value': '/healthTold'
  },
  'informationEntry': {
    'name': '信息填写',
    'value': '/informationInput'
  },
  'calculationPremium': {
    'name': '保费试算',
    'value': '/detail'
  },
  'payment': {
    'name': '支付',
    'value': '/payStatus'
  },
  'receipt': {
    'name': '回执',
    'value': '/returnTheQuestionnaire'
  },
  'termsConditions': {
    'name': '条款须知',
    'value': '/provisionShould'
  },
  'acknowledgementOrder': {
    'name': '信息确认页',
    'value': '/confirmation'
  },
  'disclaimer': {
    'name': '声明告知',
    'value': '/declarationToInform'
  }
}
// export const NewrouterJump = () => {
//
// }
export const routerJump = (uuid, str = '', hasFace = '') => {
  let routerArr = ''
  console.log(store.getters['insured/getHealthRouterJump'], '9999999997777')
  str === 'health' ? routerArr = JSON.parse(store.getters['insured/getHealthRouterJump']) : routerArr = JSON.parse(store.getters['insured/getRouterJump'])
  if (!routerArr) {
    return false
  }
  router.push({
    path: '/app' + showArr[routerArr.nextCode].value,
    query: {
      id: routerArr.processId,
      sort: Number(routerArr.sort) + 1,
      tradeNo: uuid ? uuid : store.getters['insured/getTradeNo'],
      shareId: store.getters['insured/getShareId'],
      typeCode: router.currentRoute.value.query.typeCode ? router.currentRoute.value.query.typeCode : '',
      renewalId: router.currentRoute.value.query.renewalId ? router.currentRoute.value.query.renewalId : '',
      continuousRenew: router.currentRoute.value.query.continuousRenew ? router.currentRoute.value.query.continuousRenew : '',
      hasFace,
    }
  })
  // if(localStorage.getItem('provisionShould')==='Y'){
  //   //已阅读完条款特殊处理
  //   nowPath = '/provisionShould'
  // }

  // let nowCode,nextCode
  // for(var key in showArr){
  //   if(nowPath === showArr[key].value){
  //     nowCode = key
  //   }
  // }
  // routerArr.forEach((res,index) =>{
  //   if(res.code === nowCode){
  //     nextCode = routerArr[index+1]?routerArr[index+1].code:''
  //     localStorage.setItem('nowIndex',index+1)
  //   }
  // })
  // if(!showArr[nextCode]){
  // //  非内部开发页面进行需进行跳转处理
  // }else{
  //   router.push({
  //     path:showArr[nextCode].value,
  //     params:{
  //       id:'xwl',
  //       sort:''
  //     }
  //   })
  // }
  // console.log(showArr[nextCode].value)
  // return showArr[nextCode].value
}
export const nowPathShow = (id, num, uuid, func) => {
  let ids, sort
  if (id) {
    ids = id
    sort = num
  } else {
    // let URL = location.search // 项目中可直接通过search方法获取url中"?"符后的字串
    // let url = URL.split('?')[1]
    // let obj = {} // 声明参数对象
    // let arr = url.split('&') // 以&符号分割为数组
    // for (let i = 0; i < arr.length; i++) {
    //   let arrNew = arr[i].split('=') // 以"="分割为数组
    //   obj[arrNew[0]] = arrNew[1]
    // }
    console.debug('location.search', location.search)
    let temp = new URLSearchParams(location.search)
    ids = temp.get('id')
    sort = temp.get('sort')
  }
  console.debug('id', id, 'sort', sort)
  request(getExtendUri('processNodeDetails'), {
    id: ids,
    sort: sort,
    tradeNo: uuid
  }).then(res => {
    if (res.code === 1 && res.result) {
      setTimeout(() => {
        store.dispatch('insured/changeInsureJSInfo',{
          'currentProcessNode': res.result
        })
      }, 200)
      // healthRouterJump
      // sessionStorage.setItem(`routerJump${uuid}`, JSON.stringify(res.result))
      if (res.result.code === 'healthNotification') {
        delete res.result.nodeInterfaceList
        store.dispatch('insured/changeHealthJSInfo', {
          'healthRouterJump': JSON.stringify(res.result)
        })
        if (func) {
          func.call()
        }
      } else {
        store.dispatch('insured/changeInsureJSInfo', {
          'routerJump': JSON.stringify(res.result)
        })
        let isTodoSaveDate =  findPropValue((res.result), 'isUnderwriting')
        store.dispatch('insured/changeInsureJSInfo', {
          isUnderwriting: isTodoSaveDate
        })
        if (func) {
          func.call()
        }
      }

    } else if (res.code === 0) {
      console.log('error', res.message)
      // message['error'](res.message)
    } else {
      console.log('error', '暂未配置相关流程')
      // message['error']('暂未配置相关流程')
    }
  })


  // let nowPath = window.location.pathname
  // let routerArr =localStorage.getItem('routerJump')? JSON.parse(localStorage.getItem('routerJump')):[]
  // let nowCode
  // for(var key in showArr){
  //   if(nowPath === showArr[key].value){
  //     nowCode = key
  //   }
  // }
  // if(routerArr.length>0){
  //   routerArr.forEach((res,index) =>{
  //     if(res.code === nowCode){
  //       localStorage.setItem('nowIndex',index)
  //     }
  //   })
  // }else{
  //   localStorage.setItem('nowIndex',0)
  // }
}

// 获取产品详情存入缓存
export const gePInfo = async () => {
  // console.log(store.getters['insured/getProductDetailData'])
  if (!store.getters['insured/getProductDetailData'] || store.getters['insured/getProductDetailData'] == '{}') {
    let temp = new URLSearchParams(location.search)
    const bool = await request(getExtendUri('getLinkData'), {
      data: {
        id: temp.get('shareId')
      }
    }).then(res => {
      if (res.code === 1 && res.result) {
        console.log('productDetailData:', res.result)
        // 将详情信息存入
        store.dispatch('insured/changeInsureJSInfo', {productDetailData: JSON.stringify(res.result)})
        store.dispatch('insured/changeInsureJSInfo', {productId: res.result.productInfo.productId})
      } else {
        console.log('error', res.message)
      }
      return true
    }).catch(err => {
      console.error(err)
      return false
    })
    return bool
  } else {
    return true
  }

}
const findPropValue = (obj, targetProp = 'maximumLimit') => {
  for (const prop in obj) {
    if (prop === targetProp) {
      return obj[prop]
    } else if (typeof obj[prop] === 'object' || Array.isArray(obj[prop])) {
      const result = findPropValue(obj[prop], targetProp)
      if (result !== undefined) {
        return result
      }
    }
  }
}
// 获取缓存接口的数据存入缓存
export const getCacheDate = async (num, orderList = null) => {
  // 因为默认是空{},所以用该方法判断是否具有某个属性
  if (!Object.keys(JSON.parse(store.getters['insured/getRiskList'])).includes('riskList') || orderList === '1' ) {
    if (!num) {
      let temp = new URLSearchParams(location.search)
      num = temp.get('tradeNo')
    }
    // 根据接口获取riskLisk数据存入缓存
    const bool = await request(getExtendUri('getCacheParameters'), {
      tradeNo: num
    }).then(res => {
      console.log('getCacheParameters:', res.result)
      store.dispatch('insured/changeInsureJSInfo', {riskList: JSON.stringify(res.result.selectRisk)})
      store.dispatch('insured/changeInsureJSInfo', {submitDate: JSON.stringify(res.result.saveData)})
      let temp = findPropValue(res.result.saveData)
      if (temp && temp > 1) {
        store.dispatch('insured/changeInsureJSInfo', {multipleRiskList: JSON.stringify(res.result.saveData)})
      }
      return true
    })
    return bool
  } else {
    return true
  }
}
// 跳转确认页所需信息
let rjBean = {
  pId: '',
  id: '',
  shareId: '',
  tradeNo: '',
  sort: '',
  path: ''
}
/**
 * 判断是否有未完成的订单
 * @param shareId
 */
export const getNotDonePolicy = async (shareId) => {
  if (shareId) {
    const obj = await request(getExtendUri('getTradeNoInDoing'), {
      data: {
        shareId: shareId
      }
    }).then(res => {
      if (res.code === 1) {
        rjBean.tradeNo = res.result
        return res.result
      }
    }).catch(err => {
      console.error(err)
      return null
    })
    return obj
  }
  return null
}


// 获取流程id
export const getPId = async (sId) => {
  if (store.getters['insured/getProductId'] && store.getters['insured/getProductId'] !== '') {
    return store.getters['insured/getProductId']
  } else {
    const pId = await request(getExtendUri('getLinkData'), {
      data: {
        id: sId
      }
    }).then(res => {
      if (res.code === 1 && res.result) {
        let productDetailInfo = res.result
        const productId = productDetailInfo.productInfo.productId
        return productId
      } else {
        // message['error'](res.message)
        console.error(res.message)
      }
    })
    return pId
  }
}

export const getNodeList = async (pId, nodeName = 'acknowledgementOrder', shareId='') => {
  const nodeItem = await request(getExtendUri('getProcessByProductId'), {
    data: {
      productId: pId,
      shareId
    }
  }).then(res => {
    // 先将获取到的node节点存入缓存
    let nodeDetailData = res.result
    // 遍历获取到的node节点
    nodeDetailData.forEach(nodeItem => {
      if (nodeItem.relationProcessId) {
        if (nodeItem.relationProcessId.indexOf(nodeName) != -1) {
          console.log('sort:', nodeItem.sort)
          rjBean.id = nodeItem.processId
          rjBean.sort = Number(nodeItem.sort)
          rjBean.path = '/app' + showArr[nodeItem.relationProcessId].value
          return rjBean
          // router.push({
          //   path: '/app/confirmation',
          //   query: {
          //     tradeNo: item.tradeNo,
          //     shareId: item.shareId,
          //     sort:nodeItem.sort,
          //     id: nodeItem.processId
          //   }
          // })
        }
      }
      // }
    })
  })
  return nodeItem
}

export const getRouterJumpBean = async (shareId, tradeNo , nextCode) => {
  rjBean = {}
  rjBean.shareId = shareId
  if (tradeNo) {
    rjBean.tradeNo = tradeNo
    console.info('goJump --- 1')
  } else {
    rjBean.tradeNo = await getNotDonePolicy(shareId)
    console.info('goJump --- 2')
  }
  if (rjBean.tradeNo) {
    console.info('goJump --- 3')
    const pId = await getPId(shareId)
    console.info('goJump --- 4')
    rjBean.pId = pId
    console.info('goJump --- 5')
    await getNodeList(pId , nextCode, shareId)
    console.info('goJump --- 6', rjBean)
    if (rjBean.shareId && rjBean.tradeNo && rjBean.sort && rjBean.id) {
      console.info('goJump --- 7')
      return rjBean
    }
    console.info('goJump --- 8')
  }
  console.info('goJump --- 9')
  return null
}

// const getResult = () {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       const data = 'Data from request one';
//       resolve(data);
//     }, 1000);
//   });
// }
export const getInformationEntry = (id, num, uuid, func) => {
  let ids, sort
  if (id) {
    ids = id
    sort = num
  } else {
    let temp = new URLSearchParams(location.search)
    ids = temp.get('id')
    sort = temp.get('sort')
  }
  request(getExtendUri('processNodeDetails'), {
    id: ids,
    sort: sort,
    tradeNo: uuid
  }).then(res => {
    if (res.code === 1 && res.result) {
      store.dispatch('insured/changeInsureJSInfo', {
        'routerJump': JSON.stringify(res.result)
      })
        if(func) {
          func.call()
        }
    } else if (res.code === 0) {
      console.debug('error', res.message)
    } else {
      console.debug('error', '暂未配置相关流程')
    }
  })
}
