/**
 * --------------------------------------------------------------------------------------------------------
 *                                              下载文件的工具类                                             *
 * --------------------------------------------------------------------------------------------------------
 */
import axios from 'axios'

/**
 * 下载文件--通过a标签下载
 * @param url 下载路径
 * @param fileName 下载文件名
 */
const downloadFileByUrl = (url, fileName) => {
  if (!fileName) {
    fileName = getFileNameFromUrl(url)
  }
  axios({
    url: url,
    method: 'GET',
    // 将响应类型设置为 blob
    responseType: 'blob'
  })
    .then((response) => {
      const contentType = response.headers['content-type']
      // 创建一个 a 标签
      const link = document.createElement('a')
      link.href = URL.createObjectURL(new Blob([response.data]))
      // 设置下载文件的文件名
      link.download = fileName + '.' + contentType.split('/')[1]
      // 将 a 标签添加到 body 中
      document.body.appendChild(link)
      link.click()
      // 将 a 标签从 body 中移除
      document.body.removeChild(link)
    })
    .catch((error) => {
      console.log(error)
    })
}

/**
 * 从url获取文件名，用于传递参数没有文件名或者文件名是空字符串的情况的处理
 * @param url
 * @returns {string}
 */
const getFileNameFromUrl = (url) => {
  // 获取数组中的最后一个元素
  const fileName = url.split('/').pop()
  // 去掉文件扩展名
  const nameWithoutExt = fileName.substring(0, fileName.lastIndexOf('.'))
  return nameWithoutExt
}

export {
  downloadFileByUrl
}
