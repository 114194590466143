/**
 * 流水号配置规则：
 * HB（可以在后台配置）+年+随机码（6位）+流水（10位）
 * 1、随机流水6位是英文小写
 * 2、流水（10位）  这个每年从00000001重新开始
 * 3、HB可配，上限最长10位
 */

import {request} from './request'
import {getExtendUri} from './request_extend'

/**
 * 创建流水号
 */
export const createFWNum = async () => await splicingStr()

/**
 * 后台请求组获取流水号开头标识
 */
const getTopFlag = async () => {
  const tyName = 'FWNUM'
  let con = await request(getExtendUri('dictionary_query_byType'),{data: {ids:[],types:[tyName]}}).then(res => {
    console.log('dictionary',res.result)
   if (res.code === 1 && res.result[tyName]) {
     return  res.result[tyName][0].label
   }
   return 'HB'
  }).catch(err => {
    console.error(err)
    return 'HB'
  })
  return con
}

/**
 * 接口获取最后10个流水号
 * @returns {Promise<*>}
 */
const getLastTenNum = async () => {
  let con = await request(getExtendUri('getTenNum')).then(res => {
    // 约定 -1 为 11位，此时去执行随机数
    if (res.code === 1 && res.result !== -1) {
        return  res.result
    }
    return randomTenNumBit()
  }).catch(err => {
    console.error(err)
    return randomTenNumBit()
  })
  return con
}

/**
 * 拼接字符串
 * @returns {Promise<string>}
 */
const splicingStr = async () => {
  let topStr = await getTopFlag()
  let lastStr = await getLastTenNum()
  let year = new Date().getFullYear()
  let rCode = randomSixBit()
  return topStr + year + rCode + lastStr
}
const letterChars = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n',
  'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z' ]
const numChars = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0' ]

/**
 * 随机六位小写字母
 * @returns {string}
 */
const randomSixBit = () => randomNumBit(letterChars, 6)

/**
 * 随机10个数字
 * @returns {string}
 */
export const randomTenNumBit = () => randomNumBit(numChars, 10)

/**
 * 随机
 * @param chars
 * @param limit
 * @returns {string}
 */
const randomNumBit = (chars, limit) => {
  let randomCode = ''
  for (let i = 0; i < limit; i++) {
    let num = Math.floor(Math.random()*(chars.length-1))
    randomCode += chars[num]
  }
  return randomCode
}
