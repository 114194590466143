import {vTools} from '../pages/InsuranceConfiguration/js/verificationTool'
import {getBBRAge, getNewDateParam, getTBRAge, showDataMin} from './utlis'
import store from '../store'

// 通用规则校验
export const checkData = (data, isInclude = null) => {
  console.log(data, '9999999999999999999')
  // 订单号
  let tradeNo = data.baseInfo.tradeNo
  // 代理人s
  let agentIds = data.baseInfo.agentIds
  // 校验投保人
  let applicant = data.contInfo.applicant
  // 校验被保人s
  let insureds = data.contInfo.insureds
  // 受益人
  let beneficiarys = data.contInfo.beneficiarys
  // 所选险种
  let risks = data.contInfo.risks
  // ?
  let customer = data.contInfo.customer
  // 支付信息
  let payment = data.contInfo.payment
  console.log('订单号', tradeNo)
  console.log('代理人', agentIds)
  console.log('受益人', beneficiarys)
  console.log('所选险种', risks)
  console.log('？？？', customer)
  let res = checkApplicantInfo(applicant)
  let val = checkPaymentInfo(payment)
  // let itm = checkBeneficiarys(beneficiarys)
  const marriageType = checkMarriageType(applicant, insureds)
  if (res.bool === false) {
    return res
  }
  if (val.bool === false) {
    return val
  }
  // if (itm.bool === false) {
  //   return itm
  // }
  if (marriageType.bool === false) {
    return marriageType
  }
  if (!beneficiarys[0].bnfType && isInclude) {
    // return {bool: false, msg: '请选择受益人类型！'}
    console.log(isInclude)
  }
  for (let i = 0; i < insureds.length; i++) {
    let result = checkbaseInfo(insureds[i], applicant)
    if (result.bool === false) {
      return result
    }
    let result1 = checkRelationship(insureds[i], applicant)
    if (result1.bool === false) {
      return result1
    }
    // 判断window是否挂载该方法
    // js约定的固定方法，用于传入各计划限制条件
    if (window.getPlanLimitList) {
      // eslint-disable-next-line no-undef
      if (getPlanLimitList()) {
        let plan = getPlan(risks)
        // eslint-disable-next-line no-undef
        let result2 = checkPlanLimitList(window.getPlanLimitList(), insureds[i], plan)
        if (result2.bool === false) {
          return result2
        }
      }
    } else {
      let result2 = checkPlanLimitList(null, insureds[i], {})
      if (result2.bool === false) {
        return result2
      }
    }
    /**
     * 校验职业
     */
    if (isNeedcheckJob().isNeed) {
    let resultJob =  checkInsuredJobLevel(isNeedcheckJob().value, insureds[i])
      if (resultJob.bool === false) {
        return resultJob
      }
    }
  }

}
/**
 *  判断投被保人的婚姻状态
 * @param applicant
 * @param insureds
 */
export const checkMarriageType = (applicant, insureds) =>{
  let flag = {bool: true,msg: ''}
  if(applicant.marriage !== undefined && applicant.marriage !== null && applicant.marriage !== ''){
    insureds.forEach(item =>{
      if(item.relationToAppnt === '02' && item.marriage && applicant.marriage !== '1') flag = {bool: false,msg: '婚姻状况为未婚、离异、丧偶时，投被保险人关系不能为配偶'}
    })
    insureds.forEach(item =>{
      if(item.relationToAppnt === '02' && item.marriage && item.marriage !== '1') flag = {bool: false,msg: '投被保人关系为配偶时，被保人婚姻状态不能为“未婚、离异、丧偶”'}
    })
  }
  return flag
}


const getPlan = (risks) => {
  for (let i = 0; i < risks[0]['calculateFactor'].length; i++) {
    let item = risks[0]['calculateFactor'][i]
    if (item.factorType === 'Plan') {
      return {
        planCode: item.factorValue,
        planName: item.factorValueName
      }
    }
  }
  return null
}
/**
 * 身份证
 * @returns {{msg: string, bool: boolean}}
 */
const checkIdCard01 = (type, numStr) => {
  let res = vTools('pId', numStr) === true
  return {bool: res, msg: type + '证件号码输入不正确！'}
}
/**
 * 护照
 * @returns {{msg: string, bool: boolean}}
 */
const checkIdCard02 = (type, numStr) => {
  let res = (numStr+'').length > 0
  return {bool: res, msg: type + '证件号码输入不正确！'}
}
/**
 * 港澳通行证
 * @returns {{msg: string, bool: boolean}}
 */
const checkIdCard08 = (type, numStr) => {
  let res = (numStr+'').length > 0
  return {bool: res, msg: type + '证件号码输入不正确！'}
}
/**
 * 台湾通行证
 * @returns {{msg: string, bool: boolean}}
 */
const checkIdCard09 = (type, numStr) => {
  let res = (numStr+'').length > 0
  return {bool: res, msg: type + '证件号码输入不正确！'}
}
/**
 * 社会信用
 * @returns {{msg: string, bool: boolean}}
 */
const checkIdCard23 = (type, numStr) => {
  let res = (numStr+'').length > 0
  return {bool: res, msg: type + '证件号码输入不正确！'}
}
const checkPaymentInfo = (payment) => {
  if (payment.phoneNo){
    let cMobile = checkMobile('payment', payment.phoneNo)
    if (cMobile !== true) {
      return cMobile
    }
  }
  if (payment.newBankAccNo){
    let CNewBankAccNo = checkBankNo('payment', payment.newBankAccNo)
    if (CNewBankAccNo !== true) {
      return CNewBankAccNo
    }
  }
  return {bool: true, msg: ''}
}
// const checkBeneficiarys = (beneficiarys) => {
//   if (beneficiarys.phoneNo){
//     let cMobile = checkMobile('payment', beneficiarys.phoneNo)
//     if (cMobile !== true) {
//       return cMobile
//     }
//   }
//   return {bool: true, msg: ''}
// }
/**
 * 检查投保人
 * @param applicant
 * @returns {{bool: boolean, message: string}|{bool: boolean, message: (Boolean|string)}|boolean|{msg: string, bool: boolean}}
 */
const checkApplicantInfo = (applicant) => {
  if (!applicant.appntName) {
    return {bool: false, msg: '请输入投保人姓名'}
  }
  if (checkName(applicant.appntName, applicant.appntIdType).bool !== true) {
    return checkName(applicant.appntName, applicant.appntIdType)
  }
  if (applicant.idcartType && applicant.idcartType === '23') {
    return {bool: true, msg: ''}
  }
  if (applicant.appntBirthday) {
    if ((getTBRAge(applicant.appntBirthday.split('-'), store.getters['insured/getAGEDIFF'])).age < 18) {
      return {
        bool: false,
        msg: '投保人年龄不能小于18周岁'
      }
    }
  } else {
    return {
      bool: false,
      msg: '投保人年龄不能小于18周岁'
    }
  }
  if (applicant.email){
    let cEmail = checkEmail('applicant', applicant.email)
    if (cEmail !== true) {
      return cEmail
    }
  }
  if (applicant.mobile){
    let cMobile = checkMobile('applicant', applicant.mobile)
    if (cMobile !== true) {
      return cMobile
    }
  }
  if (applicant.zipCode){
    let cZipCode = checkZipCode('applicant', applicant.zipCode)
    if (cZipCode !== true) {
      return cZipCode
    }
  }
  if (applicant.salary){
    let cSalary = checkSalary('applicant', applicant.salary)
    if (cSalary !== true) {
      return cSalary
    }
  }
  // 放在最后一个 校验，直接return
  if (applicant.appntIdType) {
    switch (applicant.appntIdType) {
      case '01':
        return checkIdCard01('投保人', applicant.appntIdNo)
      case '02':
        return checkIdCard02('投保人', applicant.appntIdNo)
      case '08':
        return checkIdCard08('投保人', applicant.appntIdNo)
      case '09':
        return checkIdCard09('投保人', applicant.appntIdNo)
      case '23':
        return checkIdCard23('投保人', applicant.appntIdNo)
      default:
        return {bool: true, msg: ''}
    }
  }
  return {bool: true, msg: ''}
}
/**
 * 检查基础信息(邮箱)
 * @param insured
 * @param applicant
 * @returns {{msg: string, bool: boolean}}
 */
const checkbaseInfo = (insured, applicant) => {
  console.debug('checkbaseInfo', applicant)
  console.log('checkbaseInfo', insured, applicant)
  if (insured.excelUrl){
    return {
      bool: true,
      msg: ''
    }
  }
  // if (!insured.name){
  //   return {
  //     bool: false,
  //       msg: '请输入被保人姓名'
  //   }
  // }
  if (insured.name && insured.idType && checkName(insured.name, insured.idType).bool !== true) {
    return checkName(insured.name, insured.idType)
  }

  if (insured.email) {
    let cEmail2 = checkEmail('insured', insured.email)
    if (cEmail2 != true) {
      return cEmail2
    }
  }
  if (insured.mobile){
    let cMobile = checkMobile('insured', insured.mobile)
    if (cMobile !== true) {
      return cMobile
    }
  }
  if (insured.zipCode){
    let cZipCode = checkZipCode('insured', insured.zipCode)
    if (cZipCode !== true) {
      return cZipCode
    }
  }
  if (insured.salary){
    let cSalary = checkSalary('insured', insured.salary)
    if (cSalary !== true) {
      return cSalary
    }
  }
  // 放在最后一个 校验，直接return
  if (insured.idType && insured.idNo) {
    console.log(insured.idType, insured.idNo, '888888888887777')
    switch (insured.idType) {
      case '01':
        return checkIdCard01('被保人', insured.idNo)
      case '02':
        return checkIdCard02('被保人', insured.idNo)
      case '08':
        return checkIdCard08('被保人', insured.idNo)
      case '09':
        return checkIdCard09('被保人', insured.idNo)
      case '23':
        return checkIdCard23('被保人', insured.idNo)
    }
  }
  return {
    bool: true,
    msg: ''
  }
}


const checkRelationship = (insured, applicant) => {
  let result = {
    bool: true,
    msg: ''
  }
  if (insured.relationToAppnt === '01') {
    //   本人
    // 校验信息是否一致
    //   略
  }
  if (insured.relationToAppnt === '02') {
    if (parseInt(insured.sex) === parseInt(applicant.appntSex)) {
      result.bool = false
      // result.msg = '配偶性别不能与投保人一致'
      result.msg = '被保人与投保人性别不能相同'
      return result
    }
  }
  // 父母，子女
  if (insured.relationToAppnt === '03' || insured.relationToAppnt === '04') {
    let b = getDay(applicant.appntBirthday, insured.birthday, insured.relationToAppnt)
    if (!b) {
      result.bool = false
      // result.msg = `${insured.relationToAppnt === '03' ? '子女' : '父母'}必须与投保人年龄差相差18岁以上`
      result.msg = '被保人与投保人年龄差相差不能小于18岁'
      return result
    }
  }
  return {
    bool: true
  }

}

/**
 * 粗算一下
 * @param a
 * @param b
 * @param t
 * @returns {boolean}
 */
const getDay = (a, b, t) => {
  // a = new Date(a)
  a = getNewDateParam(a)
  b = getNewDateParam(b)
  // b = new Date(b)
  let d
  if (t === '03') {
    d = Math.abs(a.getTime() - b.getTime()) / 1000 / 24 / 60 / 60
  } else {
    d = Math.abs(b.getTime() - a.getTime()) / 1000 / 24 / 60 / 60
  }
  // a = new Date(t === '04' ? a : b)
  // b = new Date(t === '04' ? b : a)
  // let d = Math.abs(a.getTime() - b.getTime()) / 1000 / 24 / 60 / 60
  let year = Math.floor(d / 365)//不整除取最小的年数或者直接进位（Math.ceil），或者四舍五入Math.round，自己改这个罗
  if (year < 18) {
    return false
  }
  return true
}

// console.log(getDay('1995-01-01', '2016-01-01', '03'))

/**
 * 邮箱格式校验
 * @param type insured -> 被保人 其他 -> 投保人
 * @param email
 * @returns {{bool: boolean, message: string}}
 */
const checkEmail = (type, email) => {
  if (email) {
    let result = vTools('email', email)
    if (result !== true) {
      return {
        bool: false,
        msg: `${type === 'insured' ? '被保人' : '投保人'}邮箱格式不符合规范！`
      }
    }
  } else {
    return {
      bool: false,
      msg: `请输入${type === 'insured' ? '被保人' : '投保人'}邮箱！`
    }
  }
  return true
}
/**
 * 手机号格式校验
 * @param type insured -> 被保人 其他 -> 投保人
 * @param Mobile
 * @returns {{bool: boolean, message: string}}
 */
const checkMobile = (type, val) => {
  if (val) {
    let result = vTools('phone2', val)
    if (result !== true) {
      return {
        bool: false,
        msg: `${type === 'insured' ? '被保人' : type === 'payment' ? '银行预留' : '投保人'}手机号不符合规范！`
      }
    }
  } else {
    return {
      bool: false,
      msg: `请输入${type === 'insured' ? '被保人' : type === 'payment' ? '银行预留' : '投保人'}手机号！`
    }
  }
  return true
}
/**
 * 银行卡号格式校验
 * @param type insured -> 被保人 其他 -> 投保人
 * @param Mobile
 * @returns {{bool: boolean, message: string}}
 */
const checkBankNo= (type, val) => {
  if (val) {
    let result = vTools('bankNo', val)
    if (result !== true) {
      return {
        bool: false,
        msg: `${type === 'insured' ? '被保人' : type === 'payment' ? '银行' : '投保人'}卡号不符合规范！`
      }
    }
  } else {
    return {
      bool: false,
      msg: `请输入${type === 'insured' ? '被保人' : type === 'payment' ? '银行' : '投保人'}卡号！`
    }
  }
  return true
}
/**
 * 邮编格式校验
 * @param type insured -> 被保人 其他 -> 投保人
 * @param ZipCode
 * @returns {{bool: boolean, message: string}}
 */
const checkZipCode = (type, val) => {
  if (val) {
    let result = vTools('zipCode', val)
    if (result !== true) {
      return {
        bool: false,
        msg: `${type === 'insured' ? '被保人' : '投保人'}邮编不符合规范！`
      }
    }
  } else {
    return {
      bool: false,
      msg: `请输入${type === 'insured' ? '被保人' : '投保人'}邮编！`
    }
  }
  return true
}
/**
 * 年收入格式校验
 * @param type insured -> 被保人 其他 -> 投保人
 * @param ZipCode
 * @returns {{bool: boolean, message: string}}
 */
const checkSalary = (type, val) => {
  if (val) {
    let result = vTools('salary', val)
    if (result !== true) {
      return {
        bool: false,
        msg: `${type === 'insured' ? '被保人' : '投保人'}年收入不符合规范！`
      }
    }
  } else {
    return {
      bool: false,
      msg: `请输入${type === 'insured' ? '被保人' : '投保人'}年收入！`
    }
  }
  return true
}


/**
 * 校验投保人是否可投对应计划
 * @param limitList
 * @param insurd
 * @param plan
 */
export const checkPlanLimitList = (limitList, insurd, plan) => {
  // 如果没有计划,则plan是个空对象
  if (!limitList) {
    if (window.getPlanLimitList) {
      // eslint-disable-next-line no-undef
      if (getPlanLimitList()) {
        // eslint-disable-next-line no-undef
        limitList = getPlanLimitList()
      }
    }
    if (store.getters['insured/getDateRange']){
      // todo 如果没有挂载计划的方法且缓存的有限制校验年龄，则直接不看计划去校验年龄就行，不校验其他
      if (plan) {
        plan = null
      }
      limitList = store.getters['insured/getDateRange']
    }
  }
  if (limitList && limitList.length) {
    if (plan) {
      if (getPlanItem(limitList, plan)) {
        let item = getPlanItem(limitList, plan)
        if (item.sex !== '') {
          if (insurd.sex) {
            // 校验性别
            if (insurd.sex !== item.sex) {
              return {
                bool: false,
                // msg: `被保人被保人性别为${insurd.sex==='2'?'女性':'男性'}，${insurd.name}的性别与所选计划性别不符合`
                msg: `被保人性别应为${item.sex === '2' ? '女性' : '男性'}，被保人性别不符合投保规则`
              }
            }
          } else {
            return {
              bool: false,
              msg: `被保人性别应为${item.sex === '2' ? '女性' : '男性'}，被保人性别不符合投保规则`
            }
          }
        }

        // 主要是为了校验年龄，所以就不加是否存在生日了
        let res = showDataMin(item.minAge, item.minType, item.maxAge, item.maxType, item.T)
        if (res) {
          if (insurd.birthday) {
            let result = compareBornInRightTime(getNDate(res.earliestDate), getNDate(res.latestDate), getNDate(insurd.birthday), item.T)
            if (!result.bool) {
              if (result.value === '>') {
                return {
                  bool: false,
                  msg: `被保人年龄不能小于${item.minAge}${getAgeType(item.minType)}`
                }
              } else if(result.value === '<') {
                return {
                  bool: false,
                  msg: `被保人年龄不能大于${item.maxAge}${getAgeType(item.maxType)}`
                }
              } else {
                return {
                  bool: false,
                  msg: '被保人出生日期不能晚于当前时间'
                }
              }
              // return {
              //   bool: false,
              //   msg: `该计划年龄范围为${item.minAge}${getAgeType(item.minType)}~${item.maxAge}${getAgeType(item.maxType)},被保人年龄不符合`
              // }
            }
          } else {
            return {
              bool: false,
              msg: `被保人年龄不能小于${item.minAge}${getAgeType(item.minType)}`
            }
          }
        }
      }
    } else {
      // 主要是为了校验年龄，所以就不加是否存在生日了
        if (insurd.birthday) {
          let result = compareBornInRightTime(getNDate(limitList[0]), getNDate(limitList[1]), getNDate(insurd.birthday), 0)
          if (!result.bool) {
            if (result.value === '>') {
              let age = calAge(limitList[1])
              return {
                bool: false,
                msg: `被保人年龄不能小于${age.value}${age.label}`
              }
            } else if(result.value === '<') {
              let age = calAge(limitList[0])
              return {
                bool: false,
                msg: `被保人年龄不能大于${age.value}${age.label}`
              }
            } else {
              return {
                bool: false,
                msg: '被保人年龄不能晚于当前时间'
              }
            }
          }
        } else {
          let age = calAge(limitList[1])
          return {
            bool: false,
            msg: `被保人年龄不能小于${age.value}${age.label}`
          }
        }
    }
  }
  return {
    bool: true
  }
}


const getAgeType = (t) => {
  return t === 'D' ? '天' : (t === 'M' ? '月' : '周岁')
}
const getNDate = (str) => {
  let arr = str.split('-')
  let y = arr[0]
  let m = (arr[1].length < 2&&Number(arr[1]) < 10) ? `0${arr[1]}` : arr[1]
  let d = (arr[2].length < 2&&Number(arr[2]) < 10) ? `0${arr[2]}` : arr[2]
  return `${y}-${m}-${d}`
}

export const compareBornInRightTime = (min, max, born) => {
  let cur = new Date()
  let n = getNewDateParam((born))
  if (n.getTime() > cur.getTime()) {
    return {
      bool: false,
      value: '?'
    }
  }
  // let n1 = new Date(Date.parse(min))
  let n1 = getNewDateParam((min))
  // let n2 = new Date(Date.parse(max))
  let n2 = getNewDateParam((max))
  console.log('n', n.getTime())
  console.log('n1', n1.getTime())
  console.log('n2', n2.getTime())
  if (n.getTime() < n1.getTime()){
    return {
      bool: false,
      value: '<'
    }
  }
  if(n.getTime() > n2.getTime()) {
    return {
      bool: false,
      value: '>'
    }
  }
  return {
    bool: true
  }
}
// console.log(compareBornInRightTime('1957-1-1', '2005-3-15', '2005-03-15'))

// console.log(new Date('2005-3-15').getTime())
// console.log(new Date('2005-03-15').getTime())

// 获取限制计划内容
const getPlanItem = (limitList, plan) => {
  for (let i = 0; i < limitList.length; i++) {
    if (limitList[i].planName) {
      if (limitList[i].planName === plan.planName)
        return limitList[i]
    }
    if (limitList[i].planId) {
      if (limitList[i].planId === plan.planCode)
        return limitList[i]
    }
  }
  return null
}

/**
 * 校验名字是否2位+，并且不包含特殊字符
 * @param name
 * @returns {{bool: boolean, message: string}|{bool: boolean, message: (Boolean|string)}|boolean}
 */
export const checkName = (name, idType = '01') => {
  if (name.length < 2) {
    return {
      bool: false,
      msg: '名字不能小于2个字符！'
    }
  }
  let result = idType === '15' ? vTools('fName', name) : vTools('name', name)
  if (result !== true) {
    return {
      bool: false,
      msg: result
    }
  }
  return {
    bool: true
  }
}

/**
 * 获取性别
 * @param idCard
 * @returns {string}
 */
export const getSexFromIdCard = (idCard) => {
  let sex = ''
  if (idCard.length === 15) { // 15位身份证号
    // sex = idCard.charAt(14) % 2 === 0 ? '女' : '男'
    sex = idCard.charAt(14) % 2 === 0 ? '2' : '1'
  } else if (idCard.length === 18) { // 18位身份证号
    // sex = idCard.charAt(16) % 2 === 0 ? '女' : '男'
    sex = idCard.charAt(16) % 2 === 0 ? '2' : '1'

  }
  return sex
}

/**
 * 判断是否需要校验职业
 */
export const isNeedcheckJob = () => {
  if (window.getJobList) {
    const arr = window.getJobList()
    if (arr.length > 0) {
      return {
        isNeed: true,
        value: arr
      }
    }
  }
  return {
    isNeed: false
  }
}

/**
 * 校验职业
 * 需要传入 职业list和职业对象(职业编码和类别)
 * {
 *   occupationCode: "A001",
 *   occupationLevel: "1"
 * }
 */
const checkInsuredJobLevel = (list, insure) => {
    if (!insure.occupationCode) {
      return {
        bool: false,
        msg: '请输入被保人职业'
      }
    }
  if (insure.occupationLevel && !list.includes(insure.occupationLevel)) {
    return {
      bool: false,
      msg: '您的职业不允许投保'
    }
  }
  return {
    bool: true
  }
}

const calAge = (d) => {
  let age = getBBRAge(d.split('-'), store.getters['insured/getTNUMBER'] ? Number(store.getters['insured/getTNUMBER']) : undefined, store.getters['insured/getAGEDIFF'])
  if(age.age == 0 ){
    let minAge
    let list = JSON.parse(store.getters['insured/getProductDetailData']).productInfo.riskList[0].calculateFactor.filter(item => item.factorType === 'TextAge')
    if(list.length && list[0].factorValueList.length){
      minAge = getMinAge(list[0].factorValueList)
    }
    return {value : minAge.minAge, label: '天'}
  }else{
    return {value : age.age, label: '周岁'}
  }
}
const getMinAge = (list) => {
  if (list === undefined) return
  const minList = list.filter(item => (item.value + '').includes('D'))
  if (minList.length > 0) {
    const str = minList[0].value.slice(0, (minList[0].value + '').indexOf('D'))
    return {
      minAge: str,
      mixType: 'D'
    }
  }
}

// 根据日期计算年龄
// const calculateAge = (birthday) => {
//   const today = new Date(Date.now())
//   const birthDate = new Date(birthday)
//   let age = today.getFullYear() - birthDate.getFullYear()
//   const monthDiff = today.getMonth() - birthDate.getMonth()
//   const dayDiff = today.getDate() - birthDate.getDate()
//   if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
//     age--
//   }
//   if (age < 1) {
//     const diffTime = today.getTime() - birthDate.getTime()
//     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
//     return `${diffDays}天`
//   }
//   return `${age}岁`
// }

