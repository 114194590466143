// 1. 附加险德华安顾附加安心百分百两全保险的保额问题
// todo 处理公式:
//  保额的计算公式是附加险保额(元)= (主险保费*K*交费期间) / (1-Y/1000*K*交费期间)
//  满期返还比例本期k=1
//  Y=以1000为基础的年交的保费
import store from '../store'
import {isArray} from './ArrayUtils'
import {findPropValue, formatAmountName, formatNumberToFix2} from './utlis'

// const specailCode = '100000000715'
// 附加险德华安顾附加安心百分百两全保险换成供应商产品编码去判断
const specailCode_lq = '5130283'


const calculateAdditionalInsuranceAmount = (mainPremium, paymentTermInYears, K, Y) => {
  mainPremium = Number(mainPremium)
  paymentTermInYears = Number(paymentTermInYears.replace('Y', ''))
  Y = Number(Y)
  console.debug('mainPremium', mainPremium)
  console.debug('paymentTermInYears', paymentTermInYears)
  console.debug('Y', Y)
  // 计算附加险保额
  let additionalInsuranceAmount = (mainPremium * K * paymentTermInYears) / (1 - Y / 1000 * K * paymentTermInYears)
  console.log('附加险保额为: ' + additionalInsuranceAmount + '元')
  return formatNumberToFix2(additionalInsuranceAmount)
}
// console.log(calculateAdditionalInsuranceAmount('4045.50','5Y',1,'79.97'))
/**
 * 根据保费试算结果去重新计算特殊的保额保费
 * @param premList
 * @param param
 */
export const newPremiumList = (premList, param) => {
  console.debug('保费试算的结果', premList)
  console.debug('保费试算的入参', param)
  if (premList && param) {
    let obj = getAmntElementRefAndSpecailCal(premList, param)
    if (obj) {
      if (isArray(premList)) {
        for (let i = 0; i < premList.length; i++) {
          let item = premList[i]
          if (obj.addRiskId === item.riskId) {
            premList[i].newPremium = obj.payPrem
          }
        }
      } else {
        for (let i = 0; i < premList.riskPremiumList.length; i++) {
          let item = premList.riskPremiumList[i]
          if (obj.addRiskId === item.riskId) {
            premList.riskPremiumList[i].premium = obj.payPrem
          }
        }
      }
    }
    // }
  }
  return premList
}

/**
 * 保费试算后进行重算
 * @param premList
 * @param params
 * @returns {null|{val: number, mainRiskId, addRiskId, mainPremium, payPrem: number, key: string}}
 */
export const getAmntElementRefAndSpecailCal = (premList, params) => {
  //
  let addRiskId
  let mainRiskId
  // 主险缴费年期
  let paymentTermInYears
  // 主险保费
  let mainPremium
  // Y=以1000为基础的年交的保费
  let Y
  let val
  let key
  let payPrem
  let factorType = 'Amnt'
  let arr
  if (isArray(premList)) {
    arr = premList
  } else {
    arr = premList.riskPremiumList
  }
  // let isHaveRiskArray = params.riskList.filter(item => item.riskCode === specailCode)
  let isHaveRiskArray = params.riskList.filter(item => item.supRiskCode === specailCode_lq)
  if (!isHaveRiskArray || isHaveRiskArray.length === 0) {
    return null
  }
  for (let i = 0; i < params.riskList.length; i++) {
    let item = params.riskList[i]
    // 获取主险信息
    if (item.riskSign === '1') {
      mainRiskId = item.riskId
      paymentTermInYears = item.calculateFactor.filter(item => item.factorType === 'FeeYear')[0].factorValue
      mainPremium = arr.filter(item => item.riskId === mainRiskId)[0].premium
    }
    // if (item.riskCode === specailCode) {
    if (item.supRiskCode === specailCode_lq) {
      addRiskId = item.riskId
      Y = arr.filter(item => item.riskId === addRiskId)[0].premium
    }
    if (Y && addRiskId && paymentTermInYears && mainPremium) {
      val = calculateAdditionalInsuranceAmount(mainPremium, paymentTermInYears, 1, Y)
      key = `${addRiskId}${factorType}`
      payPrem = formatNumberToFix2(val / 1000 * Y)
      return {
        val,
        key,
        payPrem,
        addRiskId,
        mainRiskId,
        mainPremium
      }
    }
  }
  return null

}


/** ---------------------------------------------------------------------------------------------------------------------  */
/**                                                        处理 泰康乐鑫年年年金保险（分红型）特殊问题  增加 泰康鑫益终身寿险（万能型）处理                                                */
/** ---------------------------------------------------------------------------------------------------------------------  */
const supRiskCode_tk = ['AV1', 'U24S']

const getSup = (params) => {
  if (params) {
    // let isHaveRiskArray = params.riskList.filter(item => item.supRiskCode === supRiskCode_tk)
    // if (!isHaveRiskArray || isHaveRiskArray.length === 0) {
    //   return null
    // }
    return params.riskList.filter((item) => {
      return supRiskCode_tk.includes(item.supRiskCode)
    })
    // let array = []
    // for (let i = 0; i < params.riskList.length; i++) {
    //   let item = params.riskList[i]
    //   for (let j = 0; j < supRiskCode_tk; j++) {
    //     if (item.supRiskCode === supRiskCode_tk[j]) {
    //       array.push(item)
    //     }
    //   }
    // }
    // return array
  }
  return null
}
const getPrem = (arr, src) => {
  if (src === supRiskCode_tk[0]) {
    let baseCoverage = 0
    let mult = 0
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i]
      if (item.factorType === 'BaseCoverage') {
        baseCoverage = item.factorValue ? Number(item.factorValue) : 0
      }
      if (item.factorType === 'Mult') {
        mult = item.factorValue ? Number(item.factorValue) : 0
      }
    }
    return baseCoverage * mult
  }
  if (src === supRiskCode_tk[1]) {
    let p = arr.filter(item => item.factorType === 'Prem')
    return p[0] ? Number(p[0].factorValue) : 0
  }
}
const tkPermisList = (arr_tk) => {
  let prem = 0
  for (let i = 0; i < arr_tk.length; i++) {
    let obj_tk = arr_tk[i]
    prem = Number(prem) + getPrem(obj_tk.calculateFactor, obj_tk.supRiskCode)
  }
  return prem + ''
}
// 附加险的校验, 取主险的保费
const editRenewinsurance = (param, riskItem) => {
  if (param.supRiskCode === supRiskCode_tk[0]) {
    let flag = {
      type: true,
      message: ''
    }
    let sum = 0
    let premium = getPrem(param.calculateFactor, param.supRiskCode)
    const feeYear = param.calculateFactor.filter(item => item.factorType === 'FeeYear')[0].factorValue.replace(/[^0-9]/ig, '')
    if (feeYear === '0') {
      sum = Number(premium) * 0.1
    } else if (feeYear === '3' || feeYear === '5') {
      sum = Number(premium) * (feeYear === '3' ? 0.3 : 0.5)
    } else if (Number(feeYear) >= 10) {
      sum = Number(premium) * 1
    }
    if ((Number(feeYear) <= 10 && sum < 200000) || (Number(feeYear) > 10 && sum < 150000)) {
      flag = {
        type: false,
        message: `不满足【10年交及以下, 大于等于20万标保; 15年交或20年交, 大于等于15万标保时】,不可同时选择投保《${riskItem.riskName}》`
      }
      return flag
    } else {
      return flag
    }
  }
}

export const newPremiumInTK = (param, type, riskItem) => {
  if (param && typeof type === 'boolean' && type) {
    return editRenewinsurance(param, riskItem)
  } else {
    let arr_tk = getSup(param)
    if (arr_tk && arr_tk.length > 0) {
      return tkPermisList(arr_tk)
    }
  }
  return null
}
/** ---------------------------------------------------------------------------------------------------------------------  */
/**                                                        处理装修宝特殊问题                                                 */
/** ---------------------------------------------------------------------------------------------------------------------  */
// const specailCode_zxb = '100000000940'
  // 比较值逻辑修改为供应商产品编码，与文浩确认不同环境下riskCode可能不一样，但supRiskCode会一致
const specailCode_zxb = 'P091607'
let planList = []
// todo 只针对装修宝，设置累计限额值
/**
 * 装修宝特殊处理入口
 * @param code 责任编码
 * @param type type判断是否初始化，0是初始化, 非0执行其他
 * @param pInfo 选择的责任`````` 么意义不传了
 */
export const isZXB = (code, type = 0, pInfo) => {
  // if (code === specailCode_zxb) {
  if (riskCode_zxb && code === riskCode_zxb) {
    if (type === 0) {
      return initLtemizedAmount(pInfo)
    } else {
      return setLtemizedAmount(pInfo)
    }
  }
  return null
}

const setLtemizedAmount = (data) => {
  let param
  // 获取当前选择的数据
  if (data) {
    param = data
  } else {
    param = JSON.parse(store.getters['insured/getRiskList']).riskList
  }
  if (param) {
    // 找到有这个责任所在的对象
    // let obj = findPropValue(param, 'supRiskCode', specailCode_zxb)
    let obj = findPropValue(param, 'riskCode', riskCode_zxb)
    if (obj) {
      let planId = obj.calculateFactor.filter(item => item.factorType === 'Plan')[0].factorValue
      // 分项保额
      let planAmount = planList.filter(item => item.planId === planId)[0].limitAmountValue
      let thirdDutyPrem = planList.filter(item => item.planId === planId)[0].thirdPartyDutyPrem
      // EngineeringCost
      let engineeringCost = obj.calculateFactor.filter(item => item.factorType === 'EngineeringCost')[0].factorValue
      // let amt = obj.calculateFactor.filter(item => item.factorType === 'Amnt')[0].factorValue
      return {
        TDPrem: thirdDutyPrem,
        LtemizedAmount: planAmount,
        Amnt: `${Number(planAmount) + Number(engineeringCost)}`,
        // need: amt === `${Number(planAmount) + Number(engineeringCost)}`
      }
    }
  }
  return null
}

/**
 * 用于产品详情首次调用给累计限配额赋值
 * 代码有风险，所以必须保证是装修宝的责任在执行初始化方法
 * @param pInfo 产品详情的 productInfo 部分
 * @returns {string|null}
 */
export const initLtemizedAmount = (pInfo) => {
  let productInfo
  if (pInfo) {
    productInfo = pInfo
  } else {
    productInfo = JSON.parse(store.getters['insured/getProductDetailData']).productInfo
  }
  if (!productInfo) {
    return null
  }
  // 获取责任
  // let obj = findPropValue(productInfo, 'supRiskCode', specailCode_zxb)
  let obj = findPropValue(productInfo, 'riskCode', riskCode_zxb)
  if (obj) {
    // 计划数组
    let factorValueList_plan = obj.calculateFactor.filter(item => item.factorType === 'Plan')[0].factorValueList
    // 分项保额数组
    // let factorValueList_ltemizedAmount = obj.calculateFactor.filter(item => item.factorType === 'LtemizedAmount')[0].factorValueList
    // 工程造价数组
    let factorValueList_engineeringCost = obj.calculateFactor.filter(item => item.factorType === 'EngineeringCost')[0].factorValueList
    let planAmount_init = (factorValueList_plan.filter(item => item.defaultShow === 'Y')[0].valueRelationList).filter(item => item.elementType === 'LtemizedAmount')[0].value
    let thirdPrem_init = (factorValueList_plan.filter(item => item.defaultShow === 'Y')[0].valueRelationList).filter(item => item.elementType === 'thirdPartyDutyPrem')[0].value
    let amount_init = factorValueList_engineeringCost.filter(item => item.defaultShow === 'Y')[0].value
    initPlanDate(factorValueList_plan)
    return {
      TDPrem: thirdPrem_init,
      LtemizedAmount: planAmount_init,
      Amnt: `${Number(planAmount_init) + Number(amount_init)}`
    }
  }
  return null
}

/**
 * 主要给全局变量赋值
 * @param factorValueList_plan
 */
const initPlanDate = (factorValueList_plan) => {
  for (let i = 0; i < factorValueList_plan.length; i++) {
    let item = factorValueList_plan[i]
    // 关联的分相保额
    let obj = findPropValue(item, 'elementType', 'LtemizedAmount')
    // 关联的三方责任值
    let thirdPartyDutyPrem = findPropValue(item, 'elementType', 'thirdPartyDutyPrem').value
    let item2 = {
      sort: item.sort,
      planName: item.name,
      planId: item.value,
      limitAmountValue: obj.value,
      limitAmountName: obj.name,
      thirdPartyDutyPrem: thirdPartyDutyPrem
    }
    // 检查对象是否已存在
    const hasItem = planList.filter((el) => el.planId == item2.planId)
    // 如果对象不存在，将其添加到数组
    if (hasItem.length === 0) {
      planList.push(item2)
    }
  }
}

export const specialRiskJudgeInZXB = (res, flag) => {
  let bool = false
  let code = getRiskCodeBySupRiskCodeInZXB(res)
  let obj
  if (code && riskCode_zxb && code === riskCode_zxb) {
    bool = true
    if (flag === 0) {
      obj = isZXB(code, flag)
    } else {
      obj = isZXB(code, flag, res)
    }
  }
  if (obj) {
    // let b = res.riskList.filter(item => item.supRiskCode === specailCode_zxb)[0].calculateFactor.filter(item => item.factorType === 'Amnt')[0]
    // 分项保额
    let b = res.riskList.filter(item => item.riskCode === riskCode_zxb)[0].calculateFactor.filter(item => item.factorType === 'Amnt')[0]
    let l = res.riskList.filter(item => item.riskCode === riskCode_zxb)[0].calculateFactor.filter(item => item.factorType === 'LtemizedAmount')[0]
    let t = res.riskList.filter(item => item.riskCode === riskCode_zxb)[0].calculateFactor.filter(item => item.factorType === 'thirdPartyDutyPrem')[0]
    if (b) {
      b.factorValue = obj.Amnt
      b.factorValueName = obj.Amnt
    }
    if (l && l.factorValue !== obj.LtemizedAmount) {
      l.factorValue = obj.LtemizedAmount
      l.factorValueName = formatAmountName(obj.LtemizedAmount)
    }
    if (t && t.factorValue !== obj.TDPrem) {
      t.factorValue = obj.TDPrem
      t.factorValueName = obj.TDPrem
    }
    // if (obj.need === false) {
    //   return false
    // }
  }
  return bool
}


let riskCode_zxb
const getRiskCodeBySupRiskCodeInZXB = (res) => {
  for (let i = 0; i < res.riskList.length; i++) {
    if (res.riskList[i].supRiskCode === specailCode_zxb) {
      riskCode_zxb = res.riskList[i].riskCode
      return riskCode_zxb
    }
  }
  return null
}

/** ---------------------------------------------------------------------------------------------------------------------  */
/**                                                       处理平安团险问题 ，主要给隐藏的amnt保额赋值                                                */
/** ---------------------------------------------------------------------------------------------------------------------  */
// 0 是主险，123附加险
const ty_supriskcode = ['PL180Y667', 'PL1800010', 'PL1800015', 'PL1800071']
// 可能参与计算的类型
const ty_factor_type = ['Aidad', 'Aha', 'CommonA', 'CommonB', 'CommonC', 'CommonD']

// 重新给团意险赋值
const specialRiskJudgeInPATYX = (res, flag) => {
  console.debug('specialRiskJudgeInPATYX -- flag', flag)
  let bool = false
  res.riskList.filter(item => ty_supriskcode.includes(item.supRiskCode)).map(item => {
    bool = true
    specialRiskAmnt(item, item.supRiskCode)
  })
  return bool
}

// 优化后的代码
const specialRiskAmnt = (riskListItem, supriskcode) => {
  const amntFactor = riskListItem.calculateFactor.find(item => item.factorType === 'Amnt')
  const factorSum = riskListItem.calculateFactor.filter(item => ty_factor_type.includes(item.factorType)).reduce((acc, item) => acc + Number(item.factorValue), 0)
  if (amntFactor != null) {
    if ((supriskcode === ty_supriskcode[0] && amntFactor.factorInMain) || (supriskcode !== ty_supriskcode[0] && !amntFactor.factorInMain)) {
      const newValue = `${factorSum}`
      amntFactor.factorValue = newValue
      amntFactor.factorValueName = `${newValue}元`
    }
  }
}


/** ---------------------------------------------------------------------------------------------------------------------  */
/**                                                      保费试算调用保额特殊处理的入口                                                */
/** ---------------------------------------------------------------------------------------------------------------------  */

export const specialRiskJudge = (res, flag) => {
  let bool1 = specialRiskJudgeInZXB(res, flag)
  let bool2 = specialRiskJudgeInPATYX(res, flag)
  return bool1 || bool2
}

/***********************************************************************************************************************/
/**                                                      缴费年期和缴费方式对比     - 仅限制缴费年期和缴费类型                                         */
/***********************************************************************************************************************/

export const payTypeComparePayYear = (val, form) => {
  console.debug('payTypeComparePayYear--val', val)
  console.debug('payTypeComparePayYear--form', form)
  const dType = '趸交'
  // const yearType = '年交'
  // const monthType = '月交'
  if (val.factorType === 'PayType' || val.factorType === 'FeeYear') {
    let productInfo = JSON.parse(store.getters['insured/getProductDetailData']).productInfo
    let riskId = val.riskId
    let obj = findPropValue(form, 'riskId', riskId)
    // form中另一个参数对象
    let item = obj.calculateFactor.filter(item => item.factorType === (val.factorType === 'PayType' ? 'FeeYear' : 'PayType'))[0]
    // form中自己
    let itemself = obj.calculateFactor.filter(item => item.factorType === (val.factorType === 'PayType' ? 'PayType' : 'FeeYear'))[0]
    // 从详情中获取相关的要素的列表数据
    // 关联对象
    let item_1 = findPropValue(productInfo, 'factorId', item.factorId)
    // 自己
    let item_2 = findPropValue(productInfo, 'factorId', itemself.factorId)
    console.debug('payTypeComparePayYear-item1', item_1)
    console.debug('payTypeComparePayYear-item2', item_2)
    console.debug('payTypeComparePayYear', val)
    // 修改缴费方式情况下
    if (val.factorType === 'PayType' ) {
      // 如果缴费方式为趸交，且 缴费年期不为趸交，这种情况修改缴费年期为趸交即可
      if (val.name === dType && item.factorValueName !== dType ) {
        item.factorValue = item_1.factorValueList[0].value
        item.factorValueName = item_1.factorValueList[0].name
      } else if (
        // 如果缴费方式不为趸交，且 缴费年期为趸交，这种情况修改缴费年期不为趸交
      val.name !== dType && item.factorValueName === dType ) {
        item.factorValue = item_1.factorValueList[1].value
        item.factorValueName = item_1.factorValueList[1].name
      }
    } else {
      // 修改缴费年期的情况
      // 只考虑缴费方式为趸交的情况，如果缴费年期不为趸交，则修改缴费年期为趸交即可
      if (val.name !== dType && item.factorValueName === dType ) {
        for (let i = 0; i < item_2.factorValueList.length; i++) {
          if (item_2.factorValueList[i].name === dType) {
            val.name = item_2.factorValueList[i].name
            val.index = i
            val.value = item_2.factorValueList[i].value
            break
          }
        }
      } else if (val.name === dType && item.factorValueName !== dType) {
        for (let i = 0; i < item_2.factorValueList.length; i++) {
          if (item_2.factorValueList[i].name!==dType) {
            val.name = item_2.factorValueList[i].name
            val.index = i
            val.value = item_2.factorValueList[i].value
            break
          }
        }
      }
    }
    itemself.factorValue = val.value
    itemself.factorValueName = val.name
  }
  console.log('payTypeComparePayYear-------', val)
}

/***  ---------------------------------------------------------------------------------------------------------------------  */
/***                                                      保额-特殊处理的入口 -- 最低10W，且为 1000 整数                                               */
/***  ---------------------------------------------------------------------------------------------------------------------  */
const ruitai_supRiskCode = ['RUIEBAO']

export const specialRiskAMN= (val, form) => {
  let bool = false
  if (val.factorType === 'Amnt') {
    const hasFalseResult = form.filter(item => ruitai_supRiskCode.includes(item.supRiskCode))
      .some(item => !specialWSMAmnt(item, val))
    if (hasFalseResult) {
      // 如果有任何一个元素使得 specialWSMAmnt 返回 false，执行这里的代码
      bool = false
    } else {
      // 如果所有元素都使得 specialWSMAmnt 返回 true，执行这里的代码
      bool = true
    }
  } else {
    return true
  }
  return bool
}

const specialWSMAmnt = (item, val) => {
  console.debug('specialWSMAmnt---item', item)
  console.debug('specialWSMAmnt---val', val)
  let bool = isValidString(val.value)
  if (!bool) {
    val.name = val.value = '100000'
    let a= item.calculateFactor.filter(item => item.factorType === 'Amnt')[0]
    a.factorValue = a.factorValueName =  val.name
  }
  return bool
}

const isValidString = (m) => {
  // 尝试将字符串转换为数字
  const num = parseFloat(m)
  // 检查转换是否成功且结果为数字
  if (isNaN(num)) {
    return false
  }
  // 检查数字是否大于或等于100000
  if (num < 100000) {
    return false
  }
  // 检查数字是否能被1000整除
  if (num % 1000 !== 0) {
    return false
  }
  // 如果所有条件都满足，返回true
  return true
}
